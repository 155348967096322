import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { DateTime } from 'luxon';
import { computed } from 'vue';

export const useFormatedIsoDateString = (
  isoString?: MaybeRefOrGetter<string | null | undefined>,
  format: Intl.DateTimeFormatOptions = DateTime.DATETIME_MED,
) => {
  return computed(() => formatIsoDateString(toValue(isoString), format));
};

export const formatIsoDateString = (
  isoString?: string | null,
  format: Intl.DateTimeFormatOptions = DateTime.DATETIME_MED,
) => {
  if (!isoString) return '';
  return DateTime.fromISO(isoString).toLocaleString(format);
};

export const useFormatedDateString = (
  date?: MaybeRefOrGetter<Date | string | null | undefined>,
  format = '',
  emptyString = '',
) => {
  return computed(() => formatDateString(toValue(date), format, emptyString));
};

export const formatDateString = (date?: Date | string | null, format = '', emptyString = '') => {
  if (!date) return emptyString;
  return (typeof date === 'string' ? DateTime.fromISO(date) : DateTime.fromJSDate(date)).toFormat(
    format,
  );
};
