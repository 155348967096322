<template>
  <h2>{{ t('startAndEndLocation') }}</h2>
  <div class="mt-4 grid grid-cols-3 gap-4">
    <div class="col-span-2 grid grid-cols-2 gap-x-4 gap-y-2">
      <Select
        v-model="formValues.pickupLocationType"
        :options="locationTypeOptions"
        :label="t('startLocation')"
        :disabled="pickupDisabled"
      />

      <Select
        v-model="formValues.dropoffLocationType"
        :options="locationTypeOptions"
        :label="t('endLocation')"
        :disabled="dropoffDisabled"
      />

      <Select
        v-if="formValues.pickupLocationType === BookingLocationType.STATION"
        v-model="formValues.pickupStationId"
        :options="stationOptions"
        :disabled="pickupDisabled"
      />

      <SearchLocation
        v-else
        v-model:location="formValues.pickupLocation"
        :disabled="pickupDisabled"
      />

      <Select
        v-if="formValues.dropoffLocationType === BookingLocationType.STATION"
        v-model="formValues.dropoffStationId"
        :options="stationOptions"
        :disabled="dropoffDisabled"
      />

      <SearchLocation
        v-else
        v-model:location="formValues.dropoffLocation"
        data-testid="search-dropoff-location-input"
        :disabled="dropoffDisabled"
      />
    </div>
  </div>
  <div v-if="hasChanged" class="mt-4 flex justify-end gap-4">
    <CVButton
      :is-loading="isSaving"
      :disabled="!readyToSave"
      variant="success"
      @click.prevent="onSaveButton"
    >
      {{ t('save') }}
    </CVButton>
    <CVButton :disabled="isSaving" variant="warning" @click.prevent="syncFormValues">
      {{ t('cancel') }}
    </CVButton>
  </div>
  <AdjustTransfersModal
    v-model:show-modal="showModal"
    :booking="booking"
    :pickup-location-type="formValues.pickupLocationType"
    :dropoff-location-type="formValues.dropoffLocationType"
    :pickup-station-id="formValues.pickupStationId"
    :dropoff-station-id="formValues.dropoffStationId"
    :is-saving="isSaving"
    @save="onSave"
  />
</template>

<script lang="ts" setup>
import Select from '@/components/Select.vue';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';
import { useStations } from '@/queries/use-stations';
import { computed, reactive, ref, watch } from 'vue';
import SearchLocation from './SearchLocation.vue';
import { useAutoAdjustTransfers } from '@/queries/use-bookings';
import type { SaveBookingLocationsEvent } from './AdjustTransfers.vue';
import AdjustTransfersModal from './AdjustTransfersModal.vue';
import { locationToCoordinates } from '@/hooks/use-location-to-coordinates';
import { useFormHasChanged } from '@/hooks/use-form-has-changed';
import { useLocalizedFields } from '@/hooks/use-localized-fields';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';

const props = defineProps<{
  booking: Booking;
  isSaving: boolean;
  pickupDisabled?: boolean;
  dropoffDisabled?: boolean;
}>();
const emit = defineEmits<{
  (
    e: 'update-booking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished?: () => void,
  ): void;
  (e: 'hasChanged', value: boolean): void;
  (e: 'updateHasDivergentDropoffLocation', value: boolean): void;
}>();

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();

const { mutateAsync: autoAdjustTransfers, isPending: adjustingTransfers } =
  useAutoAdjustTransfers();

const initialValues = computed(() => ({
  pickupLocationType: props.booking.pickupLocationType,
  dropoffLocationType: props.booking.dropoffLocationType,
  pickupStationId: props.booking.pickupStation?.id,
  dropoffStationId: props.booking.dropoffStation?.id,
  pickupLocation: {
    coordinates: locationToCoordinates(props.booking.pickupLocation),
    address: props.booking.pickupLocationGeocodedAddress,
  },
  dropoffLocation: {
    coordinates: locationToCoordinates(props.booking.dropoffLocation),
    address: props.booking.dropoffLocationGeocodedAddress,
  },
}));

const formValues = reactive({
  ...initialValues.value,
});

const showModal = ref(false);

const isSaving = computed(() => props.isSaving || adjustingTransfers.value);

const readyToSave = computed(
  () =>
    !(
      (formValues.pickupLocationType === BookingLocationType.STREET &&
        !formValues.pickupLocation?.coordinates) ||
      (formValues.dropoffLocationType === BookingLocationType.STREET &&
        !formValues.dropoffLocation?.coordinates)
    ),
);

const hasChanged = useFormHasChanged(initialValues, formValues);

const onSaveButton = () => {
  showModal.value = true;
};

const onSave = (adjustTransfers: SaveBookingLocationsEvent) => {
  emit(
    'update-booking',
    {
      id: props.booking.id,
      booking: {
        ...formValues,
        pickupLocation: formValues.pickupLocation.coordinates,
        pickupLocationGeocodedAddress: formValues.pickupLocation.address,
        dropoffLocation: formValues.dropoffLocation.coordinates,
        dropoffLocationGeocodedAddress: formValues.dropoffLocation.address,
      },
    },
    async () => {
      if (adjustTransfers.autoAdjust) {
        await autoAdjustTransfers({
          bookingId: props.booking.id,
          data: {
            deliveryDriverId: adjustTransfers.deliveryDriverId!,
            returnDriverId: adjustTransfers.returnDriverId!,
          },
        });
        syncFormValues();
      }
      showModal.value = false;
    },
  );
};

const syncFormValues = () => {
  formValues.pickupLocationType = initialValues.value.pickupLocationType;
  formValues.dropoffLocationType = initialValues.value.dropoffLocationType;
  formValues.pickupStationId = initialValues.value.pickupStationId;
  formValues.dropoffStationId = initialValues.value.dropoffStationId;
  formValues.pickupLocation = initialValues.value.pickupLocation;
  formValues.dropoffLocation = initialValues.value.dropoffLocation;
};

watch(
  formValues,
  () => {
    const hasDivergentDropoffLocation =
      formValues.dropoffLocationType !== BookingLocationType.STATION ||
      formValues.dropoffStationId !== props.booking.car.stations[0].station.id;
    emit('updateHasDivergentDropoffLocation', hasDivergentDropoffLocation);
  },
  { deep: true },
);

const { data: stationsData } = useStations(500);
const stations = useFlattenPaginatedData(stationsData);

const locationTypeOptions = computed(() =>
  Object.entries(BookingLocationType).map(([key, value]) => ({
    label: t(`locationTypes.${key}`),
    value,
  })),
);

const stationOptions = computed(() =>
  stations.value?.map((station) => ({
    label: getLocalizedField(station.info),
    value: station.id,
  })),
);

watch(hasChanged, () => emit('hasChanged', hasChanged.value));
</script>

<i18n lang="json">
{
  "en": {
    "startAndEndLocation": "Start and End Location",
    "startLocation": "Start Location",
    "endLocation": "End Location"
  },
  "de": {
    "startAndEndLocation": "Start- und Endort",
    "startLocation": "Startort",
    "endLocation": "Endort"
  }
}
</i18n>
