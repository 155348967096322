<template>
  <div class="grid grid-cols-3 gap-4">
    <div class="flex flex-col gap-4">
      <LabeledText :label="t('renter')"
        >{{ booking.customerData?.firstName ?? 'n/a' }} {{ booking.customerData?.lastName ?? 'n/a'
        }}<br />
        {{ `${t('tel')} ${booking.customerData?.phone ?? 'n/a'}` }}</LabeledText
      >
      <BookingsOfUserAmount
        v-if="showPreviousBookings && booking.customer"
        :user-id="booking.customer?.id"
      />
    </div>

    <LabeledText :label="t('bookingNumber')">{{ booking.bookingNumber }}</LabeledText>

    <div>
      <div v-if="showStatus" class="mb-2 border-b border-primary pb-2">
        <div class="flex gap-2">
          <LabeledText
            class="flex-1"
            :class="{
              'text-success': booking.status === BookingStatus.FLOATING,
            }"
            :label="t('bookingStatus')"
          >
            <p class="flex items-center justify-start gap-2">
              {{ t(`bookingStatus.${props.booking.status}`) }}
              <Spinner
                v-if="booking.status === BookingStatus.CONFIRMED && isSaving"
                class="h-5 w-5"
              />
            </p>
          </LabeledText>
          <DropdownDotted
            v-if="dropdownDottedItems.length"
            :buttons="
              dropdownDottedItems.filter(
                (e) =>
                  !(e.active === false || (e.statuses && !e.statuses?.includes(booking.status))),
              )
            "
            :is-loading="isRefreshingBookingConfirmation"
          />
        </div>

        <CVButton
          v-if="booking.status === BookingStatus.DRAFT && canUpdateToStatus(BookingStatus.OPEN)"
          outline
          class="mt-2 w-full"
          :is-loading="props.isSaving"
          @click.prevent="setBookingToPaid"
        >
          {{ t('setBookingToPaid') }}
        </CVButton>
        <CVButton
          v-if="
            (booking.status === BookingStatus.OPEN || booking.status === BookingStatus.FLOATING) &&
            canUpdateToStatus(BookingStatus.CONFIRMED)
          "
          outline
          class="mt-2 w-full"
          :is-loading="props.isSaving"
          @click.prevent="setBookingToConfirmed"
        >
          {{ t('confirmBooking') }}
        </CVButton>
      </div>
      <LabeledText :label="t('totalPrice')"
        ><span
          v-if="!hidePrice"
          v-currency="booking.priceCalculation.totalPrice"
          class="text-xl font-medium"
        /><span v-else>{{ hidePriceText }}</span></LabeledText
      >
    </div>
  </div>

  <div class="mt-4 grid grid-cols-2 gap-4">
    <TextareaField name="customerComment" :label="t('customerComment')" />

    <TextareaField name="internalComment" :label="t('internalComment')" />
  </div>

  <div v-if="hasChanged" class="mt-4 flex justify-end gap-4">
    <CVButton variant="success" :is-loading="isSaving" @click.prevent="onSubmit">{{
      t('save')
    }}</CVButton>
    <CVButton variant="warning" :disabled="isSaving" @click.prevent="onCancel">{{
      t('cancel')
    }}</CVButton>
  </div>
</template>

<script lang="ts" setup>
import LabeledText from '@/components/LabeledText.vue';
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import TextareaField from '../TextareaField.vue';
import { computed, ref, watch } from 'vue';
import DropdownDotted from '@/components/DropdownDotted.vue';
import { useResendBookingMails } from '@/hooks/use-resend-booking-mails';
import { Alert } from '@/utils/alert';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import { cloneDeep } from 'lodash';
import { useFormHasChanged } from '@/hooks/use-form-has-changed';
import { useRefreshBookingConfirmation } from '@/queries/use-bookings';
import { subject } from '@casl/ability';
import { useAbility } from '@casl/vue';
import BookingsOfUserAmount from './BookingsOfUserAmount.vue';

const props = defineProps<{
  booking: Booking;
  showStatus?: boolean;
  isSaving?: boolean;
  hidePrice?: boolean;
  hidePriceText?: string;
  showPreviousBookings?: boolean;
}>();

const emit = defineEmits<{
  (
    e: 'updateBooking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished?: () => void,
  ): void;
  (e: 'hasChanged', value: boolean): void;
}>();

const { t } = useI18n();

const { resendConfirmationEmail } = useResendBookingMails(props.booking.id);
const { can } = useAbility();
const { mutateAsync: refreshBookingConfirmation, isPending: isRefreshingBookingConfirmation } =
  useRefreshBookingConfirmation();

const isAtLeastConfirmed = computed(
  () =>
    props.booking.status === BookingStatus.CONFIRMED ||
    props.booking.status === BookingStatus.HANDED_OVER ||
    props.booking.status === BookingStatus.CAR_RETURNED,
);

const setBookingToPaid = async () => {
  changeBookingStatus(
    BookingStatus.OPEN,
    t('reallySetBookingToPaidText'),
    t('setBookingToPaid'),
    t('cancel'),
  );
};

const setBookingToConfirmed = () => {
  changeBookingStatus(
    BookingStatus.CONFIRMED,
    t('reallyConfirmBookingText'),
    t('confirmBooking'),
    t('cancel'),
  );
};

const setBookingToOpen = () => {
  changeBookingStatus(
    BookingStatus.OPEN,
    t('reallySetBookingToUnconfirmedText'),
    t('setBookingToUnconfirmed'),
    t('cancel'),
  );
};

const setBookingToFloating = () => {
  changeBookingStatus(
    BookingStatus.FLOATING,
    t('reallySetBookingToFloatingText'),
    t('setBookingToFloating'),
    t('cancel'),
  );
};

const changeBookingStatus = async (
  status: BookingStatus.OPEN | BookingStatus.CONFIRMED | BookingStatus.FLOATING,
  text: string,
  confirmButtonText: string,
  cancelButtonText: string,
) => {
  const alertResult = await Alert.fire({
    text: text,
    icon: 'success',
    showDenyButton: true,
    confirmButtonText: confirmButtonText,
    denyButtonText: cancelButtonText,
    focusDeny: true,
  });
  if (alertResult.isConfirmed) {
    emit('updateBooking', {
      id: props.booking.id,
      booking: { status },
    });
  }
};

const downloadContract = () => {
  if (!props.booking.contractFileUrl) return;
  window.location.href = props.booking.contractFileUrl;
};

const downloadBookingConfirmation = async () => {
  if (!props.booking.confirmationFileUrl) return;
  const { fileUrl } = await refreshBookingConfirmation(props.booking.id);
  window.location.href = fileUrl;
};

const canUpdateToStatus = (status: BookingStatus) =>
  can('update', subject('Booking', { ...props.booking, status }), 'status');

const dropdownDottedItems = computed(() => [
  {
    title: t('resendBookingConfirmation'),
    onClick: resendConfirmationEmail,
    active: isAtLeastConfirmed.value && can('sendMail', subject('Booking', props.booking)),
  },
  {
    title: t('downloadBookingConfirmation'),
    onClick: downloadBookingConfirmation,
    statuses: [BookingStatus.CONFIRMED, BookingStatus.HANDED_OVER, BookingStatus.CAR_RETURNED],
    active: !!props.booking.confirmationFileUrl,
  },
  {
    title: t('setBookingToUnconfirmed'),
    onClick: setBookingToOpen,
    statuses: [BookingStatus.CONFIRMED, BookingStatus.FLOATING],
    active: canUpdateToStatus(BookingStatus.OPEN),
  },
  {
    title: t('confirmBooking'),
    onClick: setBookingToConfirmed,
    statuses: [BookingStatus.OPEN, BookingStatus.FLOATING],
    active: canUpdateToStatus(BookingStatus.CONFIRMED),
  },
  {
    title: t('setBookingToFloating'),
    onClick: setBookingToFloating,
    statuses: [BookingStatus.OPEN, BookingStatus.CONFIRMED],
    active: canUpdateToStatus(BookingStatus.FLOATING),
  },
  {
    title: t('downloadContract'),
    onClick: downloadContract,
    statuses: [BookingStatus.HANDED_OVER, BookingStatus.CAR_RETURNED],
    active: !!props.booking.contractFileUrl,
  },
]);

const onCancel = () => {
  setValues({
    customerComment: props.booking.customerComment,
    internalComment: props.booking.internalComment,
  });
};

const {
  handleSubmit,
  values: formValues,
  setValues,
} = useForm({
  initialValues: {
    customerComment: props.booking.customerComment,
    internalComment: props.booking.internalComment,
  },
  validationSchema: yup.object({
    customerComment: yup.string().nullable(),
    internalComment: yup.string().nullable(),
  }),
});

const unchangedValues = ref(cloneDeep(formValues));

const onSubmit = handleSubmit((values) => {
  emit(
    'updateBooking',
    {
      id: props.booking.id,
      booking: values,
    },
    () => (unchangedValues.value = cloneDeep(formValues)),
  );
});

const hasChanged = useFormHasChanged(unchangedValues, formValues);
watch(hasChanged, () => emit('hasChanged', hasChanged.value));
</script>

<i18n lang="json">
{
  "en": {
    "customerComment": "Customer Comment",
    "internalComment": "Internal Comment",
    "renter": "Renter",
    "bookingNumber": "Booking Nr.",
    "tel": "Tel:",
    "bookingStatus": "Booking Status",
    "confirmBooking": "Confirm Booking",
    "confirmChanges": "Confirm Changes",
    "setBookingToPaid": "Set booking to paid",
    "totalPrice": "Total Price",
    "unconfirmedChanges": "Unconfirmed changes",
    "resendBookingConfirmation": "Resend booking confirmation",
    "reallyConfirmBookingText": "Do you really want to confirm the Booking?",
    "setStatusToNotConfirmed": "Set to unconfirmed",
    "reallySetBookingToUnconfirmedText": "Do you really want to set the Booking to unconfirmed?",
    "setBookingToUnconfirmed": "Set to unconfirmed",
    "reallySetBookingToFloatingText": "Do you really want to set the Booking to floating?",
    "reallySetBookingToPaidText": "Do you really want to set the Booking to paid?",
    "setBookingToFloating": "Set to floating",
    "downloadContract": "Download Contract",
    "downloadBookingConfirmation": "Download Booking Confirmation"
  },
  "de": {
    "customerComment": "Kunden Kommentar",
    "internalComment": "Interner Kommentar",
    "renter": "Mieter",
    "bookingNumber": "Buchungs Nr.",
    "tel": "Tel:",
    "bookingStatus": "Buchungsstatus",
    "confirmBooking": "Buchung bestätigen",
    "confirmChanges": "Änderungen bestätigen",
    "setBookingToPaid": "Buchung auf Bezahlt stellen",
    "totalPrice": "Gesamtpreis",
    "unconfirmedChanges": "Unbestätigte Änderungen",
    "resendBookingConfirmation": "Buchungsbestätigung neu senden",
    "reallyConfirmBookingText": "Möchtest du die Buchung wirklich bestätigen?",
    "reallySetBookingToUnconfirmedText": "Möchtest du die Buchung wirklich auf unbestätigt setzen?",
    "setBookingToUnconfirmed": "Auf unbestätigt setzen",
    "reallySetBookingToFloatingText": "Möchtest du die Buchung wirklich auf schwebend setzen?",
    "reallySetBookingToPaidText": "Möchtest du die Buchung wirklich auf Bezahlt stellen?",
    "setBookingToFloating": "Auf schwebend setzen",
    "downloadContract": "Vertrag runterladen",
    "downloadBookingConfirmation": "Buchungsbestätigung runterladen"
  }
}
</i18n>
