<template>
  <div class="space-y-4">
    <p class="text-2xl font-medium">
      {{ t('merchantConsultation') }}
    </p>
    <Checkbox v-model="merchantHasBeenConsulted">{{ t('merchantHasBeenConsulted') }}</Checkbox>
    <Information>
      <template #text>
        <p class="font-medium">
          {{ t('deliveryDateAccordingToContract') }}:
          {{ formattedAvailableFromDate }}
        </p>
      </template>
      <template #modal>{{ t('ifDeliveryDateChanges') }}</template>
    </Information>
    <div class="grid grid-cols-3 gap-4">
      <DatepickerField name="availableFrom" :label="t('enterNewDeliveryDate')" />
      <SelectField name="stationId" :label="t('isTheStationRight')" :options="stationOptions" />
    </div>
    <div class="h-44"></div>
    <Divider />
    <div class="flex justify-end">
      <CVButton
        :disabled="!merchantHasBeenConsulted"
        :is-loading="isUpdatingCar"
        @click.prevent="submit"
      >
        {{ t('confirmConsultation') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Checkbox from '@/components/Checkbox.vue';
import type { Car } from '@/entities/cars/car.entity';
import { getDateFormat } from '@/hooks/use-date-format';
import { useFormatedDateString } from '@/hooks/use-formated-date';
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import Information from '@/components/Information.vue';
import type { Station } from '@/entities/station/station.entity';
import SelectField from '@/components/SelectField.vue';
import Divider from '@/components/Divider.vue';
import { useForm } from 'vee-validate';
import { useUpdateCar } from '@/queries/use-cars';
import * as yup from 'yup';
import { toTypedSchema } from '@vee-validate/yup';
import { dateOrStringRequired } from '@/validation/car.schema';
import DatepickerField from '@/components/DatepickerField.vue';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

const props = defineProps<{
  car: Car;
  stations: Station[] | undefined;
}>();

const emit = defineEmits<{
  (e: 'complete'): void;
}>();

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();

const merchantHasBeenConsulted = ref(false);
const formattedAvailableFromDate = useFormatedDateString(
  computed(() => props.car.availableFrom),
  getDateFormat(),
);

const stationOptions = computed(() => {
  return (props.stations ?? []).map((station) => ({
    label: getLocalizedField(station.info),
    value: station.id,
  }));
});

const { mutateAsync: updateCar, isPending: isUpdatingCar } = useUpdateCar();

const { handleSubmit } = useForm({
  initialValues: {
    availableFrom: undefined,
    stationId: props.car.stations[0].station.id,
  },
  validationSchema: toTypedSchema(
    yup.object({
      availableFrom: dateOrStringRequired(),
      stationId: yup.string().required(),
    }),
  ),
});

const submit = handleSubmit(async (values) => {
  await updateCar({
    id: props.car.id,
    car: {
      merchantConsultedDate: new Date().toISOString(),
      availableFrom: new Date(values.availableFrom).toISOString(),
      stations: [
        {
          id: values.stationId,
          fromDate: null,
        },
      ],
    },
  });
  emit('complete');
});
</script>

<i18n lang="json">
{
  "en": {
    "merchantConsultation": "Merchant Consultation",
    "ifDeliveryDateChanges": "If the delivery date changes significantly, be sure to consult because of legal claims!",
    "merchantHasBeenConsulted": "Merchant has been consulted",
    "deliveryDateAccordingToContract": "Delivery date according to contract",
    "enterNewDeliveryDate": "Enter new delivery date",
    "isTheStationRight": "Is the station right?",
    "confirmConsultation": "Confirm consultation"
  },
  "de": {
    "merchantConsultation": "Händler Rücksprache",
    "ifDeliveryDateChanges": "Wenn sich der Liefertermin stark verändert, unbedingt Rücksprache halten, wegen rechtlichen Ansprüchen!",
    "merchantHasBeenConsulted": "Händler Rücksprache wurde gehalten",
    "deliveryDateAccordingToContract": "Liefertermin laut Vertrag",
    "enterNewDeliveryDate": "Liefertermin neu eingeben",
    "isTheStationRight": "Ist die Station richtig?",
    "confirmConsultation": "Rücksprache bestätigen"
  }
}
</i18n>
