<template>
  <div v-if="!changingFilter">
    <div class="grid grid-cols-2 gap-7">
      <VehiclesListItem
        v-for="car of cars"
        :key="car.id"
        :car="car"
        :rent-duration="rentDuration"
        @click="() => $emit('selectCar', car)"
      />
    </div>

    <div v-if="isFetchingNextPage" class="mt-8 flex justify-center">
      <Spinner />
    </div>

    <EntityListExceptions
      :status="status"
      :error="error"
      :items-count="cars.length"
      :no-items-text="t('noCarsFound')"
    />
  </div>
</template>

<script lang="ts" setup>
import { useCarsWithQuery } from '@/queries/use-cars';
import { computed, unref, watch } from 'vue';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import EntityListExceptions from '@/components/EntityListExceptions.vue';
import { useI18n } from 'vue-i18n';
import VehiclesListItem from './VehiclesListItem.vue';
import { useScrollPosition } from '@/hooks/use-scroll-position';
import { FindAllCarsSort } from '@/entities/cars/find-all-cars-sort.enum';
import type { Car } from '@/entities/cars/car.entity';
import type { Station } from '@/entities/station/station.entity';
import { CUSTOM_LOCATIONS, type CustomLocations } from '@/constants/custom-locations.constant';
import type { BookingLocation } from '@/entities/bookings/booking.entity';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import type { VehicleCategory } from '@/entities/vehicle-category.enum';

const props = defineProps<{
  station: Station | CustomLocations;
  pickupLocation: BookingLocation | null;
  dropoffLocation: BookingLocation | null;
  startDate: Date;
  endDate: Date;
  categoryFilter: VehicleCategory | null;
  makeFilter: string | null;
  rentDuration: number;
  changingFilter: boolean;
}>();

defineEmits<{
  (event: 'selectCar', car: Car): void;
}>();

const { t } = useI18n();

const { scrollPositionBottom } = useScrollPosition();

const {
  data: carsData,
  status,
  error,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
} = useCarsWithQuery(
  computed(() => {
    return {
      stationIds: props.station !== CUSTOM_LOCATIONS ? [props.station.id] : undefined,
      transferDeliveryLocation: locationToCoordinates(props.pickupLocation),
      transferReturnLocation: locationToCoordinates(props.dropoffLocation),
      transferDeliveryStationId:
        props.pickupLocation?.locationType === BookingLocationType.STATION
          ? props.pickupLocation?.station?.id
          : undefined,
      transferReturnStationId:
        props.dropoffLocation?.locationType === BookingLocationType.STATION
          ? props.dropoffLocation?.station?.id
          : undefined,
      startDate: props.startDate.toISOString(),
      endDate: props.endDate.toISOString(),
      categories: props.categoryFilter ? [props.categoryFilter] : undefined,
      makeIds: props.makeFilter ? [props.makeFilter] : undefined,
      filterUnavailable: true,
      isGhostCar: false,
      limit: 20,
      sort: FindAllCarsSort.TOTAL_PRICE,
    };
  }),
);

function locationToCoordinates(location: BookingLocation | null): [number, number] | undefined {
  if (!location) {
    return undefined;
  }
  if (location.locationType === BookingLocationType.STATION) {
    return location.station?.location?.coordinates;
  }
  return location.location?.coordinates
    ? [location.location?.coordinates?.longitude, location.location?.coordinates?.latitude]
    : undefined;
}

const cars = useFlattenPaginatedData(carsData);

watch(scrollPositionBottom, (distance) => {
  if (distance < 400 && unref(hasNextPage) && !unref(isFetchingNextPage)) {
    fetchNextPage();
  }
});
</script>

<i18n lang="json">
{
  "en": {
    "noCarsFound": "No cars found"
  },
  "de": {
    "noCarsFound": "Keine Autos gefunden"
  }
}
</i18n>
