<template>
  <form @submit.prevent="$emit('onSubmit')">
    <BasicOverview
      :booking="booking"
      :hide-price="!!booking.agent"
      :hide-price-text="t('paidByAgency')"
      :is-saving="isSaving"
      @update-booking="updateBooking"
      @has-changed="(changed) => (hasChanged.basicOverview = changed)"
    />

    <Divider />

    <div class="grid grid-cols-3 gap-4">
      <div class="col-span-2">
        <VehicleImmutable v-model:car="formValues.car" :booking-id="booking.id" />
      </div>
    </div>

    <Divider />

    <div class="relative mt-4 grid grid-cols-3 gap-4">
      <RangeAndLocation
        v-model:start-date="formValues.startDate"
        v-model:end-date="formValues.endDate"
        v-model:car="formValues.car"
        v-model:total-rental-days="totalRentalDays"
        v-model:calculated-inclusive-km="formValues.calculatedInclusiveKm"
        v-model:price-calculation="formValues.priceCalculation"
        :hide-booking-range-picker="!!booking.agent"
        :is-saving="isSaving"
        :booking="booking"
        :disabled="disablePriceChangingModules('rangeAndLocation')"
        :recalculating-price="recalculatingPrice"
        show-locations
        @extend-time-period-of-pricing="extendTimePeriodOfPricingWithAlert"
        @update-booking="updateBooking"
        @has-changed="(changed) => (hasChanged.rangeAndLocation = changed)"
      />

      <RangeInfos
        :booking-id="booking.id"
        :custom-inclusive-km="booking.customInclusiveKm"
        :price-calculation="formValues.priceCalculation"
        :calculated-inclusive-km="formValues.calculatedInclusiveKm"
        :total-rental-days="totalRentalDays"
        :inclusive-km="formValues.inclusiveKm"
        :available-extras="availableExtras"
        immutable
      />
    </div>

    <Divider />

    <UpgradeExtras
      v-if="!booking.agent && !showAdvancedExtraSelection && isAgentProvisionEnabled"
      v-model:extras-confirmed="extrasConfirmed"
      v-model:price-calculation="formValues.priceCalculation"
      :total-rental-days="totalRentalDays"
      :is-saving="isSaving"
      :available-extras="availableExtras"
      :booking="booking"
      :disabled="disablePriceChangingModules('extras')"
      :recalculating-price="recalculatingPrice"
      :show-checkbox="!booking.agent"
      @recalculate-price="recalculatePrice"
      @update-booking="updateBooking"
      @has-changed="(changed) => (hasChanged.extras = changed)"
    />

    <Extras
      v-if="booking.agent || showAdvancedExtraSelection || !isAgentProvisionEnabled"
      v-model:extras-confirmed="extrasConfirmed"
      v-model:price-calculation="formValues.priceCalculation"
      :total-rental-days="totalRentalDays"
      :is-saving="isSaving"
      :immutable="!!booking.agent"
      :available-extras="availableExtras"
      :booking="booking"
      :disabled="disablePriceChangingModules('extras')"
      :recalculating-price="recalculatingPrice"
      :show-checkbox="!booking.agent"
      @recalculate-price="recalculatePrice"
      @update-booking="updateBooking"
      @has-changed="(changed) => (hasChanged.extras = changed)"
    />

    <div v-if="isAgentProvisionEnabled" class="mt-2 flex justify-end">
      <button
        class="link text-sm text-primary hover:no-underline"
        @click.prevent="showAdvancedExtraSelection = !showAdvancedExtraSelection"
      >
        {{ !showAdvancedExtraSelection ? t('editExtras') : t('showUpgrades') }}
      </button>
    </div>

    <div v-if="booking.agent" class="mt-2">{{ t('agentHint') }}</div>

    <Divider />

    <div v-if="booking.priceCalculation.customBookedExtras?.length">
      <CustomExtrasImmutable
        :custom-booked-extras="booking.priceCalculation.customBookedExtras"
        :hide-price="!!booking.agent"
        :hide-price-text="t('paidByAgency')"
      />

      <Divider />
    </div>

    <div class="flex items-end gap-y-3">
      <CancelButton
        v-if="
          $can(
            'update',
            subject('Booking', { ...booking, status: BookingStatus.CANCELED }),
            'status',
          )
        "
        :booking="booking"
        :is-saving="isSaving"
        :hide-revert-cancelling="true"
        class="flex-1"
        @update-booking="updateBooking"
        @booking-cancelled="$router.push({ name: 'upcomingHandovers' })"
      />
      <div class="flex flex-1 items-end justify-end gap-8">
        <p class="justify-self-end text-right text-lg font-medium">
          {{ t('totalPrice') }}
          <span
            v-if="!booking.agent"
            v-currency="formValues.priceCalculation.totalPrice"
            class="block text-2xl"
          />
          <span v-else class="block">{{ t('paidByAgency') }}</span>
        </p>
        <ButtonNext
          :disabled="(!extrasConfirmed && !booking.agent) || hasChanged.extras"
          :is-loading="isSaving"
          class="justify-self-end"
          type="submit"
        >
          {{ t('next') }}
        </ButtonNext>
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
import BasicOverview from '@/components/booking-forms/BasicOverview.vue';
import Extras from '@/components/booking-forms/Extras.vue';
import RangeAndLocation from '@/components/booking-forms/RangeAndLocation.vue';
import RangeInfos from '@/components/booking-forms/RangeInfos.vue';
import VehicleImmutable from '@/components/booking-forms/VehicleImmutable.vue';
import ButtonNext from '@/components/buttons/ButtonNext.vue';
import Divider from '@/components/Divider.vue';
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import type { Car } from '@/entities/cars/car.entity';
import type { InclusiveKmPerDay } from '@/entities/inclusive-km-per-day.entity';
import type { Pricing } from '@/entities/pricing.entity';
import type {
  GetAllVehicleTypesCarsDto,
  GetAllVehicleTypesDto,
} from '@/entities/vehicle-type.entity';
import { useRecalculatePrice } from '@/hooks/use-recalculate-price';
import { useI18n } from 'vue-i18n';
import { useUpdateBooking } from '@/queries/use-bookings';
import { computed, reactive, ref, type Ref } from 'vue';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form';
import CustomExtrasImmutable from '@/components/booking-forms/CustomExtrasImmutable.vue';
import { useExtendTimePeriodOfPricingWithAlert } from '@/hooks/use-extend-time-period-of-pricing-with-alert';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import UpgradeExtras from '@/components/booking-forms/UpgradeExtras.vue';
import { useFeatureFlag } from '@/hooks/use-feature-flag';
import { subject } from '@casl/ability';
import CancelButton from '@/views/bookings/booking-edit/components/CancelButton.vue';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';

export type CarWithPricing = GetAllVehicleTypesCarsDto & {
  vehicleType: GetAllVehicleTypesDto;
};
export interface FormValues {
  startDate: string;
  endDate: string;
  customerComment: string | null;
  internalComment: string | null;
  car: Car;
  inclusiveKm: InclusiveKmPerDay[];
  calculatedInclusiveKm: number | null;
  priceCalculation: Pricing;
}

const { t } = useI18n();
const props = defineProps<{
  booking: Booking;
}>();
defineEmits<{
  (e: 'onSubmit'): void;
}>();

const isAgentProvisionEnabled = useFeatureFlag('agent_provision');

const { isPending: isSaving, mutateAsync: updateBookingAsync } = useUpdateBooking();

const formValues: FormValues = reactive({
  car: props.booking.car,
  inclusiveKm: props.booking.inclusiveKm,
  calculatedInclusiveKm: props.booking.calculatedInclusiveKm,
  startDate: props.booking.startDate,
  endDate: props.booking.endDate,
  customerComment: props.booking.customerComment,
  internalComment: props.booking.internalComment,
  priceCalculation: props.booking.priceCalculation,
});

const showAdvancedExtraSelection = ref(false);
const totalRentalDays: Ref<number> = ref(props.booking.totalRentalDays);

const availableExtras = ref(props.booking.car?.vehicleType?.availableExtras);

const hasChanged = reactive({
  rangeAndLocation: false,
  extras: false,
  basicOverview: false,
});

const hasDivergentDropoffLocation = computed(
  () =>
    props.booking.dropoffLocationType !== BookingLocationType.STATION ||
    props.booking.dropoffStation?.id !== props.booking.car.stations[0].station.id,
);

const { recalculatePrice, recalculatingPrice: requestingPrice } = useRecalculatePrice(
  formValues,
  computed(() => props.booking.priceCalculation),
  totalRentalDays.value,
  hasDivergentDropoffLocation,
);

const { extendTimePeriodOfPricingWithAlert, extendingTimePeriodOfPricing } =
  useExtendTimePeriodOfPricingWithAlert(
    formValues,
    computed(() => props.booking.priceCalculation),
    totalRentalDays,
    computed(() => props.booking.totalRentalDays),
  );

const recalculatingPrice = computed(
  () => requestingPrice.value || extendingTimePeriodOfPricing.value,
);

const updateBooking = async (
  query: { id: string; booking: UpdateBookingDto },
  onFinished?: () => void,
) => {
  await updateBookingAsync(query);
  if (query.booking.carId) {
    availableExtras.value = props.booking.car.vehicleType.availableExtras;
  }
  onFinished?.();
};

const extrasConfirmed = ref(false);

const disablePriceChangingModules = (ignore: 'extras' | 'rangeAndLocation') =>
  Object.entries({
    extras: hasChanged.extras,
    rangeAndLocation: hasChanged.rangeAndLocation,
  }).some(([key, value]) => key !== ignore && value);

usePreventLeavingUnsavedForm(() => Object.values(hasChanged).some((changed) => changed));
</script>

<i18n lang="json">
{
  "en": {
    "totalPrice": "Total Price",
    "backToOverview": "Back to Handoverlist",
    "noOption": "No",
    "totalBasePrice": "Car Price (without Extras)",
    "agentHint": "This booking was made through a partner/agent. Therefore, no other extras can be added.",
    "paidByAgency": "Paid by partner/agent",
    "editExtras": "Edit Extras",
    "showUpgrades": "Show Upgrades"
  },
  "de": {
    "totalPrice": "Gesamtpreis",
    "backToOverview": "Zurück zur Übergabeliste",
    "noOption": "Kein",
    "totalBasePrice": "Fahrzeugpreis (ohne Extras)",
    "agentHint": "Diese Buchung wurde über einen Partner/Vermittler getätigt. Daher können keine weiteren Extras hinzugefügt werden.",
    "paidByAgency": "Von Partner/Vermittler bezahlt",
    "editExtras": "Extras bearbeiten",
    "showUpgrades": "Upgrades anzeigen"
  }
}
</i18n>
