<template>
  <div class="h-26 overflow-hidden break-words">
    <h3 class="font-gotham mb-1 space-x-1 text-lg font-medium lg:text-xl">
      <router-link :to="{ name: 'showNews', params: { id: news.id } }" class="hover:underline">{{
        news.title
      }}</router-link>
      <span class="ml-[2px] text-xs font-normal lg:text-sm">{{ '- ' + formattedDate }}</span>
    </h3>
    <p class="text-sm lg:text-base">
      {{ shortenedText }}
      <span v-if="showReadMore">
        ...
        <router-link :to="{ name: 'showNews', params: { id: news.id } }" class="text-link">{{
          t('readMore')
        }}</router-link>
      </span>
    </p>
  </div>
</template>

<script setup lang="ts">
import type { News } from '@/entities/news/news.entity';
import { useFormatedIsoDateString } from '@/hooks/use-formated-date';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const props = defineProps<{
  news: News;
}>();

const { t } = useI18n();

const shortenedText = computed(() => props.news.content.slice(0, 250));
const showReadMore = computed(() => props.news.content.length > shortenedText.value.length);

const formattedDate = useFormatedIsoDateString(
  computed(() => props.news?.publishedDate ?? props.news?.createdDate),
);
</script>

<i18n lang="json">
{
  "en": {
    "readMore": "read more"
  },
  "de": {
    "readMore": "weiterlesen"
  }
}
</i18n>
