<template>
  <div>
    <div class="grid grid-cols-2 gap-4">
      <div v-if="transferDeliveryPrice">
        <p class="mb-2 text-xl font-medium">{{ t('delivery') }}</p>
        <div>{{ t('from') }}: {{ deliveryFrom }}</div>
        <div>{{ t('to') }}: {{ deliveryTo }}</div>
        <div class="font-medium">{{ t('price') }}: <span v-currency="transferDeliveryPrice" /></div>
      </div>
      <div v-if="transferReturnPrice">
        <p class="mb-2 text-xl font-medium">{{ t('return') }}</p>
        <div>{{ t('from') }}: {{ returnFrom }}</div>
        <div>{{ t('to') }}: {{ returnTo }}</div>
        <div class="font-medium">{{ t('price') }}: <span v-currency="transferReturnPrice" /></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { BookingLocation } from '@/entities/bookings/booking.entity';
import type { Station } from '@/entities/station/station.entity';
import { useLocationInfo } from '@/hooks/use-location-info';
import { useI18n } from 'vue-i18n';
import { computed, toRef } from 'vue';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

const props = defineProps<{
  carStation: Station;
  pickupLocation: BookingLocation;
  dropoffLocation: BookingLocation;
  transferDeliveryPrice?: number | null;
  transferReturnPrice?: number | null;
}>();

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();

const deliveryFrom = computed(() => getLocalizedField(props.carStation.info));
const deliveryTo = useLocationInfo(toRef(props, 'pickupLocation'));
const returnFrom = useLocationInfo(toRef(props, 'dropoffLocation'));
const returnTo = computed(() => getLocalizedField(props.carStation.info));
</script>

<i18n lang="json">
{
  "en": {
    "transfers": "Transfers",
    "delivery": "Transfer Delivery",
    "return": "Transfer Return",
    "from": "From",
    "to": "To",
    "price": "Price"
  },
  "de": {
    "transfers": "Transfers",
    "delivery": "Transfer Anlieferung",
    "return": "Transfer Rückführung",
    "from": "Von",
    "to": "Nach",
    "price": "Preis"
  }
}
</i18n>
