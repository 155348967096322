import type { VehicleExtraOption } from '@/entities/vehicle-extra-option.entity';
import { getCalculatedExtraOptionPrice } from './get-calculated-extra-option-price';

export const sortOptionsByPrice = (options: VehicleExtraOption[], rentDuration?: number | null) => {
  return [...options].sort(
    (a, b) =>
      getCalculatedExtraOptionPrice(a, rentDuration) -
      getCalculatedExtraOptionPrice(b, rentDuration),
  );
};
