<template>
  <div class="grid grid-cols-3 gap-4">
    <BooleanSelectField
      name="hasNavigation"
      :label="t('gpsNavigation')"
      :empty-value-to-null="true"
      :null-title="t('fromVehicleType')"
      :hint="vehicleTypeValue('hasNavigation')"
    />

    <BooleanSelectField
      name="hasDistanceControl"
      :label="t('distanceControl')"
      :empty-value-to-null="true"
      :null-title="t('fromVehicleType')"
      :hint="vehicleTypeValue('hasDistanceControl')"
    />

    <BooleanSelectField
      name="hasRearViewCamera"
      :label="t('rearViewCamera')"
      :empty-value-to-null="true"
      :null-title="t('fromVehicleType')"
      :hint="vehicleTypeValue('hasRearViewCamera')"
    />
  </div>
</template>

<script lang="ts" setup>
import BooleanSelectField from '@/components/BooleanSelectField.vue';
import type { VehicleType } from '@/entities/vehicle-type.entity';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  vehicleType?: VehicleType;
}>();

const { t } = useI18n();

const vehicleTypeValue = (key: string) => {
  if (!props.vehicleType) return null;
  const prefix = t('originalValue') + ': ';
  switch (key) {
    case 'hasNavigation':
      return prefix + (props.vehicleType.hasNavigation ? t('yes') : t('no'));
    case 'hasDistanceControl':
      return prefix + (props.vehicleType.hasDistanceControl ? t('yes') : t('no'));
    case 'hasRearViewCamera':
      return prefix + (props.vehicleType.hasRearViewCamera ? t('yes') : t('no'));
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "gpsNavigation": "GPS/Navigation",
    "distanceControl": "Distance Control ...",
    "rearViewCamera": "Rear View Camera",
    "fromVehicleType": "From Vehicle Type",
    "originalValue": "Original Value"
  },
  "de": {
    "gpsNavigation": "GPS/Navigation",
    "distanceControl": "Parkabstandswarner ...",
    "rearViewCamera": "Rückfahrkamera",
    "fromVehicleType": "Von Vehicle Type",
    "originalValue": "Originalwert"
  }
}
</i18n>
