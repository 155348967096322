<template>
  <Modal v-model="showModal">
    <template #title>{{ t('createNewCustomer') }}</template>

    <CreateCustomerModalForm
      v-if="showModal"
      :schema="schema"
      :is-saving="isSaving"
      @on-save="onSave"
      @on-cancel="$emit('onCancel')"
    />
  </Modal>
</template>

<script lang="ts" setup>
import Modal from '@/components/Modal.vue';
import { useI18n } from 'vue-i18n';
import { computed, ref, watch, watchEffect } from 'vue';
import type { ObjectSchema } from 'yup';
import CreateCustomerModalForm from './CreateCustomerModalForm.vue';
import type { CreateUserDto, User } from '@/entities/auth/user.entity';
import { useCreateUser } from '@/queries/use-users';

const props = defineProps<{
  showModal: boolean;
  schema: ObjectSchema<any>;
  bookingId: string;
  isSaving: boolean;
}>();

const emit = defineEmits<{
  (e: 'newCustomerCreated', newCustomer: User, onFinished: () => void): void;
  (e: 'onFinished'): void;
  (e: 'onCancel'): void;
  (e: 'update:showModal', value: boolean): void;
}>();

const { t } = useI18n();

const isSaving = computed(() => props.isSaving || isCreatingUser.value);
const showModal = ref(props.showModal);
watchEffect(() => (showModal.value = props.showModal));

watch(showModal, () => {
  emit('update:showModal', showModal.value);
});

const { mutateAsync: createUser, isPending: isCreatingUser } = useCreateUser();

const onSave = async (user: CreateUserDto) => {
  const newCustomer = await createUser(user);
  emit('newCustomerCreated', newCustomer, () => {
    showModal.value = false;
    emit('onFinished');
  });
};
</script>

<i18n lang="json">
{
  "en": {
    "createNewCustomer": "Create and link new Customer"
  },
  "de": {
    "createNewCustomer": "Neuen Kunden anlegen und verknüpfen"
  }
}
</i18n>
