<template>
  <div class="relative space-y-2">
    <p class="mb-4 text-xl font-medium">{{ t('extras') }}</p>
    <div v-for="(extra, i) in extrasSelectData" :key="i">
      <div v-if="!immutable || extra.isChosenOption" class="grid w-full grid-cols-2">
        <div v-if="!props.immutable" class="flex justify-start">
          <Select
            v-model="bookedExtrasMap[extra.id]"
            :placeholder="t(`vehicleExtras.${extra.type}`)"
            :options="extra.options"
            class="w-full"
          />
          <Information v-if="extra.type === VehicleExtraType.CROSSBORDER"
            ><template #modal>{{ t('crossBorderInfo') }}</template></Information
          >
          <Information v-if="extra.type === VehicleExtraType.EXTRA_KM"
            ><template #modal>{{
              t('extrasKmInfo', {
                price: formatCurrency(car.vehicleType.pricePerExtraKm),
              })
            }}</template></Information
          >
        </div>
        <div
          class="flex self-center font-medium text-error"
          :class="{
            'text-success': extra.isChosenOption,
            'ml-4': !props.immutable,
          }"
        >
          {{ extra.chosenOptionInfo }}
          {{ extra.isChosenOption ? '✓' : '✕' }}
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="absolute -inset-1 flex items-center justify-center bg-black/20">
      <Spinner />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Information from '@/components/Information.vue';
import Select from '@/components/Select.vue';
import type { Car } from '@/entities/cars/car.entity';
import { VehicleExtraType } from '@/entities/vehicle-extra-type.enum';
import { useI18n } from 'vue-i18n';
import { formatCurrency } from '@/utils/format-numbers';
import { getCalculatedExtraOptionPrice } from '@/utils/get-calculated-extra-option-price';
import { sortOptionsByPrice } from '@/utils/sort-options-by-price';
import { computed, ref, watch, watchEffect } from 'vue';

const props = defineProps<{
  car: Car;
  rentDuration: number;
  bookedExtrasMap: Record<string, string>;
  immutable?: boolean;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'change', value: Record<string, string>): void;
  (e: 'numberOfChosenExtras', value: number): void;
}>();

const { t } = useI18n();

const bookedExtrasMap = ref(props.bookedExtrasMap);
watchEffect(() => (bookedExtrasMap.value = props.bookedExtrasMap));

const availableExtras = computed(() =>
  [...props.car.vehicleType.availableExtras].sort((extraA, extraB) =>
    extraA.type > extraB.type ? 1 : -1,
  ),
);

const extrasSelectData = computed(() =>
  availableExtras.value.map((extra) => {
    const sortedOptions = sortOptionsByPrice(extra.options, props.rentDuration);
    const options: {
      label: string;
      value: string;
      chosenOptionInfo: string;
      id: string;
      price: number;
    }[] = [];
    const notPerDay = extra.type === VehicleExtraType.EXTRA_KM;
    sortedOptions.forEach((option) => {
      const price = getCalculatedExtraOptionPrice(option, props.rentDuration);

      const priceLabel = notPerDay
        ? `${formatCurrency(price)}`
        : `${formatCurrency(price / (props.rentDuration ? props.rentDuration : 1))}/${t('day')}`;

      const name = `${
        option.value === 'true'
          ? ''
          : option.value === 'false'
            ? t('noOption')
            : option.name.length > 0
              ? option.name
              : option.value
      }`;
      const label = `${name} ${t(`vehicleExtras.${extra.type}`)} - ${priceLabel}`;
      const chosenOptionInfo = `${name} ${t(
        `vehicleExtras.${extra.type}`,
      )} | ${formatCurrency(price)}`;
      options.push({
        label,
        value: option.id,
        chosenOptionInfo,
        id: option.id,
        price,
      });
    });
    const { chosenOptionInfo, price } =
      options.find((option) => option.id === bookedExtrasMap.value[extra.id]) ?? options[0];
    return {
      id: extra.id,
      type: extra.type,
      options,
      chosenOptionInfo,
      isChosenOption: price > 0,
    };
  }),
);

watchEffect(() => {
  const numberOfChosenExtras = extrasSelectData.value.filter(
    (extra) => extra.isChosenOption,
  ).length;
  emit('numberOfChosenExtras', numberOfChosenExtras);
});

watchEffect(() => {
  for (const extra of availableExtras.value || []) {
    if (!bookedExtrasMap.value[extra.id]) {
      const optionId = sortOptionsByPrice(extra.options, props.rentDuration)[0].id;
      bookedExtrasMap.value[extra.id] = optionId;
    }
  }
});

watch(
  bookedExtrasMap,
  () => {
    emit('change', bookedExtrasMap.value);
  },
  { deep: true },
);
</script>

<i18n lang="json">
{
  "en": {
    "extras": "Extras",
    "extrasKmInfo": "Each additional km will be charged with {price}",
    "noOption": "No",
    "day": "day"
  },
  "de": {
    "extras": "Extras",
    "extrasKmInfo": "Jeder zusätzliche Km wird mit {price} berechnet",
    "noOption": "Kein",
    "day": "Tag"
  }
}
</i18n>
