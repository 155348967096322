<template>
  <HeaderBar />
  <section class="carvia-container mx-auto bg-accent">
    <div class="mb-4 grid grid-cols-3 items-center gap-4">
      <Input v-model="searchQuery" :placeholder="t('search')" class="flex-1" />
      <Input
        v-model="licencePlateFinSearchQuery"
        :placeholder="t('searchForLicencePlateFin')"
        class="flex-1"
      />
      <Select
        v-model="stationId"
        class="col-start-1"
        :options="stationOptions"
        :placeholder="t('station')"
      />
      <Select v-model="decommissionedFilter" :options="decommissionedOptions" empty-value-to-null />
    </div>

    <div v-if="vehicleTypes && !licencePlateFinSearchQuery" class="flex flex-col space-y-2">
      <VehicleTypeCard
        v-for="vehicleType in vehicleTypes"
        :key="vehicleType.id"
        :vehicle-type="vehicleType"
        hide-dropdown-dotted
      />
    </div>
    <div v-if="licencePlateFinSearchQuery" class="flex flex-col space-y-2">
      <CarCard
        v-for="car in cars"
        :key="car.id"
        :car="car"
        :vehicle-type="car.vehicleType"
        cannot-edit
      />
    </div>

    <div v-if="isLoading" class="mt-10 flex items-center justify-center">
      <Spinner />
    </div>

    <div v-if="isError">
      <p>{{ t('somethingWentWrong') }}</p>
    </div>
  </section>
</template>

<script lang="ts" setup>
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import Input from '@/components/Input.vue';
import type { FindAllVehicleTypesQuery } from '@/entities/vehicle-type.entity';
import { useI18n } from 'vue-i18n';
import { useVehicleTypesWithQuery } from '@/queries/use-vehicle-types';
import { useUiStore } from '@/stores/ui.store';
import { debounce } from 'lodash';
import { computed, reactive, ref, watch, watchEffect } from 'vue';
import VehicleTypeCard from './VehicleTypeCard.vue';
import Select from '@/components/Select.vue';
import { useStations } from '@/queries/use-stations';
import { useRoute } from 'vue-router';
import { DecommissionedFilter } from '@/entities/cars/decommissioned-filter.enum';
import CarCard from './CarCard.vue';
import { useCarsWithQuery } from '@/queries/use-cars';
import type { FindAllCarsQueryDto } from '@/entities/cars/car.entity';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { useRouteQueryStore } from '@/stores/route-query.store';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();
const searchQuery = ref<string>('');
const licencePlateFinSearchQuery = ref<string>('');
const route = useRoute();
const preSelectedStationId = route.query.stationId as string | undefined;
const stationId = ref<string>(preSelectedStationId ?? 'all');
const routeQueryStore = useRouteQueryStore();

const decommissionedFilter = ref<DecommissionedFilter | null>(DecommissionedFilter.ACTIVE);

const query = reactive<FindAllVehicleTypesQuery>({
  query: undefined,
  stationIds: preSelectedStationId ? [preSelectedStationId] : undefined,
  decommissioned: decommissionedFilter.value ?? undefined,
  isGhostCar: false,
});
const {
  data: vehicleTypes,
  isLoading: isLoadingVehicleTypes,
  isError: isVehicleTypesError,
} = useVehicleTypesWithQuery(
  query,
  computed(() => !licencePlateFinSearchQuery.value),
);

const carsQuery = reactive<FindAllCarsQueryDto>({
  licencePlateFinQuery: undefined,
  stationIds: preSelectedStationId ? [preSelectedStationId] : undefined,
  decommissioned: decommissionedFilter.value ?? undefined,
  isGhostCar: false,
  limit: 20,
});
const {
  data: carsData,
  isLoading: isLoadingCars,
  isError: isCarsError,
} = useCarsWithQuery(
  carsQuery,
  computed(() => !!licencePlateFinSearchQuery.value),
);
const cars = useFlattenPaginatedData(carsData);

const isLoading = computed(
  () =>
    (!licencePlateFinSearchQuery.value && isLoadingVehicleTypes.value) ||
    (licencePlateFinSearchQuery.value && isLoadingCars.value),
);

const isError = computed(
  () =>
    (!licencePlateFinSearchQuery.value && isVehicleTypesError.value) ||
    (licencePlateFinSearchQuery.value && isCarsError.value),
);

const { data: stationsData } = useStations(500);
const stations = useFlattenPaginatedData(stationsData);

const decommissionedOptions = computed(() => [
  {
    label: t('showAllCars'),
    value: null,
  },
  {
    label: t('hideDecommissionedCars'),
    value: DecommissionedFilter.ACTIVE,
  },
  {
    label: t('hideActiveCars'),
    value: DecommissionedFilter.DECOMMISSIONED,
  },
]);

const stationOptions = computed(() => {
  if (!stations.value) return [];
  return [
    {
      label: t('allStations'),
      value: 'all',
    },
    ...stations.value.map((station) => {
      return {
        label: getLocalizedField(station.info),
        value: station.id,
      };
    }),
  ];
});
watch(stationId, (value) => {
  query.stationIds = value === 'all' ? undefined : [value];
  routeQueryStore.push({
    stationId: value === 'all' ? undefined : value,
  });
});

watch(decommissionedFilter, (value) => {
  query.decommissioned = value ?? undefined;
});

const refreshSearchQuery = debounce(() => {
  query.query = searchQuery.value;
}, 500);
watch(searchQuery, () => {
  refreshSearchQuery();
});

const refreshLicencePlateFinSearchQuery = debounce(() => {
  carsQuery.licencePlateFinQuery = licencePlateFinSearchQuery.value;
}, 500);
watch(licencePlateFinSearchQuery, () => {
  refreshLicencePlateFinSearchQuery();
});

const uiStore = useUiStore();
watchEffect(() => {
  uiStore.setHeaderTitle(t('vehicleTypes'));
});
</script>

<i18n lang="json">
{
  "en": {
    "vehicleTypes": "Vehicle Types",
    "newVehicleType": "New Vehicle Type",
    "addVehicleType": "+ Add Vehicle Type",
    "search": "Search vehicle type",
    "station": "Station",
    "allStations": "All Stations",
    "somethingWentWrong": "Something went wrong",
    "showAllCars": "Show all cars",
    "hideDecommissionedCars": "Hide decommissioned cars",
    "hideActiveCars": "Hide active cars",
    "searchForLicencePlateFin": "Search car (licence plate/FIN)"
  },
  "de": {
    "vehicleTypes": "Vehicle Types",
    "newVehicleType": "Neue Fahrzeug Klasse",
    "addVehicleType": "+ Vehicle Type hinzufügen",
    "search": "Vehicle Type suchen",
    "station": "Station",
    "allStations": "Alle Stationen",
    "somethingWentWrong": "Etwas ist schief gelaufen",
    "showAllCars": "Alle Autos anzeigen",
    "hideDecommissionedCars": "Ausrangierte Autos ausblenden",
    "hideActiveCars": "Aktive Autos ausblenden",
    "searchForLicencePlateFin": "Auto suchen (Kennzeichen/FIN)"
  }
}
</i18n>
