<template>
  <div class="collapse collapse-arrow">
    <input type="checkbox" class="peer" />
    <div
      class="collapse-title grid grid-cols-3 gap-2"
      :class="{ 'text-base-300': vehicleType.isBlocked }"
    >
      <img :src="vehicleType.imageUrl" :height="100" :width="100" />
      <div>
        <h2 class="card-title">
          {{ vehicleType.make.name }}
          {{ vehicleType.model }}
        </h2>

        <p v-if="vehicleType.isBlocked">
          {{ t('availableFrom') }} {{ formatedNextAvailableStartDate }}
        </p>
      </div>

      <LabeledText :label="t('basePrice')">
        {{ vehicleType.basePrice }}
      </LabeledText>
    </div>
    <div class="collapse-content">
      <div
        v-for="car in vehicleType.cars"
        :key="car.id"
        class="mb-2 flex items-center justify-between border p-2"
        :class="{
          'border-primary': selectedCar?.id === car.id,
          'bg-accent': selectedCar?.id === car.id,
          'border-gray-300': selectedCar?.id !== car.id,
          'text-base-300': vehicleType.isBlocked && car.id !== selectedCar?.id,
          'text-primary-content': selectedCar?.id === car.id,
        }"
      >
        <LabeledText :label="t('licencePlate')">
          {{ car.licencePlate }}
        </LabeledText>
        <LabeledText :label="t('station')">
          {{ getLocalizedField(getCurrentStationOfCar(car).info) }}
        </LabeledText>

        <CVButton
          :disabled="car.isBlocked || selectedCar?.id === car.id"
          :is-loading="isFetching"
          @click.prevent="selectCar(car.id)"
        >
          {{ t('select') }}
        </CVButton>
      </div>
    </div>
    <div class="divider"></div>
  </div>
</template>

<script lang="ts" setup>
import LabeledText from '@/components/LabeledText.vue';
import type { Car } from '@/entities/cars/car.entity';
import { useFormatedIsoDateString } from '@/hooks/use-formated-date';
import { useI18n } from 'vue-i18n';
import type { ManyVehicleTypesVehicleType } from '@/entities/many-vehicle-types-vehicle-type.entity';
import { ref } from 'vue';
import { carsService } from '@/api/cars.service';
import { getCurrentStationOfCar } from '@/hooks/use-current-station-of-car';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();

const props = defineProps<{
  vehicleType: ManyVehicleTypesVehicleType;
  selectedCar: Car;
}>();

const emit = defineEmits<{
  (e: 'onCarSelect', car: Car): void;
}>();

const selectedCarId = ref(props.selectedCar.id);
const isFetching = ref(false);

const formatedNextAvailableStartDate = useFormatedIsoDateString(
  props.vehicleType.nextAvailableStartDate,
);

const selectCar = async (carId: string) => {
  selectedCarId.value = carId;
  isFetching.value = true;
  try {
    const newCar = await carsService.getOne(carId);
    emit('onCarSelect', newCar);
  } catch {
    isFetching.value = false;
  } finally {
    isFetching.value = false;
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "select": "Select",
    "availableFrom": "Available from"
  },
  "de": {
    "select": "Auswählen",
    "availableFrom": "Verfügbar ab"
  }
}
</i18n>
