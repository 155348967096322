<template>
  <div>
    <p class="text-lg font-medium">{{ t('inspectionDates') }}</p>
    <div v-if="inspections.length" class="space-y-1">
      <div
        v-for="(inspection, i) in inspections"
        :key="i"
        class="flex gap-4 border-t border-primary pt-1"
      >
        <div class="basis-[200px]">
          {{ formatDateString(inspection.date, getDateTimeFormat()) }}
        </div>
        <div>{{ formatFloat(inspection.milage) }} km</div>
      </div>
    </div>
    <div v-else>-</div>
  </div>
</template>

<script lang="ts" setup>
import type { CarInspection } from '@/entities/cars/car-inspection';
import { getDateTimeFormat } from '@/hooks/use-date-format';
import { formatDateString } from '@/hooks/use-formated-date';
import { useI18n } from 'vue-i18n';
import { formatFloat } from '@/utils/format-numbers';

defineProps<{
  inspections: CarInspection[];
}>();

const { t } = useI18n();
</script>

<i18n lang="json">
{
  "en": {
    "date": "Date",
    "milage": "Milage",
    "inspectionDates": "Inspection dates"
  },
  "de": {
    "date": "Datum",
    "milage": "Kilometerstand",
    "inspectionDates": "Inspektionstermine"
  }
}
</i18n>
