export enum FindAllCarsSort {
  LICENCE_PLATE = 'car.licencePlate',
  FIN = 'car.fin',
  MILAGE = 'car.milage',
  MAKE = 'make.name',
  MODEL = 'vehicleType.model',
  AVAILABLE_FROM = 'car.availableFrom',
  LAST_TUV_DATE = 'car.lastTuvDate',
  NEXT_INSPECTION_DUE_IN_KM = 'nextInspectionDueInKm',
  TOTAL_PRICE = 'TOTAL_PRICE',
}
