<template>
  <div class="grid grid-cols-3 gap-4">
    <Select
      v-model="stationId"
      :label="t('location')"
      :options="stationOptions"
      empty-value-to-null
    />
    <div class="relative">
      <Input
        :label="t('startDate')"
        :value="formattedStartDate"
        readonly
        @click="showPicker = true"
      />
      <DateRangePicker
        v-model:show-picker="showPicker"
        :start-date="startDate"
        :end-date="endDate"
        class="absolute top-[63px] z-10"
        @dates-change="
          (dates) => {
            $emit('change', { ...dates, station });
          }
        "
      />
    </div>
    <Input :label="t('endDate')" :value="formattedEndDate" readonly @click="showPicker = true" />
  </div>
</template>

<script setup lang="ts">
import { getDateTimeFormat } from '@/hooks/use-date-format';
import { useFormatedDateString } from '@/hooks/use-formated-date';
import { useI18n } from 'vue-i18n';
import { computed, ref, toRef, watch } from 'vue';
import DateRangePicker from './DateRangePicker.vue';
import Input from '@/components/Input.vue';
import Select from '@/components/Select.vue';
import type { Station } from '@/entities/station/station.entity';
import { CUSTOM_LOCATIONS, type CustomLocations } from '@/constants/custom-locations.constant';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

export interface StationAndTimes {
  station: Station | CustomLocations | null;
  startDate: Date;
  endDate: Date;
}

const props = defineProps<StationAndTimes & { stations: Station[] }>();

const emit = defineEmits<{
  (e: 'change', value: StationAndTimes): void;
}>();

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();

const showPicker = ref(false);
const stationId = ref(props.station !== CUSTOM_LOCATIONS ? props.station?.id : null);

watch(stationId, () => {
  emit('change', {
    station:
      stationId.value === CUSTOM_LOCATIONS
        ? CUSTOM_LOCATIONS
        : (props.stations.find((station) => station.id === stationId.value) ?? null),
    startDate: props.startDate,
    endDate: props.endDate,
  });
});

const formattedStartDate = useFormatedDateString(toRef(props, 'startDate'), getDateTimeFormat());
const formattedEndDate = useFormatedDateString(toRef(props, 'endDate'), getDateTimeFormat());

const stationOptions = computed(() => [
  {
    label: t('chooseLocation'),
    value: null,
  },
  ...(props.stations.map((station) => ({
    label: getLocalizedField(station.info),
    value: station.id,
  })) ?? []),
  {
    label: t('customLocations'),
    value: CUSTOM_LOCATIONS,
  },
]);
</script>

<i18n lang="json">
{
  "en": {
    "chooseLocation": "Choose a location",
    "location": "Location",
    "startDate": "Start date",
    "endDate": "End date",
    "customLocations": "Custom locations"
  },
  "de": {
    "chooseLocation": "Ort auswählen",
    "location": "Ort",
    "startDate": "Startdatum",
    "endDate": "Enddatum",
    "customLocations": "Andere Orte"
  }
}
</i18n>
