<template>
  <div class="relative flex flex-col gap-4">
    <h2>{{ t('upgradeExtras') }}</h2>
    <div class="flex flex-col gap-4">
      <div
        v-for="extra in extrasUpgradeData"
        :key="extra.extra.id"
        class="flex flex-col gap-1 text-lg"
        :data-testid="`upgrade-extra-${extra.extra.type}`"
      >
        <div class="flex gap-2">
          <p
            class="font-medium"
            :class="{
              'text-success': extra.isBooked,
              'text-error': !extra.isBooked,
            }"
          >
            {{ extra.extraName }} - {{ extra.priceLabel }}
            {{ extra.isBooked ? '✓' : '✕' }}
          </p>
          <Information v-if="extra.extra.type === VehicleExtraType.CROSSBORDER"
            ><template #modal>{{ t('crossBorderInfo') }}</template></Information
          >
          <Information v-if="extra.extra.type === VehicleExtraType.EXTRA_KM"
            ><template #modal>{{
              t('extrasKmInfo', {
                price: formatCurrency(booking.pricePerExtraKm),
              })
            }}</template></Information
          >
        </div>
        <div class="flex gap-2 overflow-y-auto">
          <button
            v-for="button in extra.buttons"
            :key="button.option.id"
            class="flex w-32 flex-shrink-0 flex-col justify-between gap-2 overflow-hidden rounded-md border border-primary px-3 py-2"
            :class="{
              'border-gold-200 bg-gold-200': button.booked,
              'border-success bg-success text-white':
                !button.booked &&
                changedBookedExtraTypes[extra.extra.type]?.vehicleExtraOptionId ===
                  button.option.id,
              'hover:border-black hover:bg-black hover:text-white':
                !disabled &&
                !button.booked &&
                changedBookedExtraTypes[extra.extra.type]?.vehicleExtraOptionId !==
                  button.option.id,
            }"
            :disabled="(button.booked && !changedBookedExtraTypes[extra.extra.type]) || disabled"
            @click.prevent="selectOption(extra, button, $event.target as HTMLButtonElement)"
          >
            <div class="font-medium">{{ button.title }}</div>
            <div class="text-xs">{{ !button.booked ? '+ ' : '' }}{{ button.priceLabel }}</div>
          </button>
        </div>
      </div>
    </div>

    <p v-if="hasChanged && !recalculatingPrice" class="font-medium">
      {{ t('newPrice') }}:
      <span v-currency="priceCalculation?.totalPrice"></span>
    </p>

    <p v-if="hasChanged && recalculatingPrice" class="font-medium">
      {{ t('recalculatingPrice') }}
    </p>

    <div v-if="hasChanged" class="col-span-3 flex">
      <CVButton
        variant="success"
        :disabled="recalculatingPrice"
        :is-loading="isSaving"
        class="mr-2"
        @click.prevent="saveExtrasChange"
      >
        {{ t('save') }}
      </CVButton>
      <CVButton
        variant="warning"
        :disabled="isSaving || recalculatingPrice"
        class="mr-2"
        @click.prevent="cancelExtrasChange"
      >
        {{ t('cancel') }}
      </CVButton>
    </div>

    <div v-if="showCheckbox" class="form-control col-span-3">
      <label class="label cursor-pointer justify-start">
        <input v-model="extrasConfirmed" type="checkbox" class="checkbox mr-2 bg-white" />
        <span class="label-text">{{ t('doubleCheckExtras') }}</span>
      </label>
    </div>

    <div
      v-if="recalculatingPrice"
      class="absolute -inset-2 flex items-center justify-center bg-black/20"
    >
      <Spinner />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import type { Pricing } from '@/entities/pricing.entity';
import { type VehicleExtraOption } from '@/entities/vehicle-extra-option.entity';
import { VehicleExtraType } from '@/entities/vehicle-extra-type.enum';
import type { VehicleExtra } from '@/entities/vehicle-extra.entity';
import type {
  ChangedBookedExtraTypes,
  RecalculatePriceParams,
} from '@/hooks/use-recalculate-price';
import { formatCurrency } from '@/utils/format-numbers';
import { getCalculatedExtraOptionPrice } from '@/utils/get-calculated-extra-option-price';
import { sortOptionsByPrice } from '@/utils/sort-options-by-price';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Spinner from '../icons/Spinner.vue';
import { pickBy } from 'lodash';
import Information from '../Information.vue';
import { isBiggerExtraOption } from '@/utils/is-bigger-extra-option';
import confetti from 'canvas-confetti';

const props = defineProps<{
  extrasConfirmed: boolean;
  availableExtras: VehicleExtra[];
  disabled?: boolean;
  showCheckbox?: boolean;
  priceCalculation: Pricing;
  recalculatingPrice: boolean;
  totalRentalDays: number | null;
  isSaving: boolean;
  booking: Booking;
}>();

const emit = defineEmits<{
  (e: 'update:extrasConfirmed', value: boolean): void;
  (e: 'update:priceCalculation', value: Pricing): void;
  (e: 'hasChanged', value: boolean): void;
  (e: 'recalculate-price', value: RecalculatePriceParams): void;
  (
    e: 'update-booking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished: () => void,
  ): void;
}>();

const { t } = useI18n();

const changedBookedExtraTypes = ref<ChangedBookedExtraTypes>({});
const extrasConfirmed = ref(props.extrasConfirmed);

const hasChanged = computed(() => Object.keys(changedBookedExtraTypes.value).length > 0);

const extrasUpgradeData = computed(() => {
  return props.availableExtras.map((extra) => {
    const bookedExtra = props.booking.priceCalculation.bookedExtras.find(
      (be) => be.vehicleExtraSnapshot.type === extra.type,
    );
    const sortedOptions = sortOptionsByPrice(extra.options, props.totalRentalDays);
    const bookedOption = bookedExtra?.vehicleExtraOptionSnapshot ?? sortedOptions[0];
    const options = sortedOptions.filter((option) => {
      return isBiggerExtraOption(option, bookedOption, extra, props.totalRentalDays);
    });
    options.unshift(bookedOption);

    const { extraName, priceLabel } = getOptionData(extra, bookedOption);
    const buttons = options.map((option, index) => {
      const booked = bookedExtra
        ? option.id === bookedExtra.vehicleExtraOptionSnapshot.id
        : index === 0;
      const { title, priceLabel } = getOptionData(
        extra,
        option,
        getCalculatedExtraOptionPrice(bookedOption, !booked ? props.totalRentalDays : 0),
      );
      return {
        option,
        title,
        priceLabel,
        booked,
      };
    });

    return {
      extra,
      extraName,
      priceLabel,
      isBooked: (bookedExtra?.calculatedPrice ?? 0) > 0,
      buttons,
    };
  });
});

const getOptionData = (extra: VehicleExtra, option: VehicleExtraOption, bookedPrice = 0) => {
  const price = getCalculatedExtraOptionPrice(option, props.totalRentalDays);

  const notPerDay = extra.type === VehicleExtraType.EXTRA_KM;

  const priceLabel = notPerDay
    ? `${formatCurrency(price - bookedPrice)}`
    : `${formatCurrency(
        price / (props.totalRentalDays ?? 1) - bookedPrice / (props.totalRentalDays ?? 1),
      )}/${t('day')}`;

  const name = `${
    option.value === 'true'
      ? t('yes')
      : option.value === 'false'
        ? t('no')
        : option.name.length > 0
          ? option.name
          : option.value
  }`;
  const title = name;
  return {
    extraName: t(`vehicleExtras.${extra.type}`),
    title: title,
    priceLabel,
  };
};

const selectOption = (
  { extra }: (typeof extrasUpgradeData.value)[number],
  { option, booked }: (typeof extrasUpgradeData.value)[number]['buttons'][number],
  buttonElement: HTMLButtonElement,
) => {
  const { top, left, right, bottom } = buttonElement.getBoundingClientRect();
  if (
    !changedBookedExtraTypes.value[extra.type]?.vehicleExtraOptionId ||
    isBiggerExtraOption(
      option,
      props.availableExtras
        .find((ae) => ae.type === extra.type)!
        .options.find(
          (o) => o.id === changedBookedExtraTypes.value[extra.type]?.vehicleExtraOptionId,
        )!,
      extra,
      props.totalRentalDays,
    )
  ) {
    confetti({
      origin: {
        x: (left + (right - left) / 2) / window.innerWidth,
        y: (top + (bottom - top) / 2) / window.innerHeight,
      },
      colors: ['#CDAF74'],
    });
  }
  if (changedBookedExtraTypes.value[extra.type]?.vehicleExtraOptionId === option.id) {
    changedBookedExtraTypes.value[extra.type] = undefined;
  } else {
    changedBookedExtraTypes.value[extra.type] = !booked
      ? {
          vehicleExtraId: extra.id,
          vehicleExtraOptionId: option.id,
        }
      : undefined;
  }
  changedBookedExtraTypes.value = pickBy(
    changedBookedExtraTypes.value,
    (value) => value !== undefined,
  );
  if (Object.entries(changedBookedExtraTypes.value).length) {
    return emit('recalculate-price', {
      changedBookedExtraTypes: changedBookedExtraTypes.value,
      showAlert: false,
      nullExtrasOnConfirm: false,
    });
  }
  emit('update:priceCalculation', props.booking.priceCalculation);
};

const saveExtrasChange = async () => {
  const updateBookingValues: UpdateBookingDto = {
    priceCalculationId: props.priceCalculation.id,
  };
  emit(
    'update-booking',
    {
      id: props.booking.id,
      booking: updateBookingValues,
    },
    () => (changedBookedExtraTypes.value = {}),
  );
};

const cancelExtrasChange = () => {
  changedBookedExtraTypes.value = {};
  emit('update:priceCalculation', props.booking.priceCalculation);
};

watch(extrasConfirmed, (newValue) => emit('update:extrasConfirmed', newValue));
watch(hasChanged, (newValue) => emit('hasChanged', newValue));
</script>

<i18n lang="json">
{
  "en": {
    "upgradeExtras": "Upgrade Extras",
    "no": "No",
    "yes": "Yes",
    "day": "Day",
    "noOption": "No",
    "newPrice": "New Price",
    "recalculatingPrice": "Recalculating Price...",
    "doubleCheckExtras": "Was informed about deductible, cross border, additional drivers & extra kilometers",
    "extrasKmInfo": "Each additional km will be charged with {price}"
  },
  "de": {
    "upgradeExtras": "Extras upgraden",
    "no": "Nein",
    "yes": "Ja",
    "day": "Tag",
    "noOption": "Kein",
    "newPrice": "Neuer Preis",
    "recalculatingPrice": "Preis wird neu berechnet...",
    "doubleCheckExtras": "Wurde über Selbstbeteiligung, Auslandsfahrt, Zusatzfahrer & Extrakilometer aufgeklärt",
    "extrasKmInfo": "Jeder zusätzliche Km wird mit {price} berechnet"
  }
}
</i18n>
