<template>
  <div class="space-y-4">
    <p class="text-2xl font-medium">
      {{ t('enterInspectionAppointmentDate') }}
    </p>

    <div class="grid grid-cols-3 gap-4">
      <LabeledText :label="t('lastInspectionDate')">
        {{ formattedLastInspectionDate }}
      </LabeledText>
      <LabeledText :label="t('currentMileage')"> {{ formatFloat(car.milage) }} km </LabeledText>
      <LabeledText :label="t('appointmentDueFor')">
        {{ formatFloat(appointmentDueForMilage) }} km
      </LabeledText>
      <DatepickerField
        name="addInspection"
        :initial-value="null"
        :label="t('addInspectionAppointmentDate')"
        enable-time-picker
      />
    </div>

    <div class="h-52"></div>

    <Divider />

    <div class="flex justify-end">
      <CVButton :is-loading="isUpdatingCar" @click.prevent="submit">
        {{ t('enterAppointmentDate') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Car } from '@/entities/cars/car.entity';
import { getDateFormat } from '@/hooks/use-date-format';
import { useFormatedDateString } from '@/hooks/use-formated-date';
import { useI18n } from 'vue-i18n';
import { computed, toRef } from 'vue';
import Divider from '@/components/Divider.vue';
import { useForm } from 'vee-validate';
import { useUpdateCar } from '@/queries/use-cars';
import * as yup from 'yup';
import { toTypedSchema } from '@vee-validate/yup';
import { dateOrStringRequired } from '@/validation/car.schema';
import DatepickerField from '@/components/DatepickerField.vue';
import LabeledText from '@/components/LabeledText.vue';
import { formatFloat } from '@/utils/format-numbers';
import { useCurrentInspectionCycleMileage } from '@/hooks/use-current-inspection-cycle-mileage';

const props = defineProps<{
  car: Car;
}>();

const emit = defineEmits<{
  (e: 'complete'): void;
}>();

const { t } = useI18n();

const formattedLastInspectionDate = useFormatedDateString(
  computed(() =>
    props.car.inspections.length
      ? props.car.inspections[props.car.inspections.length - 1].date
      : null,
  ),
  getDateFormat(),
  '-',
);
const appointmentDueForMilage = useCurrentInspectionCycleMileage(toRef(props, 'car'));

const { mutateAsync: updateCar, isPending: isUpdatingCar } = useUpdateCar();

const { handleSubmit } = useForm({
  initialValues: {
    addInspection: undefined,
  },
  validationSchema: toTypedSchema(
    yup.object({
      addInspection: dateOrStringRequired(),
    }),
  ),
});

const submit = handleSubmit(async (values) => {
  await updateCar({
    id: props.car.id,
    car: values,
  });
  emit('complete');
});
</script>

<i18n lang="json">
{
  "en": {
    "enterInspectionAppointmentDate": "Enter inspection appointment date",
    "lastInspectionDate": "Last inspection date",
    "currentMileage": "Current mileage",
    "appointmentDueFor": "Appointment due for",
    "addInspectionAppointmentDate": "Add inspection appointment date",
    "enterAppointmentDate": "Enter appointment date"
  },
  "de": {
    "enterInspectionAppointmentDate": "Inspektionstermin eingeben",
    "lastInspectionDate": "Letzter Inspektionstermin",
    "currentMileage": "Aktueller Kilometerstand",
    "appointmentDueFor": "Termin fällig für",
    "addInspectionAppointmentDate": "Inspektionstermin hinzufügen",
    "enterAppointmentDate": "Termin speichern"
  }
}
</i18n>
