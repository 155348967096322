<template>
  <div class="mt-4 grid grid-cols-3 gap-4">
    <InputField name="company" :label="t('company')" />
    <div class="col-span-2"></div>
    <SelectField name="gender" :label="t('gender')" :options="genderOptions" />
    <InputField name="firstName" :label="t('firstName')" />
    <InputField name="lastName" :label="t('lastName')" />
    <InputField name="email" :label="t('email')" />
    <InputField name="phone" :label="t('phone')" />
    <DatepickerField name="dateOfBirth" :label="t('dateOfBirth')" />
    <div class="flex gap-x-2">
      <InputField class="grow-0 basis-1/2" name="country" :label="t('country')" />
      <SelectField
        class="basis-1/2"
        name="preferredLocal"
        :label="t('preferredLocal')"
        :options="preferredLocalOptions"
      />
    </div>
    <InputField class="w-full grow" name="street" :label="t('street')" />
    <div class="flex gap-2">
      <InputField class="basis-2/5" name="zip" :label="t('zip')" />
      <InputField name="city" :label="t('city')" />
    </div>
  </div>

  <div class="mt-6 flex justify-end gap-4">
    <CVButton variant="warning" :disabled="isSaving" @click.prevent="$emit('onCancel')">{{
      t('cancel')
    }}</CVButton>
    <CVButton variant="success" :is-loading="isSaving" @click.prevent="onSave">{{
      t('createAndLink')
    }}</CVButton>
  </div>
</template>

<script lang="ts" setup>
import DatepickerField from '@/components/DatepickerField.vue';
import InputField from '@/components/InputField.vue';
import SelectField from '@/components/SelectField.vue';
import { Gender } from '@/entities/auth/gender.enum';
import { Local } from '@/entities/auth/local.enum';
import type { CreateUserDto } from '@/entities/auth/user.entity';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { computed } from 'vue';
import type { ObjectSchema } from 'yup';

const props = defineProps<{
  schema: ObjectSchema<any>;
  isSaving: boolean;
}>();

const emit = defineEmits<{
  (e: 'onCancel'): void;
  (e: 'onSave', value: CreateUserDto): void;
}>();

const { t } = useI18n();

const genderOptions = computed(() =>
  Object.values(Gender).map((value) => ({
    label: t(`gender.${value}`),
    value,
  })),
);

const preferredLocalOptions = computed(() =>
  Object.values(Local).map((value) => ({
    label: t(`locales.${value}`),
    value,
  })),
);

const { handleSubmit } = useForm<CreateUserDto>({
  validationSchema: props.schema,
});

const onSave = handleSubmit((values) => {
  emit('onSave', values);
});
</script>

<i18n lang="json">
{
  "en": {
    "company": "Company",
    "gender": "Gender",
    "firstName": "First Name",
    "lastName": "Last Name",
    "email": "Email",
    "city": "City",
    "zip": "Zip",
    "street": "Street",
    "phone": "Phone",
    "country": "Country",
    "dateOfBirth": "Date Of Birth",
    "preferredLocal": "Pref. Language",
    "createAndLink": "Create and link new Customer"
  },
  "de": {
    "company": "Firma",
    "gender": "Geschlecht",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "email": "Email",
    "city": "Stadt",
    "zip": "PLZ",
    "street": "Straße",
    "phone": "Telefon",
    "country": "Land",
    "dateOfBirth": "Geburtsdatum",
    "preferredLocal": "Bev. Sprache",
    "createAndLink": "Neuen Kunden anlegen und verknüpfen"
  }
}
</i18n>
