<template>
  <div>
    <div class="flex justify-between">
      <p class="text-2xl font-medium">
        {{ car.vehicleType.make.name }} {{ car.vehicleType.model }}
      </p>

      <div class="flex justify-end gap-6">
        <FeatureIcon :icon="hpIcon" :label="t('hp', { power: car.vehicleType.power })" />
        <FeatureIcon
          :icon="accelerationIcon"
          :label="t('acceleration', { acceleration: car.vehicleType.accelaration })"
        />
        <FeatureIcon :icon="seatsIcon" :label="t('seats', { seats: car.vehicleType.seats })" />
        <FeatureIcon
          :icon="minAgeIcon"
          :label="t('minAgeIcon', { minAge: car.vehicleType.minAge })"
        />
      </div>
    </div>

    <div class="mb-6 grid grid-cols-2 gap-4">
      <img :src="imageUrl" class="max-h-44" />
      <FeatureList :title="t('featuresIncluded')" :features="featuresIncluded" class="mb-4 mt-10" />
    </div>

    <div class="grid grid-cols-2 gap-4">
      <FeatureList :title="t('otherFeatures')" :features="otherFeatures" />
      <FeatureList :title="t('rentRequirements')" :features="rentRequirements" />
    </div>

    <Divider />

    <div v-if="hasTransfers">
      <Transfers
        :car-station="carStation"
        :pickup-location="pickupLocation"
        :dropoff-location="dropoffLocation"
        :transfer-delivery-price="transferDeliveryPrice"
        :transfer-return-price="transferReturnPrice"
      />

      <Divider />
    </div>

    <Extras
      :booked-extras-map="bookedExtrasMap"
      :car="car"
      :rent-duration="rentDuration"
      :is-loading="isRequestingPricing"
      @change="(extrasMap) => onExtrasChange(extrasMap)"
    />

    <Divider />

    <div v-if="authStore.user?.role !== UserRole.AGENT">
      <Checkbox
        :model-value="isAgencyBooking"
        :label="t('isAgencyBooking')"
        @change="(value) => $emit('updateIsAgencyBooking', value)"
      />

      <Divider />
    </div>

    <div class="flex items-end justify-between">
      <LocationsAndTimesInfo
        :pickup-location="pickupLocation"
        :dropoff-location="dropoffLocation"
        :start-date="startDate"
        :end-date="endDate"
        :rent-duration="rentDuration"
      />
      <div class="flex items-center gap-4">
        <TotalPrice :total-price="totalPrice" />
        <CVButton :is-loading="isRequestingPricing" @click="onContinue">{{
          t('continue')
        }}</CVButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Car } from '@/entities/cars/car.entity';
import { computed, toRef } from 'vue';
import accelerationIcon from '@/assets/car-feature-icons/acceleration.png';
import hpIcon from '@/assets/car-feature-icons/hp.png';
import minAgeIcon from '@/assets/car-feature-icons/min-age.png';
import seatsIcon from '@/assets/car-feature-icons/seats.png';
import FeatureIcon from './FeatureIcon.vue';
import FeatureList from './FeatureList.vue';
import { useI18n } from 'vue-i18n';
import { getInclusiveKm } from '@/utils/get-inclusive-km';
import { formatCurrency } from '@/utils/format-numbers';
import { useTrueCarAttributes } from '@/hooks/use-true-car-attributes';
import Extras from './Extras.vue';
import Divider from '@/components/Divider.vue';
import Checkbox from '@/components/Checkbox.vue';
import type { BookingLocation } from '@/entities/bookings/booking.entity';
import type { Pricing } from '@/entities/pricing.entity';
import { useRequestPricing } from '@/queries/use-pricing';
import Transfers from './Transfers.vue';
import TotalPrice from '../../TotalPrice.vue';
import LocationsAndTimesInfo from '../../LocationsAndTimesInfo.vue';
import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import { useAuthStore } from '@/stores/auth.store';
import { UserRole } from '@/entities/auth/user-role.enum';
import { useStationOfCarByDate } from '@/hooks/use-current-station-of-car';

const props = defineProps<{
  car: Car;
  pickupLocation: BookingLocation;
  dropoffLocation: BookingLocation;
  startDate: Date;
  endDate: Date;
  rentDuration: number;
  transferDeliveryPrice?: number | null;
  transferReturnPrice?: number | null;
  totalPrice: number;
  bookedExtrasMap: Record<string, string>;
  isAgencyBooking: boolean;
  pricing: Pricing | null;
}>();

const emit = defineEmits<{
  (e: 'updateBookedExtrasMap', value: Record<string, string>): void;
  (e: 'updateIsAgencyBooking', value: boolean): void;
  (e: 'updatePricing', value: Pricing | null): void;
  (e: 'continue'): void;
}>();

const { t } = useI18n();
const authStore = useAuthStore();

const carStation = useStationOfCarByDate(toRef(props, 'car'), toRef(props, 'startDate'));

const hasDivergentDropoffLocation = computed(
  () =>
    props.dropoffLocation.locationType !== BookingLocationType.STATION ||
    props.dropoffLocation.station?.id !== carStation.value.id,
);

const hasTransfers = computed(
  () => props.transferDeliveryPrice !== null || props.transferReturnPrice !== null,
);

const { mutateAsync: requestPricing, isPending: isRequestingPricing } = useRequestPricing();

const { imageUrl, hasDistanceControl, hasNavigation, transmission, drive } = useTrueCarAttributes(
  toRef(props, 'car'),
);

const inclusiveKm = computed(() =>
  getInclusiveKm(props.car.vehicleType.inclusiveKm, props.rentDuration),
);

const otherFeatures = computed(() => [
  ...(hasNavigation.value ? [t('navigation')] : []),
  ...(hasDistanceControl.value ? [t('distanceControl')] : []),
  t(`transmissionTypes.${transmission.value}`),
  t(`driveTypes.${drive.value}`),
]);

const featuresIncluded = computed(() => [
  t('insurance'),
  inclusiveKm.value + ' ' + t('kmIncluded'),
]);

const rentRequirements = computed(() => [
  t('deposit', { deposit: formatCurrency(props.car.vehicleType.deposit ?? 0) }),
  t('minAge', { age: props.car.vehicleType.minAge }),
  t('licenceNeededForXYears', {
    duration: props.car.vehicleType.licenceNeededForXYears,
  }),
]);

const onExtrasChange = (extrasMap: Record<string, string>) => {
  emit('updateBookedExtrasMap', extrasMap);
  requestNewPricing(extrasMap);
};

const requestNewPricing = async (extrasMap?: Record<string, string>) => {
  const pricing = await requestPricing({
    carId: props.car.id,
    startDate: props.startDate,
    endDate: props.endDate,
    bookedExtras: Object.entries(extrasMap ?? props.bookedExtrasMap).map(([extraId, optionId]) => ({
      vehicleExtraId: extraId,
      vehicleExtraOptionId: optionId,
    })),
    customBookedExtras: [
      ...(props.transferDeliveryPrice
        ? [
            {
              description: 'Transfer Delivery',
              price: props.transferDeliveryPrice,
              customVehicleExtraType: null,
              value: null,
            },
          ]
        : []),
      ...(props.transferReturnPrice
        ? [
            {
              description: 'Transfer Return',
              price: props.transferReturnPrice,
              customVehicleExtraType: null,
              value: null,
            },
          ]
        : []),
    ],
    hasDivergentDropoffLocation: hasDivergentDropoffLocation.value,
  });
  emit('updatePricing', pricing);
};

const onContinue = async () => {
  if (!props.pricing) {
    await requestNewPricing();
  }
  emit('continue');
};
</script>

<i18n lang="json">
{
  "en": {
    "otherFeatures": "Other Features",
    "distanceControl": "Distance Control",
    "featuresIncluded": "The rent includes",
    "insurance": "Fully comprehensive insurance with deductible",
    "kmIncluded": "km included",
    "minAge": "Minimum age {age} years",
    "licenceNeededForXYears": "Driving license possession for at least {duration} years",
    "deposit": "Deposit {deposit} by credit card",
    "rentRequirements": "Requirements for the rental",
    "navigation": "GPS/navigation",
    "isAgencyBooking": "Is booked via an agency",
    "continue": "Continue",
    "hp": "{power} HP",
    "acceleration": "0-100 kph {acceleration}s",
    "seats": "{seats} seats",
    "minAgeIcon": "From {age} years"
  },
  "de": {
    "otherFeatures": "Weitere Ausstattungsmerkmale",
    "distanceControl": "Parkabstandswarner",
    "featuresIncluded": "Die Miete ist inklusive",
    "insurance": "Vollkaskoversicherung mit SB",
    "kmIncluded": "Freikilometer",
    "minAge": "Mindestalter {age} Jahre",
    "licenceNeededForXYears": "Führerscheinbesitz seit mindestens {duration} Jahren",
    "deposit": "Kaution {deposit} per Kreditkarte",
    "rentRequirements": "Voraussetzungen für die Miete",
    "navigation": "GPS/Navigation",
    "isAgencyBooking": "Wird über eine Agency gebucht",
    "continue": "Weiter",
    "hp": "{power} PS",
    "acceleration": "0-100 km/h {acceleration}s",
    "seats": "{seats} Sitze",
    "minAgeIcon": "Ab {minAge} Jahren"
  }
}
</i18n>
