import { BookingLocationType } from '@/entities/bookings/booking-location-type.enum';
import type {
  BookingCoordinates,
  LocationGeocodedAddress,
} from '@/entities/bookings/booking.entity';
import { useMapApiWithCoordinates } from '@/queries/use-map-api';
import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { computed } from 'vue';

interface QueryLocations {
  pickupLocationType: BookingLocationType;
  dropoffLocationType: BookingLocationType;
  pickupLocation?: BookingCoordinates | null;
  dropoffLocation?: BookingCoordinates | null;
  pickupLocationGeocodedAddress?: LocationGeocodedAddress | null;
  dropoffLocationGeocodedAddress?: LocationGeocodedAddress | null;
}

export const useLocationsData = (locations: MaybeRefOrGetter<QueryLocations | undefined>) => {
  const pickupLocation = computed(
    () =>
      toValue(locations)?.pickupLocation ?? {
        latitude: 0,
        longitude: 0,
      },
  );
  const dropoffLocation = computed(
    () =>
      toValue(locations)?.dropoffLocation ?? {
        latitude: 0,
        longitude: 0,
      },
  );
  const locationUnrefed = toValue(locations);
  const {
    data: pickupLocationData,
    refetch: fetchPickupLocation,
    isFetching: isFetchingPickupLocation,
  } = useMapApiWithCoordinates(pickupLocation, 'pickup', false);
  const {
    data: dropoffLocationData,
    refetch: fetchDropoffLocation,
    isFetching: isFetchingDropoffLocation,
  } = useMapApiWithCoordinates(dropoffLocation, 'dropoff', false);
  if (
    locationUnrefed?.pickupLocationType === BookingLocationType.STREET &&
    !locationUnrefed?.pickupLocationGeocodedAddress
  ) {
    fetchPickupLocation();
  }
  if (
    locationUnrefed?.dropoffLocationType === BookingLocationType.STREET &&
    !locationUnrefed?.dropoffLocationGeocodedAddress
  ) {
    fetchDropoffLocation();
  }

  return {
    pickupLocationData,
    fetchPickupLocation,
    isFetchingPickupLocation,
    dropoffLocationData,
    fetchDropoffLocation,
    isFetchingDropoffLocation,
  };
};
