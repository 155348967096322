<template>
  <div class="mb-4 flex items-center justify-between">
    <h2>{{ t('vehicleCondition') }}</h2>
    <CVButton @click.prevent="showNewDamageModal = true">
      {{ addDamageButtonText ?? t('addDamage') }}
    </CVButton>
  </div>
  <div class="grid grid-cols-6 gap-4">
    <MarkerContainer class="col-span-6 col-start-1 self-start" :damages="damages"></MarkerContainer>
    <Tabs v-model="activeTab" :tabs="tabs" class="col-span-6" />
    <div class="col-span-6 col-start-1 grid grid-cols-2 gap-4 md:grid-cols-3">
      <DamageCard
        v-for="(damage, i) in damages"
        :key="i"
        :damage="damage"
        :index="i + 1"
        :show-repaired-status="activeTab === Tab.NEW"
        @on-view="viewDamage(damage)"
      />
    </div>
  </div>

  <vue-final-modal
    v-model="showNewDamageModal"
    :click-to-close="false"
    class="flex items-center justify-center"
    content-class="modal-box p-0 w-11/12 max-w-5xl h-max"
    content-transition="vfm-fade"
    overlay-transition="vfm-fade"
  >
    <DamagesForm :car-id="carId" :booking-id="bookingId" @on-close="onCloseNewDamageModal" />
  </vue-final-modal>

  <vue-final-modal
    v-model="showViewDamageModal"
    class="flex items-center justify-center"
    content-class="modal-box p-0 w-11/12 max-w-5xl h-max"
    content-transition="vfm-fade"
    overlay-transition="vfm-fade"
  >
    <DamageViewer v-if="viewedDamage" :damage="viewedDamage" />
  </vue-final-modal>
</template>

<script lang="ts" setup>
import type { Damage } from '@/entities/damage.entity';
import { useI18n } from 'vue-i18n';
import { useDamages } from '@/queries/use-damages';
import { computed, ref, watchEffect } from 'vue';
import DamageCard from './components/DamageCard.vue';
import MarkerContainer from './components/MarkerContainer.vue';
import DamagesForm from './DamagesForm.vue';
import DamageViewer from './DamageViewer.vue';
import Tabs, { type TabItem } from '@/components/Tabs.vue';
import { DamageStatus } from '@/entities/damage-status.enum';
import { useModalVisibility } from '@/utils/use-modal-visibility';

enum Tab {
  ALL = 'ALL',
  NEW = 'NEW',
  REPAIRED = 'REPAIRED',
}
const props = defineProps<{
  carId: string;
  bookingId?: string;
  addDamageButtonText?: string;
}>();

const { t } = useI18n();
const showNewDamageModal = useModalVisibility<boolean>('new-damage');
const showViewDamageModal = useModalVisibility<string>('view-damage');

const activeTab = ref<Tab>(Tab.ALL);
const tabs = computed<TabItem[]>(() => [
  { title: t('damages'), id: Tab.ALL },
  { title: t('newDamages'), id: Tab.NEW },
  { title: t('repaired'), id: Tab.REPAIRED },
]);

const { data: damagesOfCar } = useDamages({
  carIds: props.carId,
});
const { data: damagesOfBooking } = useDamages({ bookingIds: props.bookingId });
const newDamageIds = ref<string[]>([]);

const damages = computed(() => {
  switch (activeTab.value) {
    case Tab.NEW:
      return newDamages.value;
    case Tab.REPAIRED:
      return damagesOfCar.value?.filter((damage) => damage.status === DamageStatus.REPAIRED);
    default:
      return damagesOfCar.value?.filter((damage) => damage.status === DamageStatus.OPEN);
  }
});

const viewedDamage = computed(() => damages.value?.find((d) => d.id === showViewDamageModal.value));

const newDamages = computed(() => {
  return props.bookingId
    ? damagesOfBooking.value
    : damagesOfCar.value?.filter((damage) => newDamageIds.value.some((id) => id === damage.id));
});

const onCloseNewDamageModal = (newDamageId?: string) => {
  showNewDamageModal.value = false;
  if (!newDamageId) return;
  newDamageIds.value.push(newDamageId);
  activeTab.value = Tab.NEW;
};

const viewDamage = (damage: Damage) => {
  showViewDamageModal.value = damage.id;
};

watchEffect(() => {
  if (
    showViewDamageModal.value &&
    damages.value &&
    !damages?.value?.some((d) => d.id === showViewDamageModal.value)
  ) {
    showViewDamageModal.value = false;
  }
});
</script>

<i18n lang="json">
{
  "en": {
    "description": "Sort Description of Damage",
    "saveImage": "save image",
    "mark": "Mark the damage",
    "damage": "Damage",
    "addDamage": "+ add damage",
    "damages": "All Damages",
    "newDamages": "New Damages",
    "repaired": "Repaired",
    "vehicleCondition": "Vehicle Condition"
  },
  "de": {
    "description": "Kurze Beschreibung des Schadens",
    "saveImage": "Bild Speichern",
    "mark": "Markiere den Schaden",
    "damage": "Schaden",
    "addDamage": "+ Schaden hinzufügen",
    "damages": "Alle Schäden",
    "newDamages": "Neue Schäden",
    "repaired": "Repariert",
    "vehicleCondition": "Fahrzeugzustand"
  }
}
</i18n>
