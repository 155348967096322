<template>
  <form class="space-y-4" @submit="onSubmit">
    <h2>1. {{ t('vehicleType') }}</h2>

    <div class="grid grid-cols-3 gap-4">
      <SelectField name="vehicleTypeId" :label="t('vehicleType')" :options="vehicleTypeOptions" />
    </div>

    <Divider />

    <h2>2. {{ t('uploadSpecificCarImage') }}</h2>

    <div class="grid grid-cols-3 gap-4">
      <UploadCarImage
        class="col-span-2"
        :selected-image-file="formValues.selectedImageFile"
        :selected-image-url="selectedImageUrl"
        :error="imageError"
        @select="onSelectImage"
      />
    </div>

    <div v-if="!isGhostCar" class="space-y-4">
      <Divider />

      <h2>3. {{ t('purchaseLeasingContract') }}</h2>

      <div class="grid grid-cols-3 gap-4">
        <InputField name="contractName" :label="t('documentName')" />

        <FileInputField name="contractFile" :label="t('upload')" />
      </div>

      <TextareaField name="contractComment" :label="t('additionalAgreements')" />

      <Divider />

      <h2>4. {{ t('procurementDetails') }}</h2>

      <div class="grid grid-cols-3 gap-4">
        <SelectField
          name="acquisitionType"
          :label="t('acquisitionType')"
          :options="acquisitionTypeOptions"
        />

        <InputField
          v-if="
            formValues.acquisitionType === CarAcquisitionType.PURCHASED_NEW ||
            formValues.acquisitionType === CarAcquisitionType.PURCHASED_USED
          "
          name="purchasePrice"
          :label="t('purchasePrice')"
          type="number"
          :max-fraction-digits="2"
          :min="0"
          adornment="€"
          :tooltip="t('purchasePriceInfo')"
        />

        <InputField
          v-if="formValues.acquisitionType === CarAcquisitionType.LEASED"
          name="leasingRate"
          :label="t('leasingRate')"
          type="number"
          :max-fraction-digits="2"
          :min="0"
          adornment="€"
          :tooltip="t('leasingRateInfo')"
        />

        <InputField
          name="listPrice"
          :label="t('listPrice')"
          type="number"
          :max-fraction-digits="2"
          :min="0"
          adornment="€"
          :tooltip="t('listPriceInfo')"
        />

        <DatepickerField
          :label="t('deliveryOn')"
          :name="`availableFrom`"
          :tooltip="t('deliveryOnInfo')"
        />

        <SelectField name="color" :label="t('color')" :options="colorOptions" empty-value-to-null />

        <SelectField name="stationId" :label="t('station')" :options="stationOptions" />

        <SelectField name="tyres" :label="t('tyres')" :options="tyresOptions" />
      </div>
    </div>

    <div v-else class="space-y-4">
      <Divider />

      <h2>3. {{ t('carDetails') }}</h2>

      <div class="grid grid-cols-3 gap-4">
        <SelectField name="color" :label="t('color')" :options="colorOptions" empty-value-to-null />

        <SelectField name="stationId" :label="t('station')" :options="stationOptions" />

        <BooleanSelectField
          name="isMysteryCar"
          :label="t('mysteryCar')"
          :tooltip="t('isMysteryCarInfo')"
        />
      </div>

      <Divider />

      <PossibleUpgradeCars
        :station-id="formValues.stationId"
        :title="'4. ' + t('possibleUpgradeCars')"
        :errors="upgradeCarErrors"
      />
    </div>

    <Divider />

    <div v-if="showFeaturesAndTechnicalData" class="space-y-4">
      <h2>{{ t('features') }}</h2>

      <FeaturesFields :vehicle-type="vehicleType" />

      <Divider />

      <h2>{{ t('technicalData') }}</h2>

      <TechnicalDataFields :vehicle-type="vehicleType" :fuel-type="formValues.fuelType" />
    </div>

    <CVButton v-else @click.prevent="showFeaturesAndTechnicalData = true">{{
      t('editFeaturesAndTechnicalData')
    }}</CVButton>

    <Divider />

    <div class="flex justify-end">
      <CVButton :is-loading="isSaving" type="submit" :disabled="isSaving">
        {{ t('fleetInCar') }}
      </CVButton>
    </div>
  </form>
</template>

<script lang="ts" setup>
import SelectField from '@/components/SelectField.vue';
import type { ManyVehicleTypesVehicleType } from '@/entities/many-vehicle-types-vehicle-type.entity';
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import UploadCarImage from './UploadCarImage.vue';
import type { Car, UpdateCarDto } from '@/entities/cars/car.entity';
import type { VehicleType } from '@/entities/vehicle-type.entity';
import { useForm } from 'vee-validate';
import Divider from '@/components/Divider.vue';
import FileInputField from '@/components/FileInputField.vue';
import InputField from '@/components/InputField.vue';
import TextareaField from '@/components/TextareaField.vue';
import DatepickerField from '@/components/DatepickerField.vue';
import { CarColor } from '@/entities/cars/car-color.enum';
import type { Station } from '@/entities/station/station.entity';
import { CarTyres } from '@/entities/cars/car-tyres.enum';
import { CarAcquisitionType } from '@/entities/cars/car-acquisition-type.enum';
import FeaturesFields from './FeaturesFields.vue';
import TechnicalDataFields from './TechnicalDataFields.vue';
import { carFleetInSchema, ghostCarFleetInSchema } from '@/validation/car.schema';
import PossibleUpgradeCars from '../edit-car/components/PossibleUpgradeCars.vue';
import BooleanSelectField from '@/components/BooleanSelectField.vue';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

export type AddNewCarFormValues = UpdateCarDto & {
  selectedImageFile?: File | null;
  contractName?: string;
  contractFile?: File | null;
  contractComment?: string;
  stationId?: string;
  possibleUpgradeCars?: (Car | undefined)[];
};

const props = defineProps<{
  vehicleType?: VehicleType;
  vehicleTypes: ManyVehicleTypesVehicleType[];
  stations: Station[];
  isSaving: boolean;
  isGhostCar: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSubmit', value: AddNewCarFormValues): void;
  (e: 'vehicleTypeChange', value: string): void;
}>();

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();

const initialValues = ref<AddNewCarFormValues>({
  vehicleTypeId: props.vehicleType?.id,
  contractName: 'Kaufvertrag',
  acquisitionType: CarAcquisitionType.PURCHASED_NEW,
});

const showFeaturesAndTechnicalData = ref(false);
const selectedImageUrl = ref<string | undefined>(undefined);

const onSelectImage = (newImageFile: File | undefined, newImageUrl: string | undefined) => {
  setFieldValue('selectedImageFile', newImageFile);
  selectedImageUrl.value = newImageUrl;
};

const {
  handleSubmit,
  values: formValues,
  errors,
  setFieldValue,
} = useForm({
  initialValues: initialValues.value,
  validationSchema: !props.isGhostCar ? carFleetInSchema : ghostCarFleetInSchema,
});

const onSubmit = handleSubmit((values) => {
  emit('onSubmit', values);
});

const imageError = computed(() => {
  return errors.value.selectedImageFile;
});

const upgradeCarErrors = computed(() => {
  const upgradeCarErrors = Object.entries(errors.value).filter(
    ([key, error]) => key.startsWith('possibleUpgradeCars') && error,
  ) as unknown as [string, string[]];
  return upgradeCarErrors.length > 0 ? upgradeCarErrors.map(([, value]) => value) : undefined;
});

const vehicleTypeOptions = computed(() => {
  return props.vehicleTypes.map((vehicleType) => ({
    label: `${vehicleType.make.name} ${vehicleType.model}`,
    value: vehicleType.id,
  }));
});

const colorOptions = computed(() => {
  return Object.values(CarColor).map((color) => ({
    label: t(`carColors.${color}`),
    value: color,
  }));
});

const stationOptions = computed(() => {
  return props.stations.map((station) => ({
    label: getLocalizedField(station.info),
    value: station.id,
  }));
});

const tyresOptions = computed(() => {
  return Object.values(CarTyres).map((tyres) => ({
    label: t(`carTyres.${tyres}`),
    value: tyres,
  }));
});

const acquisitionTypeOptions = computed(() => {
  return Object.values(CarAcquisitionType).map((acquisitionType) => ({
    label: t(`carAcquisitionType.${acquisitionType}`),
    value: acquisitionType,
  }));
});

watch(
  () => formValues.vehicleTypeId,
  (vehicleTypeId) => {
    if (!vehicleTypeId) return;
    emit('vehicleTypeChange', vehicleTypeId);
  },
);
</script>

<i18n lang="json">
{
  "en": {
    "fleetInCar": "Fleet in car",
    "vehicleType": "Vehicle Type",
    "uploadSpecificCarImage": "Upload specific car image",
    "purchaseLeasingContract": "Purchase/Leasing contract",
    "carDetails": "Car details",
    "possibleUpgradeCars": "Possible upgrade cars",
    "procurementDetails": "Procurement details",
    "technicalData": "Technical Data (overwrites Vehicle Type)",
    "features": "Features (overwrites Vehicle Type)",
    "documentName": "Document name",
    "upload": "Upload",
    "additionalAgreements": "Additional agreements when contract is concluded",
    "acquisitionType": "Acquisition Type",
    "purchasePrice": "Purchase Price",
    "purchasePriceInfo": "The actual gross list price of the car minus discount (incl. transfer costs)",
    "leasingRate": "Leasing Rate",
    "leasingRateInfo": "The gross leasing rate incl. discount",
    "listPrice": "List Price",
    "listPriceInfo": "The gross list price",
    "deliveryOn": "Delivery on",
    "deliveryOnInfo": "For the delivery date we use a standardized method to determine the date if no specific one is stated on the purchase contract. If a quarter is given as the delivery date, we will choose the middle date of the period. This means that 'Q1' is symbolically the 15th of February, as a representative date in the middle of the first quarter. We proceed in the same way if you specify a specific month by setting the date in the middle of that month as target delivery date.",
    "color": "Color",
    "tyres": "Tyres",
    "editFeaturesAndTechnicalData": "Edit features and technical data",
    "mysteryCar": "Mystery car",
    "isMysteryCarInfo": "The customer will not know which car they will get until they receive the bookings confirmation mail."
  },
  "de": {
    "fleetInCar": "Fahrzeug einflotten",
    "vehicleType": "Vehicle Type",
    "uploadSpecificCarImage": "Spezifisches Fahrzeugbild hochladen",
    "purchaseLeasingContract": "Kauf-/Leasingvertrag",
    "carDetails": "Fahrzeugdetails",
    "possibleUpgradeCars": "Mögliche Upgrade-Fahrzeuge",
    "procurementDetails": "Beschaffungsdetails",
    "technicalData": "Technische Daten (überschreibt Vehicle Type)",
    "features": "Ausstattung (Überschreibt Vehicle Type)",
    "documentName": "Dokumentname",
    "upload": "Upload",
    "additionalAgreements": "Weitere Zusatzvereinbarungen bei Vertragsabschluss",
    "acquisitionType": "Beschaffungsart",
    "purchasePrice": "Kaufpreis",
    "purchasePriceInfo": "Der tatsächliche Brutto-Listenpreis des Autos abzüglich Rabatt (inkl. Überführungskosten)",
    "leasingRate": "Leasingrate",
    "leasingRateInfo": "Die Brutto Leasingrate inkl. Nachlass",
    "listPrice": "Listenpreis",
    "listPriceInfo": "Der Brutto Listenpreis",
    "deliveryOn": "Lieferung am",
    "deliveryOnInfo": "Für den Liefertermin nutzen wir eine standardisierte Methode um das Datum zu bestimmen, wenn kein genaues auf dem Kaufvertrag angegeben ist. Wenn als Lieferzeitpunkt ein Quartal angegeben wird, wählen wir das mittlere Datum des Zeitraums aus. Das bedeutet, 'Q1' steht beispielsweise symbolisch für den 15. Februar, als repräsentatives Datum in der Mitte des ersten Quartals. Genauso verfahren wir, wenn du einen spezifischen Monat angibst, indem wir das Datum in der Mitte dieses Monats als Ziellieferdatum festlegen.",
    "color": "Farbe",
    "tyres": "Bereifung",
    "editFeaturesAndTechnicalData": "Ausstattung und technische Daten bearbeiten",
    "mysteryCar": "Mystery Car",
    "isMysteryCarInfo": "Der Kunde weiß nicht, welches Fahrzeug er bekommt, bis er die Buchungsbestätigungsmail erhält."
  }
}
</i18n>
