<template>
  <div>
    <div v-if="makes" class="grid grid-cols-2 gap-4">
      <Select v-model="categoryFilter" :options="categoryOptions" />
      <Select v-model="makeFilter" :options="makeOptions" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Select from '@/components/Select.vue';
import { VehicleCategory } from '@/entities/vehicle-category.enum';
import { useI18n } from 'vue-i18n';
import { useMakes } from '@/queries/use-makes';
import { computed, ref, watch, watchEffect } from 'vue';

const props = defineProps<{
  categoryFilter: VehicleCategory | null;
  makeFilter: string | null;
}>();

const emit = defineEmits<{
  (
    event: 'change',
    value: {
      categoryFilter: VehicleCategory | null;
      makeFilter: string | null;
    },
  ): void;
}>();

const categoryFilter = ref(props.categoryFilter);
const makeFilter = ref(props.makeFilter);
watchEffect(() => {
  categoryFilter.value = props.categoryFilter;
});
watchEffect(() => {
  makeFilter.value = props.makeFilter;
});

const { t } = useI18n();

const { data: makes } = useMakes();

const categoryOptions = computed(() => [
  { label: t('vehicleCategory'), value: null },
  ...Object.values(VehicleCategory).map((value) => ({
    label: t(`categories.${value}`),
    value,
  })),
]);

const makeOptions = computed(() => [
  { label: t('premiumMake'), value: null },
  ...(makes.value?.map((make) => ({ label: make.name, value: make.id })) ?? []),
]);

watch([categoryFilter, makeFilter], () => {
  emit('change', {
    categoryFilter: categoryFilter.value,
    makeFilter: makeFilter.value,
  });
});
</script>

<i18n lang="json">
{
  "en": {
    "vehicleCategory": "Vehicle category",
    "premiumMake": "Premium make"
  },
  "de": {
    "vehicleCategory": "Fahrzeugkategorie",
    "premiumMake": "Premium Marke"
  }
}
</i18n>
