<template>
  <div class="mb-4 mt-6 border border-primary p-4">
    <div class="mb-4">
      <div class="grid grid-cols-2 gap-4">
        <SelectField class="w-full" name="type" :label="t('invoiceType')" :options="typeOptions" />
        <SelectField
          v-if="formValues.type === InvoiceType.REVERSAL"
          class="w-full"
          name="originalInvoiceId"
          :label="t('originalInvoice')"
          :options="originalInvoicesOptions"
        />
        <InputField name="title" :label="t('invoiceTitle')" class="col-start-1" />
        <DatepickerField name="invoiceDate" :label="t('invoiceDate')" />
      </div>
      <div class="mt-4 text-base font-medium lg:text-lg">
        {{ t('positions') }}
      </div>
      <div class="border border-primary p-4">
        <div v-for="(position, i) in positionFields" :key="i" class="relative">
          <div class="mb-4 grid grid-cols-3 gap-4 border-b border-primary pb-4">
            <InputField :name="`positions[${i}].title`" :label="t('title')" class="col-span-3" />
            <InputField
              :name="`positions[${i}].amount`"
              :label="t('amount')"
              type="number"
              :max-fraction-digits="0"
            />
            <InputField
              :name="`positions[${i}].unitPrice`"
              :label="t('unitPrice')"
              type="number"
              :max-fraction-digits="2"
              adornment="€"
            />
            <InputField
              :disabled="formValues.type === InvoiceType.DAMAGE"
              :name="`positions[${i}].tax`"
              :label="t('tax')"
              type="number"
              :max-fraction-digits="0"
              adornment="%"
            />
          </div>
          <div v-if="i !== 0" class="absolute right-0 top-0 flex items-center">
            <button
              class="cursor-pointer p-2 text-lg leading-3 text-red-600"
              @click="removePosition(i)"
            >
              ×
            </button>
          </div>
        </div>
        <div class="mt-4 flex justify-end">
          <CVButton @click.prevent="pushPosition(getEmptyPosition())">{{
            t('addPosition')
          }}</CVButton>
        </div>
      </div>
      <div class="mt-4 border-b border-primary pb-4">
        <div class="flex items-center gap-4">
          <Select
            v-model="selectedPaymentId"
            class="w-full"
            :label="t('paymentsMade')"
            :options="paymentsOptions"
            :empty-value-to-null="true"
            @change="addPaymentId"
          />
        </div>
        <div v-if="selectedPayments.length > 0" class="mt-2 flex justify-start gap-3">
          <div
            v-for="(payment, i) in selectedPayments"
            :key="i"
            class="rounded-full bg-primary px-3 py-0.5 text-white"
          >
            {{ paymentToHuman(payment) }}
            <button
              class="cursor-pointer px-0.5 py-2 text-lg leading-3"
              @click.prevent="removePaymentId(i)"
            >
              ×
            </button>
          </div>
        </div>
      </div>
      <Checkbox v-model="sendInvoiceOnSave" :label="t('sendInvoiceOnSave')" />
    </div>
    <div class="flex justify-end gap-2">
      <CVButton @click.prevent="$emit('onCancel')">
        {{ t('cancel') }}
      </CVButton>
      <CVButton @click.prevent="saveInvoice">
        {{ t('saveInvoice') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { InvoiceStatus } from '@/entities/invoices/invoice-status.enum';
import type { CreateInvoiceDto, Invoice } from '@/entities/invoices/invoice.entity';
import type { Payment } from '@/entities/payments/payment.entity';
import { formatDateString } from '@/hooks/use-formated-date';
import { useI18n } from 'vue-i18n';
import { formatCurrency } from '@/utils/format-numbers';
import { invoiceCreateSchema } from '@/validation/invoice.schema';
import { useFieldArray, useForm } from 'vee-validate';
import { computed, ref, watch } from 'vue';
import DatepickerField from '../DatepickerField.vue';
import InputField from '../InputField.vue';
import Select from '../Select.vue';
import Checkbox from '../Checkbox.vue';
import { InvoiceType } from '@/entities/invoices/invoice-type.enum';
import SelectField from '../SelectField.vue';
import type { Booking } from '@/entities/bookings/booking.entity';
import { Local } from '@/entities/auth/local.enum';
import { useDateFormat } from '@/hooks/use-date-format';

interface InvoiceFormValues {
  title: string;
  bookingId: string;
  invoiceDate: string;
  positions: PositionFormValues[];
  paymentIds: string[];
  type: InvoiceType;
  originalInvoiceId?: string;
}

interface PositionFormValues {
  title: string;
  amount: number | undefined;
  unitPrice: number | undefined;
  tax: number | undefined;
}

const props = defineProps<{
  booking: Booking;
  invoices: Invoice[];
  payments: Payment[];
}>();

const emit = defineEmits<{
  (e: 'onSubmit', newInvoice: CreateInvoiceDto, sendInvoiceOnSave: boolean): void;
  (e: 'onCancel'): void;
}>();

const { t } = useI18n();

const dateFormat = useDateFormat();
const selectedPaymentId = ref('');
const sendInvoiceOnSave = ref(true);

const { handleSubmit, values: formValues } = useForm({
  initialValues: getEmptyFormValues(),
  validationSchema: invoiceCreateSchema,
});

const {
  push: pushPosition,
  remove: removePosition,
  replace: replacePositions,
  fields: positionFields,
} = useFieldArray<PositionFormValues>('positions');

const {
  push: pushPaymentId,
  remove: removePaymentId,
  fields: paymentFields,
} = useFieldArray<string>('paymentIds');

const selectablePayments = computed(() =>
  props.payments.filter(
    (payment) =>
      !payment.invoice && !paymentFields.value.some((field) => field.value === payment.id),
  ),
);

const selectedPayments = computed(() =>
  paymentFields.value.map(
    (field) => props.payments.find((payment) => payment.id === field.value) as Payment,
  ),
);

const typeOptions = computed(() =>
  Object.values([
    InvoiceType.DAMAGE,
    InvoiceType.ADMINISTRATIVE_OFFENCE,
    InvoiceType.REVERSAL,
    InvoiceType.OTHER,
  ]).map((type) => ({
    label: t(`invoiceTypes.${type}`),
    value: type,
  })),
);

const originalInvoicesOptions = computed(() => [
  { label: t('selectOriginalInvoice'), value: null },
  ...props.invoices
    .filter((invoice) => invoice.type !== InvoiceType.REVERSAL)
    .map((invoice) => ({
      label: invoice.completeInvoiceNumber + (invoice.title ? ' - ' + invoice.title : ''),
      value: invoice.id,
    })),
]);

const paymentsOptions = computed(() => [
  { label: t('selectPayment'), value: null },
  ...selectablePayments.value.map((payment) => ({
    label: paymentToHuman(payment),
    value: payment.id,
  })),
]);

const paymentToHuman = (payment: Payment) => {
  return `${formatDateString(payment.createdDate, dateFormat.value)} - ${t(
    `paymentTypes.${payment.paymentType}`,
  )} ${payment.amount >= 0 ? t('payment') : t('repayment')}: ${formatCurrency(payment.amount)}`;
};

const addPaymentId = () => {
  pushPaymentId(selectedPaymentId.value);
  selectedPaymentId.value = '';
};

watch(
  () => formValues.type,
  (newType, oldType) => {
    if (newType === InvoiceType.DAMAGE) {
      replacePositions(positionFields.value.map((field) => ({ ...field.value, tax: 0 })));
    } else if (newType === InvoiceType.ADMINISTRATIVE_OFFENCE) {
      const locale = props.booking.customerData?.preferredLocal === Local.DE_DE ? 'de' : 'en';
      replacePositions([
        {
          title: t('administrativeOffencePositionTitle', 0, { locale }),
          amount: 1,
          unitPrice: 25,
          tax: 19,
        },
      ]);
    } else if (oldType === InvoiceType.DAMAGE) {
      replacePositions(positionFields.value.map((field) => ({ ...field.value, tax: 19 })));
    }
  },
);

const saveInvoice = handleSubmit(async (values) => {
  const newInvoice: CreateInvoiceDto = {
    ...values,
    positions: values.positions.map((position) => ({
      title: position.title,
      amount: position.amount ?? 0,
      unitPrice: position.unitPrice ?? 0,
      tax: (position.tax ?? 0) / 100,
    })),
    status: InvoiceStatus.OPEN,
  };
  emit('onSubmit', newInvoice, sendInvoiceOnSave.value);
});

function getEmptyFormValues(): InvoiceFormValues {
  return {
    title: '',
    bookingId: props.booking.id,
    invoiceDate: new Date().toISOString(),
    positions: [getEmptyPosition(InvoiceType.DAMAGE)],
    paymentIds: [],
    type: InvoiceType.DAMAGE,
    originalInvoiceId: undefined,
  };
}

function getEmptyPosition(type?: InvoiceType): PositionFormValues {
  return {
    title: '',
    amount: 1,
    unitPrice: undefined,
    tax: (type ?? formValues?.type) === InvoiceType.DAMAGE ? 0 : 19,
  };
}
</script>

<i18n lang="json">
{
  "en": {
    "invoiceType": "Invoice Type",
    "invoiceDate": "Invoice Date",
    "originalInvoice": "Original Invoice",
    "selectOriginalInvoice": "- Select Original Invoice -",
    "title": "Title",
    "amount": "Amount",
    "unitPrice": "Unit Price (Gross)",
    "tax": "Tax",
    "addPosition": "+ Add Position",
    "saveInvoice": "Save Invoice",
    "selectPayment": "- Select Payment -",
    "payment": "Payment",
    "repayment": "Repayment",
    "addPayment": "+ Add Payment",
    "sendInvoiceOnSave": "Automatically send Invoice to Customer after Saving",
    "compensationInvoice": "This is a damage compensation Invoice",
    "positions": "Positions",
    "invoiceTitle": "Invoice Title",
    "paymentsMade": "Payments made",
    "administrativeOffencePositionTitle": "Processing fee administrative offence booking file number "
  },
  "de": {
    "invoiceType": "Rechnungstyp",
    "invoiceDate": "Rechnungsdatum",
    "originalInvoice": "Originalrechnung",
    "selectOriginalInvoice": "- Originalrechnung auswählen -",
    "title": "Titel",
    "amount": "Anzahl",
    "unitPrice": "Preis (Brutto)",
    "tax": "Steuer",
    "addPosition": "+ Position hinzufügen",
    "saveInvoice": "Rechnung speichern",
    "selectPayment": "- Zahlung auswählen -",
    "payment": "Zahlung",
    "repayment": "Rückzahlung",
    "addPayment": "+ Zahlung hinzufügen",
    "sendInvoiceOnSave": "Rechnung beim Speichern automatisch an Kunden senden",
    "compensationInvoice": "Dies ist eine Schadensersatzrechnung",
    "positions": "Positionen",
    "invoiceTitle": "Rechnungstitel",
    "paymentsMade": "Getätigte Zahlungen",
    "administrativeOffencePositionTitle": "Bearbeitungsgebühr Ordnungswidrigkeit Buchung Aktenzeichen "
  }
}
</i18n>
