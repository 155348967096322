<template>
  <Input
    v-model="value"
    :hint="error"
    :is-error="!!error"
    :type="type"
    :max-fraction-digits="maxFractionDigits"
    :min="min"
  />
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { toRef, watch } from 'vue';
import Input from './Input.vue';

const props = defineProps<{
  name: string;
  initialValue?: string | number | null;
  type?: string;
  saveNumberAsString?: boolean;
  emptyValueToNull?: boolean;
  maxFractionDigits?: number;
  min?: number;
  isError?: boolean;
}>();

const name = toRef(props, 'name');

const { errorMessage: error, value } = useField(name, undefined, {
  initialValue: props.initialValue,
});

if (props.emptyValueToNull) {
  watch(
    value,
    (newValue) => (value.value = newValue === '' || newValue === undefined ? null : newValue),
  );
}
if (props.saveNumberAsString) {
  watch(value, (newValue) => {
    value.value = props.saveNumberAsString ? `${newValue}` : newValue;
  });
}
</script>
