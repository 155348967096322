<template>
  <Select
    v-model="stringInputValue"
    :options="options"
    :hint="errorMessage"
    @change="handleOnChange"
  />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useField } from 'vee-validate';
import { computed, ref, toRef, watch } from 'vue';
import Select from './Select.vue';

const props = withDefaults(
  defineProps<{
    name: string;
    initialValue?: boolean | null;
    emptyValueToNull?: boolean;
    nullTitle?: string;
  }>(),
  { initialValue: undefined, nullTitle: undefined },
);

defineEmits(['update:modelValue']);

const { t } = useI18n();

const name = toRef(props, 'name');

const options = computed(() => {
  const optionsArray = [
    {
      label: t('yes'),
      value: 'true',
    },
    {
      label: t('no'),
      value: 'false',
    },
  ];
  if (props.emptyValueToNull) {
    optionsArray.unshift({
      label: props.nullTitle ?? '',
      value: '',
    });
  }
  return optionsArray;
});

const { value: inputValue, errorMessage } = useField<boolean | null>(name, undefined, {
  ...(props.initialValue !== undefined ? { initialValue: props.initialValue } : {}),
});

const boolToString = () => {
  let string = inputValue.value ? 'true' : 'false';
  if (props.emptyValueToNull) {
    string = inputValue.value === null || inputValue.value === undefined ? '' : string;
  }
  return string;
};

const handleOnChange = () => {
  inputValue.value = stringInputValue.value === 'true' ? true : false;
  if (props.emptyValueToNull) {
    inputValue.value = stringInputValue.value === '' ? null : inputValue.value;
  }
};

const stringInputValue = ref<string | null>(boolToString());

watch(inputValue, () => {
  stringInputValue.value = boolToString();
});
</script>

<i18n lang="json">
{
  "en": {
    "yes": "Yes",
    "no": "No"
  },
  "de": {
    "yes": "Ja",
    "no": "Nein"
  }
}
</i18n>
