<template>
  <div>
    <p>
      <span class="font-medium">{{ t('rentStart') }}:</span>
      {{ formattedStartDate }} | {{ pickupLocationInfo }}
    </p>
    <p>
      <span class="font-medium">{{ t('rentEnd') }}:</span>
      {{ formattedEndDate }} | {{ dropoffLocationInfo }}
    </p>
    <p>
      <span class="font-medium">{{ t('rentDuration') }}:</span>
      {{ props.rentDuration }} {{ t('days') }}
    </p>
    <div v-if="totalBasePrice">
      <p class="font-medium">
        {{ t('price') }}:
        {{ formatCurrency(totalBasePrice) }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { BookingLocation } from '@/entities/bookings/booking.entity';
import { getDateTimeFormat } from '@/hooks/use-date-format';
import { useFormatedDateString } from '@/hooks/use-formated-date';
import { useLocationInfo } from '@/hooks/use-location-info';
import { useI18n } from 'vue-i18n';
import { formatCurrency } from '@/utils/format-numbers';
import { toRef } from 'vue';

const props = defineProps<{
  startDate: Date;
  endDate: Date;
  pickupLocation: BookingLocation;
  dropoffLocation: BookingLocation;
  rentDuration: number;
  totalBasePrice?: number;
}>();

const { t } = useI18n();

const formattedStartDate = useFormatedDateString(props.startDate, getDateTimeFormat());
const formattedEndDate = useFormatedDateString(props.endDate, getDateTimeFormat());
const pickupLocationInfo = useLocationInfo(toRef(props, 'pickupLocation'));
const dropoffLocationInfo = useLocationInfo(toRef(props, 'dropoffLocation'));
</script>

<i18n lang="json">
{
  "en": {
    "rentStart": "Rent Start",
    "rentEnd": "Rent End",
    "rentDuration": "Rent Duration",
    "days": "days",
    "price": "Price"
  },
  "de": {
    "rentStart": "Mietbeginn",
    "rentEnd": "Mietende",
    "rentDuration": "Mietdauer",
    "days": "Tage",
    "price": "Preis"
  }
}
</i18n>
