<template>
  <div class="relative">
    <div class="flex items-center">
      <h2 class="w-full">{{ t('additionalDrivers') }}</h2>
      <CVButton
        v-if="!preventAdding && !isCustomBookedExtra"
        type="button"
        class="min-w-max"
        :disabled="disabled"
        @click="push(new AdditionalDriver())"
      >
        {{ t('add') }}
      </CVButton>
    </div>
    <div v-if="fields.length" class="col-span-1 mt-4 md:col-span-2">
      <div class="grid grid-cols-1 gap-4">
        <div
          v-for="(field, idx) in fields"
          :key="field.key"
          class="relative grid grid-cols-3 gap-x-4 gap-y-2 border border-primary p-4"
        >
          <InputField
            :name="`additionalDrivers[${idx}].firstName`"
            :initial-value="field.value.firstName"
            :label="t('firstName')"
            :disabled="disabled"
          />
          <InputField
            :name="`additionalDrivers[${idx}].lastName`"
            :initial-value="field.value.lastName"
            :label="t('lastName')"
            :disabled="disabled"
          />
          <DatepickerField
            :label="t('dateOfBirth')"
            :initial-value="field.value.dateOfBirth"
            :name="`additionalDrivers[${idx}].dateOfBirth`"
            :disabled="disabled"
          />
          <InputField
            :name="`additionalDrivers[${idx}].licenseNumber`"
            :initial-value="field.value.licenseNumber"
            :label="t('licenseNumber')"
            :disabled="disabled"
          />
          <InputField
            :name="`additionalDrivers[${idx}].licenseAuthority`"
            :initial-value="field.value.licenseAuthority"
            :label="t('licenseAuthority')"
            :disabled="disabled"
          />
          <DatepickerField
            :name="`additionalDrivers[${idx}].licenseDate`"
            :initial-value="field.value.licenseDate"
            :label="t('licenseDate')"
            :disabled="disabled"
          />
          <div class="absolute right-0 top-0">
            <button
              v-if="!isCustomBookedExtra"
              class="h-7 w-7 text-xl font-bold text-red-500"
              type="button"
              :disabled="disabled"
              @click="remove(idx)"
            >
              x
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hasChanged && !fields.length" class="mt-4 border border-primary/40 p-2 pb-1 pt-1.5">
      <p class="text-error">
        {{ t('deleteAllAdditionalDrivers') }}
      </p>
    </div>
    <div v-if="hasChanged" class="mt-4 flex justify-end gap-4">
      <CVButton variant="success" :is-loading="isSaving" @click.prevent="onSubmit">{{
        t('save')
      }}</CVButton>
      <CVButton variant="warning" :disabled="isSaving" @click.prevent="onCancel">{{
        t('cancel')
      }}</CVButton>
    </div>
    <div
      v-if="recalculatingPrice"
      class="absolute -inset-3 flex items-center justify-center bg-black/20"
    >
      <Spinner />
    </div>
  </div>
</template>

<script lang="ts" setup>
import DatepickerField from '@/components/DatepickerField.vue';
import InputField from '@/components/InputField.vue';
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import { AdditionalDriver } from '@/entities/bookings/booking.entity';
import type { VehicleExtraOption } from '@/entities/vehicle-extra-option.entity';
import { VehicleExtraType } from '@/entities/vehicle-extra-type.enum';
import type { VehicleExtra } from '@/entities/vehicle-extra.entity';
import { useI18n } from 'vue-i18n';
import { cloneDeep } from 'lodash';
import { useFieldArray, useForm } from 'vee-validate';
import { computed, ref, toRef, watch } from 'vue';
import type {
  ChangedBookedExtraTypes,
  RecalculatePriceParams,
} from '@/hooks/use-recalculate-price';
import {
  additionalDriversMandatorySchema,
  additionalDriversSchema,
} from '@/validation/booking.schema';
import type { Pricing } from '@/entities/pricing.entity';
import { useFormHasChanged } from '@/hooks/use-form-has-changed';

const props = defineProps<{
  booking: Booking;
  additionalDrivers: AdditionalDriver[];
  priceCalculation: Pricing;
  extrasHaveChanged?: boolean;
  customExtrasHaveChanged?: boolean;
  disabled?: boolean;
  recalculatingPrice: boolean;
  isSaving: boolean;
  preventAdding?: boolean;
  validationIsMandatory?: boolean;
}>();
const emit = defineEmits<{
  (e: 'update:additionalDrivers', value: AdditionalDriver[]): void;
  (e: 'update:priceCalculation', value: Pricing): void;
  (e: 'recalculate-price', value: RecalculatePriceParams): void;
  (
    e: 'updateBooking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished?: () => void,
  ): void;
  (e: 'hasChanged', value: boolean): void;
}>();

const { t } = useI18n();

const booking = toRef(props, 'booking');
const extraChanges = ref<ChangedBookedExtraTypes>({});

const isCustomBookedExtra = computed(() =>
  booking.value.priceCalculation.customBookedExtras?.some(
    (customBookedExtra) =>
      customBookedExtra.customVehicleExtraType === VehicleExtraType.ADDITIONAL_DRIVER,
  ),
);

const {
  handleSubmit,
  values: formValues,
  validate,
  setFieldValue,
} = useForm({
  initialValues: {
    additionalDrivers: cloneDeep(booking.value.additionalDrivers),
  },
  validationSchema: props.validationIsMandatory
    ? additionalDriversMandatorySchema(
        computed(() => booking.value.car.vehicleType.minAge),
        computed(() => booking.value.startDate),
      )
    : additionalDriversSchema,
});

watch(
  () => formValues.additionalDrivers,
  () => {
    if (!formValues.additionalDrivers) {
      setFieldValue('additionalDrivers', []);
    }
  },
);

const {
  remove: removeFromFieldArray,
  push: pushToFieldArray,
  replace: replaceFieldArray,
  fields,
} = useFieldArray<AdditionalDriver>('additionalDrivers');

watch(
  () => props.booking.additionalDrivers,
  () => {
    replaceFieldArray(cloneDeep(props.booking.additionalDrivers));
    unchangedValues.value = cloneDeep(formValues);
  },
);

const push = (additionalDriver: AdditionalDriver, updateExtra = true) => {
  const extraOption = getExtraOption(fields.value.length + 1);
  if (!extraOption.chosenOption) return;
  if (updateExtra) {
    calculatePriceAndUpdateExtra(extraOption, additionalDriver);
  } else {
    pushToFieldArray(additionalDriver);
  }
};

const remove = (i: number, updateExtra = true) => {
  const extraOption = getExtraOption(fields.value.length - 1);
  if (!extraOption.chosenOption) return;
  if (updateExtra) {
    calculatePriceAndUpdateExtra(extraOption, undefined, i);
  } else {
    removeFromFieldArray(i);
  }
};

const calculatePriceAndUpdateExtra = (
  extraOption: {
    extra: VehicleExtra | undefined;
    chosenOption: VehicleExtraOption | undefined;
  },
  newDriver: AdditionalDriver | undefined = undefined,
  removeDriver: number | undefined = undefined,
) => {
  if (!extraOption.extra || !extraOption.chosenOption) return;
  const bookedExtra = props.booking.priceCalculation.bookedExtras.find(
    (bookedExtra) => bookedExtra.vehicleExtra.type === extraOption.extra!.type,
  );
  if (bookedExtra && bookedExtra.vehicleExtraOption.id !== extraOption.chosenOption.id) {
    extraChanges.value[extraOption.extra.type] = {
      vehicleExtraId: extraOption.extra.id,
      vehicleExtraOptionId: extraOption.chosenOption.id,
    };
  } else {
    extraChanges.value = {};
  }

  emit('recalculate-price', {
    changedBookedExtraTypes: extraChanges.value,
    nullExtrasOnConfirm: false,
    isConfirmedFunction: () => {
      if (newDriver) {
        pushToFieldArray(newDriver);
      }
      if (removeDriver !== undefined) {
        removeFromFieldArray(removeDriver);
      }
    },
  });
};

const getExtraOption = (additionalDriversAmount: number) => {
  const extra = booking.value.car.vehicleType?.availableExtras.find(
    (extra) => extra.type === VehicleExtraType.ADDITIONAL_DRIVER,
  );
  return {
    extra: extra,
    chosenOption: extra?.options.find((option) => Number(option.value) === additionalDriversAmount),
  };
};

const unchangedValues = ref(cloneDeep(formValues));

const onSubmit = handleSubmit((values) => {
  emit(
    'updateBooking',
    {
      id: props.booking.id,
      booking: { ...values, priceCalculationId: props.priceCalculation.id },
    },
    () => {
      unchangedValues.value = cloneDeep(formValues);
      extraChanges.value = {};
    },
  );
});

const hasFormChanged = useFormHasChanged(unchangedValues, formValues);
const hasChanged = computed(() => {
  return hasFormChanged?.value || Object.values(extraChanges.value).length > 0;
});
watch(hasChanged, () => emit('hasChanged', hasChanged.value));

const onCancel = () => {
  replaceFieldArray(cloneDeep(props.booking.additionalDrivers));
  emit('update:priceCalculation', props.booking.priceCalculation);
  emit('update:additionalDrivers', props.booking.additionalDrivers);
};

defineExpose({
  validate,
});
</script>

<i18n lang="json">
{
  "en": {
    "firstName": "First Name",
    "lastName": "Last Name",
    "dateOfBirth": "Date Of Birth",
    "licenseNumber": "License Number",
    "licenseAuthority": "License Authority",
    "licenseDate": "License Date",
    "additionalDrivers": "Additional Drivers",
    "add": "+ Add Additional Driver",
    "deleteAllAdditionalDrivers": "Save to delete all Additional Drivers"
  },
  "de": {
    "firstName": "Vorname",
    "lastName": "Nachname",
    "dateOfBirth": "Geburtsdatum",
    "driversLicense": "Führerschein",
    "licenseNumber": "Führerschein Nr.",
    "licenseAuthority": "Behörde",
    "licenseDate": "Ausstelldatum",
    "additionalDrivers": "Zusatzfahrer",
    "add": "+ Zusatzfahrer hinzufügen",
    "deleteAllAdditionalDrivers": "Speichern um alle Zusatzfahrer zu löschen"
  }
}
</i18n>
