<template>
  <HeaderBar />

  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
  </ButtonBackContainer>

  <section class="carvia-container">
    <div v-if="news" class="relative">
      <div class="mb-4 flex items-center justify-between">
        <h2 class="space-x-2">
          {{ news.title }}
        </h2>
        <div class="flex gap-1 text-xs font-normal lg:text-sm">
          <div v-if="authStore.user?.role === UserRole.ADMIN" class="flex gap-1">
            <router-link class="text-link" :to="{ name: 'editNews', params: { id: news.id } }">{{
              t('edit')
            }}</router-link>
            <span class="text-primary">•</span>
            <a href="#" class="text-link text-error" @click.prevent="deleteNews">{{
              t('delete')
            }}</a>
            <span class="text-primary">•</span>
            <span class="text-black/50">
              {{ t(`newsStatus.${news.status}`) }}
            </span>
            <span class="text-primary">•</span>
          </div>
          <p class="ml-[2px] text-black/50">
            {{ formattedDate }}
          </p>
        </div>
      </div>
      <p class="whitespace-pre-wrap">{{ news.content }}</p>
      <div v-if="isDeleting" class="absolute inset-0 flex items-center justify-center bg-black/20">
        <Spinner />
      </div>
    </div>
    <div v-else class="flex h-[200px] items-center justify-center">
      <Spinner />
    </div>
  </section>
</template>

<script lang="ts" setup>
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import { useI18n } from 'vue-i18n';
import { useUiStore } from '@/stores/ui.store';
import { useRoute, useRouter } from 'vue-router';
import { computed, watchEffect } from 'vue';
import { useDeleteNews, useNews } from '@/queries/use-news';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import { useFormatedIsoDateString } from '@/hooks/use-formated-date';
import Spinner from '@/components/icons/Spinner.vue';
import { Alert } from '@/utils/alert';
import { useAuthStore } from '@/stores/auth.store';
import { UserRole } from '@/entities/auth/user-role.enum';

const { t } = useI18n();
const authStore = useAuthStore();
const uiStore = useUiStore();
const route = useRoute();
const router = useRouter();
const newsId = route.params.id as string;
const { data: news } = useNews(newsId);

const { mutateAsync: deleteNewsAsync, isPending: isDeleting } = useDeleteNews();

const formattedDate = useFormatedIsoDateString(
  computed(() => news.value?.publishedDate ?? news.value?.createdDate),
);

const deleteNews = async () => {
  if (!news.value) return;
  const { isConfirmed } = await Alert.fire({
    text: t('reallyDeleteNewsText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('delete'),
    denyButtonText: t('cancel'),
  });
  if (!isConfirmed) return;
  await deleteNewsAsync(news.value.id);
  router.push({ name: 'newsList' });
};

watchEffect(() => {
  uiStore.setHeaderTitle(t('news'));
});
</script>

<i18n lang="json">
{
  "en": {
    "news": "News",
    "edit": "Edit",
    "delete": "Delete",
    "reallyDeleteNewsText": "Do you really want to delete this news?"
  },
  "de": {
    "news": "Neuigkeiten",
    "edit": "Bearbeiten",
    "delete": "Löschen",
    "reallyDeleteNewsText": "Möchtest du diese Neuigkeit wirklich löschen?"
  }
}
</i18n>
