<template>
  <label>
    <InputLabel :label="label" :tooltip="tooltip" :is-error="!!error" />
    <div class="relative">
      <div class="relative">
        <Input
          ref="input"
          v-model="searchString"
          list="location"
          :placeholder="placeholder"
          autocomplete="nope"
          @blur="onBlur"
          @keypress="onKeyPress"
          @keydown="onKeyPress"
        />
        <Spinner
          v-if="isFetchingResults && searchString"
          class="absolute right-1.5 top-1.5 h-5 w-5"
        />
      </div>
      <InputHint :hint="error" is-error />
      <div
        v-if="showDropdown"
        class="absolute top-8 z-10 w-full border border-t-0 border-primary/60 bg-white text-sm shadow-sm"
      >
        <div
          v-for="(option, i) in searchOptions"
          :key="i"
          class="cursor-pointer pl-2 pr-2 hover:bg-gray-100"
          :class="{ 'bg-gray-100': option.isSelected }"
          @mousedown="selectOption(i)"
        >
          <div class="border-primary/40 pb-1 pt-1" :class="{ 'border-b': !option.isLast }">
            <div
              v-for="(line, o) in option.content"
              :key="o"
              class="text-base"
              :class="{ 'text-xs': o > 0 }"
            >
              {{ line }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </label>
</template>

<script lang="ts" setup>
import Input from '@/components/Input.vue';
import { computed, onMounted, onUnmounted, ref, toRef, watch } from 'vue';
import { debounce } from 'lodash';
import InputHint from './InputHint.vue';
import InputLabel from './InputLabel.vue';

interface SearchResultOption {
  item: any;
  content: (string | null | undefined)[];
}

const props = defineProps<{
  searchResultOptions: SearchResultOption[];
  isFetchingResults: boolean;
  label?: string;
  tooltip?: string;
  placeholder?: string;
  error?: string;
}>();

const emit = defineEmits<{
  (e: 'fetch', value: string): void;
  (e: 'on-update-selected-item', value: any): void;
}>();

const input = ref();
const searchString = ref('');
const isFetchingResults = toRef(props, 'isFetchingResults');
const showDropdown = ref(false);
const selectedResult = ref(0);

let justSelectedItem = false;

const searchOptions = computed(() =>
  props.searchResultOptions.map((option, i, results) => ({
    ...option,
    isSelected: i === selectedResult.value,
    isLast: i === results.length - 1,
  })),
);

const fetchDebounced = debounce(() => {
  showDropdown.value = !!searchString.value;
  emit('fetch', searchString.value);
}, 500);
watch(searchString, () => {
  if (!justSelectedItem) {
    emit('on-update-selected-item', undefined);
    selectedResult.value = 0;
    fetchDebounced();
  }
  justSelectedItem = false;
  // if (!showDropdown.value) return;
});

// const onInput = () => {
//   showDropdown.value = !!searchString.value;
// };
const onBlur = () => (showDropdown.value = false);
const onKeyPress = (e: KeyboardEvent) => {
  switch (e.key) {
    case 'ArrowDown':
    case 'ArrowUp':
    case 'Enter':
      e.preventDefault();
  }
};

const onKeyDown = (e: KeyboardEvent) => {
  switch (e.key) {
    case 'ArrowDown':
      selectedResult.value = Math.min(
        selectedResult.value + 1,
        (searchOptions.value?.length ?? 1) - 1,
      );
      e.preventDefault();
      break;
    case 'ArrowUp':
      selectedResult.value = Math.max(selectedResult.value - 1, 0);
      e.preventDefault();
      break;
    case 'Enter':
      if (!searchOptions.value?.[selectedResult.value]) return;
      selectOption(selectedResult.value);
      e.preventDefault();
      break;
  }
};

const selectOption = (index: number) => {
  if (!(index in searchOptions.value)) return;
  selectedResult.value = index;
  searchString.value = searchOptions.value[index].content?.[0] ?? '';
  showDropdown.value = false;
  justSelectedItem = true;
  input.value?.blur?.();
  emit('on-update-selected-item', searchOptions.value[index].item);
};

watch(searchOptions, () => {
  selectedResult.value = Math.min(
    Math.max(selectedResult.value, 0),
    (searchOptions.value?.length ?? 1) - 1,
  );
});

onMounted(() => {
  document.addEventListener('keydown', onKeyDown);
});
onUnmounted(() => {
  document.removeEventListener('keydown', onKeyDown);
});
</script>
