import { createI18n } from 'vue-i18n';
import { useExtendTimePeriodOfPricing } from '@/queries/use-pricing';
import type { Pricing } from '@/entities/pricing.entity';
import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { i18n } from '@/i18n';
import { Alert } from '@/utils/alert';
import { getInclusiveKm } from '@/utils/get-inclusive-km';
import type { InclusiveKmPerDay } from '@/entities/inclusive-km-per-day.entity';
import type { Car } from '@/entities/cars/car.entity';
import { formatCurrency } from '@/utils/format-numbers';

export interface ExtendTimePeriodOfPricingParams {
  isConfirmedFunction?: (amount: number) => void;
  isDeniedFunction?: () => void;
}

export const useExtendTimePeriodOfPricingWithAlert = (
  formValues: {
    startDate: string;
    endDate: string;
    priceCalculation: Pricing;
    car: Car;
    inclusiveKm: InclusiveKmPerDay[];
  },
  oldPriceCalculation: MaybeRefOrGetter<Pricing>,
  totalRentalDays: MaybeRefOrGetter<number>,
  oldTotalRentalDays: MaybeRefOrGetter<number>,
) => {
  const { t } = createI18n({
    legacy: false,
    locale: toValue(i18n.global.locale),
    fallbackLocale: 'en',
    globalInjection: true,
    fallbackWarn: false,
    missingWarn: false,
    messages: localization,
  }).global;

  const { mutateAsync: extendTimePeriodOfPricing, isPending: extendingTimePeriodOfPricing } =
    useExtendTimePeriodOfPricing();

  const extendTimePeriodOfPricingWithAlert = async ({
    isConfirmedFunction,
    isDeniedFunction,
  }: ExtendTimePeriodOfPricingParams) => {
    const totalRentalDaysUnref = toValue(totalRentalDays);
    const oldTotalRentalDaysUnref = toValue(oldTotalRentalDays);
    const oldPriceCalculationUnref = toValue(oldPriceCalculation);

    const pricing = await extendTimePeriodOfPricing({
      oldPricingId: oldPriceCalculationUnref.id,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
    });

    const oldTotalPrice = formatCurrency(oldPriceCalculationUnref.totalPrice);
    const newTotalPrice = formatCurrency(pricing.totalPrice);
    const pricePerNewDay = formatCurrency(
      (pricing.totalPrice - oldPriceCalculationUnref.totalPrice) /
        (pricing.days - oldPriceCalculationUnref.days),
    );

    const newInclusiveKm = getInclusiveKm(
      formValues.car.vehicleType?.inclusiveKm,
      totalRentalDaysUnref,
    );
    const oldInclusiveKm = getInclusiveKm(formValues.inclusiveKm, oldTotalRentalDaysUnref);
    const { isConfirmed } = await Alert.fire({
      titleText: t('newPriceTitle'),
      html: `
        <p>
          ${t('newTotalPrice')}:
          ${oldTotalPrice}
          -> <strong>${newTotalPrice}</strong>
        </p>
        <p>
          ${t('pricePerAdditionalDay')}: ${pricePerNewDay}
        </p>
        <p>
          ${t('newInclusiveKm')}:
          ${oldInclusiveKm} km
          -> <strong>${newInclusiveKm} km</strong>
        </p>`,
      icon: 'info',
      showDenyButton: true,
      confirmButtonText: t('acceptNewPrice'),
      denyButtonText: t('keepOldPrice'),
    });

    if (!isConfirmed) {
      return isDeniedFunction?.();
    }

    formValues.inclusiveKm = [...(formValues.car.vehicleType?.inclusiveKm || [])];
    formValues.priceCalculation = pricing;
    isConfirmedFunction?.(pricing.totalPrice - oldPriceCalculationUnref.totalPrice);
  };

  return {
    extendTimePeriodOfPricingWithAlert,
    extendingTimePeriodOfPricing,
  };
};

const localization = {
  en: {
    acceptNewPrice: 'Accept new price',
    keepOldPrice: 'Continue with old price',
    newPriceTitle: 'Price calculated',
    newPriceDescription: 'New total price',
    newInclusiveKm: 'New Incl.-Kilometers',
    pricePerAdditionalDay: 'Price per additional day',
  },
  de: {
    acceptNewPrice: 'Neuen Preis akzeptieren',
    keepOldPrice: 'Weiter mit altem Preis',
    newPriceTitle: 'Preis berechnet',
    newTotalPrice: 'Neuer Gesamtpreis',
    newInclusiveKm: 'Neue Inkl.-Kilometer',
    pricePerAdditionalDay: 'Preis pro zusätzlichem Tag',
  },
};
