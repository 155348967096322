import { bookingsService } from '@/api/bookings.service';
import type { autoAdjustTransfersDto } from '@/entities/bookings/auto-adjust-transfers.dto';
import type { BookingType } from '@/entities/bookings/booking-type.enum';
import type {
  Booking,
  CreateBookingAsAgentDto,
  CreateBookingDto,
  FindAllBookingsQueryDto,
  UpdateBookingDto,
} from '@/entities/bookings/booking.entity';
import type { UpdateBookingQueryDto } from '@/entities/bookings/booking.entity';
import type { CreateBookingContractPdfDto } from '@/entities/bookings/create-booking-contract-pdf.dto';
import type { GetBookingContractTemplateDto } from '@/entities/bookings/get-booking-contract-template.dto';
import { useFilterForBookingTypes } from '@/hooks/use-filter-for-booking-types';
import {
  addItemToQueriesData,
  removeItemFromQueriesData,
  updateItemOnQueriesData,
} from '@/utils/update-query-data';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { type MaybeRefOrGetter, toRef, toValue } from '@vueuse/core';

const QUERY_KEY = 'bookings';

export const useBooking = (
  id: MaybeRefOrGetter<string>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, toRef(id)],
    queryFn: async () => bookingsService.getOne(toValue(id)),
    enabled: toRef(enabled),
  });
};

export const useBookingOfType = (
  id: MaybeRefOrGetter<string>,
  enabled: MaybeRefOrGetter<boolean> = true,
  restrictBookingTypes: BookingType | BookingType[],
  referToName?: string,
) => {
  const { data, ...rest } = useBooking(id, enabled);
  const booking = useFilterForBookingTypes(data, restrictBookingTypes, referToName);
  return { data: booking, ...rest };
};

export const useBookingInvoicePositions = (bookingId: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, bookingId, 'invoicePositions'],
    queryFn: () => bookingsService.getAllInvoicePositions(bookingId),
  });
};

export const useBookingsWithQuery = (
  query: MaybeRefOrGetter<FindAllBookingsQueryDto>,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY, toRef(query)],
    queryFn: ({ pageParam = 1 }) =>
      bookingsService.getAllWithQuery({
        ...toValue(query),
        page: pageParam,
      }),
    enabled: toRef(enabled),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.meta.nextPage,
  });
};

export const useCountBookingsOfUser = (userId: MaybeRefOrGetter<string>) => {
  return useQuery({
    queryKey: [QUERY_KEY, 'count-bookings-of-user', toRef(userId)],
    queryFn: () => bookingsService.countBookingsOfUser(toValue(userId)),
  });
};

export const useUpdateBooking = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: ({
      id,
      booking,
      query,
    }: {
      id: string;
      booking: UpdateBookingDto;
      query?: UpdateBookingQueryDto;
    }) => bookingsService.update(id, booking, query),
    onMutate: () => ({
      successMessage: 'Booking updated',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      updateItemOnQueriesData<Booking>([QUERY_KEY], result, queryClient);
    },
  });
};

export const useCreateBooking = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (booking: CreateBookingDto) => bookingsService.create(booking),
    onMutate: () => ({
      successMessage: 'Booking created successfully',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      addItemToQueriesData<Booking>([QUERY_KEY], result, queryClient);
    },
  });
};

export const useCreateBookingAsAgent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (booking: CreateBookingAsAgentDto) => bookingsService.createAsAgent(booking),
    onMutate: () => ({
      successMessage: 'Booking created successfully',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      addItemToQueriesData<Booking>([QUERY_KEY], result, queryClient);
    },
  });
};

export const useDeleteBooking = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (removeId: string) => bookingsService.remove(removeId),
    onMutate: () => ({
      successMessage: 'Booking deleted successfully',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      removeItemFromQueriesData<Booking>([QUERY_KEY], variables, queryClient);
    },
  });
};

export const useBookingContractTemplate = (
  query: MaybeRefOrGetter<GetBookingContractTemplateDto>,
) => {
  return useQuery({
    queryKey: ['contractTemplate', toRef(query)],
    queryFn: () => bookingsService.getBookingContractTemplate(toValue(query)),
  });
};

export const useCreateBookingContract = () => {
  return useMutation({
    mutationKey: ['contract'],
    mutationFn: ({ bookingId, data }: { bookingId: string; data: CreateBookingContractPdfDto }) =>
      bookingsService.createContract(bookingId, data),
    onMutate: () => ({ successMessage: 'Contract created successfully' }),
  });
};

export const useResendBookingContract = () => {
  return useMutation({
    mutationKey: ['resend-contract'],
    mutationFn: (bookingId: string) => bookingsService.resendContract(bookingId),
    onMutate: () => ({ successMessage: 'Contract sent successfully' }),
  });
};

export const useResendBookingConfirmation = () => {
  return useMutation({
    mutationKey: ['resend-confirmation-email'],
    mutationFn: (bookingId: string) => bookingsService.resendBookingConfirmation(bookingId),
    onMutate: () => ({ successMessage: 'Confirmation sent successfully' }),
  });
};

export const useRefreshBookingConfirmation = () => {
  return useMutation({
    mutationKey: ['refresh-booking-confirmation'],
    mutationFn: (bookingId: string) => bookingsService.refreshBookingConfirmation(bookingId),
  });
};

export const useAutoAdjustTransfers = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['auto-adjust-transfers'],
    mutationFn: ({ bookingId, data }: { bookingId: string; data: autoAdjustTransfersDto }) =>
      bookingsService.autoAdjustTransfers(bookingId, data),
    onMutate: () => ({
      successMessage: 'Transfers adjusted successfully',
      invalidateQueryKeys: null,
    }),
    onSuccess: (result, variables, context) => {
      queryClient.getMutationDefaults([])?.onSuccess?.(result, variables, context);
      updateItemOnQueriesData<Booking>([QUERY_KEY], result, queryClient);
    },
  });
};
