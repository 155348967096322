<template>
  <HeaderBar />

  <ButtonBackContainer>
    <ButtonBack>{{ t('back') }}</ButtonBack>
    <AuditLogButton entity-type="Invoice" :entity-id="invoiceId" />
  </ButtonBackContainer>

  <section class="carvia-container flex flex-col justify-center">
    <InvoiceForm
      v-if="invoice"
      :invoice="invoice"
      :is-saving="isUpdating"
      :is-fetching="isFetching"
      @on-submit="onSubmit"
      @on-cancel="onCancel"
      @refetch="refetch"
    />
    <div v-else class="flex justify-center"><Spinner /></div>
  </section>
</template>

<script lang="ts" setup>
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import { InvoiceStatus } from '@/entities/invoices/invoice-status.enum';
import type { UpdateInvoiceDto } from '@/entities/invoices/invoice.entity';
import { formatIsoDateString } from '@/hooks/use-formated-date';
import { useI18n } from 'vue-i18n';
import { useInvoice, useUpdateInvoice } from '@/queries/use-invoices';
import router from '@/router';
import { useUiStore } from '@/stores/ui.store';
import { DateTime } from 'luxon';
import { watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import InvoiceForm from './InvoiceForm.vue';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import AuditLogButton from '@/components/audit-log/AuditLogButton.vue';

const { t } = useI18n();
const uiStore = useUiStore();
const route = useRoute();
const invoiceId = route.params.id as string;

const { data: invoice, refetch, isFetching } = useInvoice(invoiceId);
const { mutateAsync: updateInvoice, isPending: isUpdating } = useUpdateInvoice();

watchEffect(() => {
  if (invoice.value) {
    const { completeInvoiceNumber, invoiceDate } = invoice.value;
    uiStore.setHeaderTitle(
      t('invoice'),
      completeInvoiceNumber,
      formatIsoDateString(invoiceDate, DateTime.DATE_MED),
    );
  } else {
    uiStore.setHeaderTitle(t('invoice'));
  }
});

const onSubmit = (invoice: UpdateInvoiceDto) => {
  updateInvoice({ id: invoiceId, invoice });
};

const onCancel = async () => {
  await updateInvoice({
    id: invoiceId,
    invoice: { status: InvoiceStatus.CANCELED },
  });
  router.push({ name: 'invoiceList' });
};
</script>

<i18n lang="json">
{
  "en": {
    "invoice": "Invoice"
  },
  "de": {
    "invoice": "Rechnung"
  }
}
</i18n>
