<template>
  <HeaderBar />

  <section class="carvia-container">
    <div class="grid grid-cols-4 gap-4">
      <Input v-model="search" :placeholder="t('search')" class="w-full" />

      <Input v-model="bookingNumber" :placeholder="t('bookingNumber')" class="w-full" />

      <Datepicker
        v-if="activeTab === Tab.ACTIVE"
        :model-value="filterDate"
        :format="dateFormat"
        week-numbers
        :month-change-on-scroll="false"
        :clearable="false"
        class="w-full"
        @update:model-value="setfilterDate"
      />
      <div v-else></div>

      <StationSwitcher
        v-model="currentStationId"
        has-null-option
        :null-option-title="t('allStations')"
      />
    </div>

    <Tabs v-model="activeTab" :tabs="tabs" class="mb-4" />

    <BookingsList :bookings="bookings" :show-weeks="activeTab === Tab.WITH_TRANSFER" />

    <EntityListExceptions
      :status="status"
      :error="error"
      :items-count="bookings.length"
      :no-items-text="t('noBookingFound')"
    />

    <div v-if="hasNextPage" class="flex justify-center pt-8">
      <CVButton outline :is-loading="isFetchingNextPage" @click="() => fetchNextPage()">
        {{ t('loadMore') }}
      </CVButton>
    </div>
  </section>
</template>

<script lang="ts" setup>
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import Input from '@/components/Input.vue';
import Tabs, { type TabItem } from '@/components/Tabs.vue';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { useI18n } from 'vue-i18n';
import { useBookingsWithQuery } from '@/queries/use-bookings';
import { useUiStore } from '@/stores/ui.store';
import { ref, computed, watch, watchEffect } from 'vue';
import StationSwitcher from '@/views/settings/components/StationSwitcher.vue';
import { useDateFormat } from '@/hooks/use-date-format';
import { debounce } from 'lodash';
import EntityListExceptions from '@/components/EntityListExceptions.vue';
import BookingsList from './BookingsList.vue';
import { useGetBookingsQuery } from './use-get-bookings-query';
import { Tab } from './tab.enum';

const { t } = useI18n();
const currentStationId = ref<string>();
const uiStore = useUiStore();

watchEffect(() => {
  uiStore.setHeaderTitle(t('bookings'));
});

const dateFormat = useDateFormat();
const filterDate = ref(new Date());

const setfilterDate = (value: Date) => {
  filterDate.value = value;
};

const search = ref('');
const searchQuery = ref('');
const bookingNumber = ref<string | undefined>(undefined);
const bookingNumberQuery = ref<number | undefined>(undefined);
const activeTab = ref<Tab>(Tab.ALL);

const getBookingsQuery = useGetBookingsQuery(
  activeTab,
  searchQuery,
  currentStationId,
  filterDate,
  bookingNumberQuery,
);

watch(activeTab, () => {
  if (activeTab.value !== Tab.ALL) {
    bookingNumber.value = undefined;
  }
});

const updateSearchQuery = debounce(() => {
  searchQuery.value = search.value;
}, 500);

watch(search, () => {
  updateSearchQuery();
  if (search.value) {
    bookingNumber.value = undefined;
  }
});

const updateBookingNumber = debounce(() => {
  bookingNumberQuery.value = !isNaN(parseInt(bookingNumber.value ?? ''))
    ? parseInt(bookingNumber.value ?? '')
    : undefined;
}, 500);

watch(bookingNumber, () => {
  updateBookingNumber();
  if (bookingNumber.value) {
    activeTab.value = Tab.ALL;
    search.value = '';
  }
});

const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status, error } =
  useBookingsWithQuery(getBookingsQuery);

const bookings = useFlattenPaginatedData(data);

const tabs = computed<TabItem[]>(() => [
  {
    title: t('all'),
    id: Tab.ALL,
  },
  {
    title: t('floating'),
    id: Tab.FLOATING,
  },
  {
    title: t('reserved'),
    id: Tab.RESERVED,
  },
  {
    title: t('active'),
    id: Tab.ACTIVE,
  },
  {
    title: t('withTransfer'),
    id: Tab.WITH_TRANSFER,
  },
  {
    title: t('notPaid'),
    id: Tab.NOT_PAID,
  },
]);
</script>

<i18n lang="json">
{
  "en": {
    "bookings": "Bookings",
    "search": "Search by name/car",
    "allStations": "All Stations",
    "notPaid": "Not paid online bookings",
    "reserved": "Reserved",
    "active": "Active",
    "floating": "Floating",
    "withTransfer": "With transfer",
    "all": "All",
    "noBookingFound": "No Bookings found",
    "bookingNumber": "Booking Number"
  },
  "de": {
    "bookings": "Buchungen",
    "search": "Nach Name/Auto suchen",
    "allStations": "Alle Stationen",
    "notPaid": "Unbezahlte Onlinebuchungen",
    "reserved": "Reserviert",
    "active": "Aktiv",
    "floating": "Schwebend",
    "withTransfer": "Mit Transfer",
    "all": "Alle",
    "noBookingFound": "Es wurden keine Buchungen gefunden",
    "bookingNumber": "Buchungsnummer"
  }
}
</i18n>
