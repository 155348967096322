<template>
  <div class="border-t border-primary/40 pt-4">
    <div v-if="!editDiscount && !deletedDiscount" class="flex items-center gap-4">
      <div v-if="hasActiveDiscount">
        <p class="text-success">
          {{
            t('activeDiscountText', {
              percentage: initialDiscountPercentage,
              untilDate: initialFormattedDiscountUntil,
            })
          }}
        </p>
      </div>
      <CVButton @click.prevent="editDiscount = true">{{
        t(!hasActiveDiscount ? 'activateDiscount' : 'replaceDiscount')
      }}</CVButton>
      <CVButton v-if="hasActiveDiscount" variant="error" @click.prevent="deleteDiscount">{{
        t('deleteDiscount')
      }}</CVButton>
    </div>
    <div v-if="editDiscount" class="grid grid-cols-3 items-end gap-4">
      <Select
        v-model="discountUntilFromNow"
        :options="discountUntilFromNowOptions"
        :label="t('discountUntilFromNow')"
      />

      <Select
        v-model="discountPercentage"
        :options="discountPercentageOptions"
        :label="t('discountPercentage')"
      />

      <CVButton variant="error" outline @click.prevent="cancelEditDiscount">{{
        t('cancel')
      }}</CVButton>
    </div>
    <div v-if="deletedDiscount" class="flex items-center gap-4">
      <p class="text-error">{{ t('discountWillBeDeletedOnSave') }}</p>
      <CVButton variant="error" outline @click.prevent="cancelEditDiscount">{{
        t('cancel')
      }}</CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Select from '@/components/Select.vue';
import type { VehicleType } from '@/entities/vehicle-type.entity';
import { getDateFormat } from '@/hooks/use-date-format';
import { useFormatedDateString } from '@/hooks/use-formated-date';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';
import { useField } from 'vee-validate';
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  initialVehicleType?: VehicleType;
}>();

const { t } = useI18n();

const discountUntilFromNowOptions = Array.from({ length: 6 }, (_, i) => i + 1).map((i) => ({
  label: t(i === 1 ? 'month' : 'months', { count: i }),
  value: String(i),
}));

const discountPercentageOptions = [
  {
    label: '10%',
    value: '0.1',
  },
  {
    label: '20%',
    value: '0.2',
  },
  {
    label: '30%',
    value: '0.3',
  },
];

const editDiscount = ref(false);
const deletedDiscount = ref(false);
const discountUntilFromNow = ref<string | null>(discountUntilFromNowOptions[0].value);
const discountPercentage = ref<string | null>(discountPercentageOptions[0].value);
const hasActiveDiscount = computed(
  () =>
    !!(
      props.initialVehicleType?.discountFrom &&
      props.initialVehicleType?.discountUntil &&
      props.initialVehicleType?.discountFactor &&
      DateTime.now() >= DateTime.fromISO(props.initialVehicleType?.discountFrom) &&
      DateTime.now() <= DateTime.fromISO(props.initialVehicleType?.discountUntil)
    ),
);
const initialFormattedDiscountUntil = useFormatedDateString(
  computed(() => props.initialVehicleType?.discountUntil),
  getDateFormat(),
);
const initialDiscountPercentage = computed(() =>
  props.initialVehicleType?.discountFactor
    ? String(Math.round(props.initialVehicleType?.discountFactor * 100))
    : null,
);

const { setValue: setDiscountFrom } = useField('discountFrom');
const { setValue: setDiscountUntil } = useField('discountUntil');
const { setValue: setDiscountFactor } = useField('discountFactor');

watch(
  [discountUntilFromNow, discountPercentage, editDiscount],
  () => {
    if (!editDiscount.value) return;
    const discountFrom = DateTime.now().startOf('day');
    const discountUntilIso = discountUntilFromNow.value
      ? discountFrom
          .plus({
            months: Number(discountUntilFromNow.value),
          })
          .endOf('day')
          .toISO()
      : null;
    setDiscountFrom(discountFrom.toISO());
    setDiscountUntil(discountUntilIso);
    setDiscountFactor(discountPercentage.value ? Number(discountPercentage.value) : null);
  },
  { immediate: true },
);

watch(
  [
    computed(() => props.initialVehicleType?.discountFrom),
    computed(() => props.initialVehicleType?.discountUntil),
    computed(() => props.initialVehicleType?.discountFactor),
  ],
  () => {
    editDiscount.value = false;
    deletedDiscount.value = false;
  },
);

const deleteDiscount = () => {
  deletedDiscount.value = true;
  setDiscountFrom(null);
  setDiscountUntil(null);
  setDiscountFactor(null);
};

const cancelEditDiscount = () => {
  setDiscountFrom(props.initialVehicleType?.discountFrom ?? null);
  setDiscountUntil(props.initialVehicleType?.discountUntil ?? null);
  setDiscountFactor(props.initialVehicleType?.discountFactor ?? null);
  editDiscount.value = false;
  deletedDiscount.value = false;
};
</script>

<i18n lang="json">
{
  "en": {
    "activateDiscount": "Activate discount",
    "replaceDiscount": "Replace discount",
    "deleteDiscount": "Delete discount",
    "month": "{count} month",
    "months": "{count} months",
    "activeDiscountText": "Active discount of {percentage}% until {untilDate}",
    "discountWillBeDeletedOnSave": "Discount will be deleted on save",
    "discountUntilFromNow": "Discount duration from today",
    "discountPercentage": "Discount percentage"
  },
  "de": {
    "activateDiscount": "Streichpreis aktivieren",
    "replaceDiscount": "Streichpreis ersetzen",
    "deleteDiscount": "Streichpreis löschen",
    "month": "{count} Monat",
    "months": "{count} Monate",
    "activeDiscountText": "Aktiver Streichpreis mit {percentage}% Rabatt bis {untilDate}",
    "discountWillBeDeletedOnSave": "Streichpreis wird beim Speichern gelöscht",
    "discountUntilFromNow": "Streichpreis Dauer ab heute",
    "discountPercentage": "Streichpreis Rabatt"
  }
}
</i18n>
