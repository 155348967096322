import type {
  AdditionalCosts,
  CreateAdditionalCostsDto,
  CreatePricingDto,
  CreatePricingWithExtendedTimePeriodDto,
  Pricing,
} from '@/entities/pricing.entity';
import { Service } from './http-service';

class PricingService extends Service {
  constructor() {
    super('/pricing');
  }

  async requestPricing(params: CreatePricingDto) {
    const res = await this.httpClient.post<Pricing>(`${this.endpoint}`, params);
    return res.data;
  }

  async extendTimePeriodOfPricing(params: CreatePricingWithExtendedTimePeriodDto) {
    const res = await this.httpClient.post<Pricing>(`${this.endpoint}/extend-time-period`, params);
    return res.data;
  }

  async requestAdditionalCosts(params: CreateAdditionalCostsDto) {
    const res = await this.httpClient.post<AdditionalCosts>(
      `${this.endpoint}/additional-costs`,
      params,
    );
    return res.data;
  }
}

export const pricingService = new PricingService();
