import type { InclusiveKmPerDay } from '@/entities/inclusive-km-per-day.entity';
import _ from 'lodash';

export const getInclusiveKm = (inclusiveKm?: InclusiveKmPerDay[], rentDuration = 0) => {
  const kmPerDays = Array.from({ length: rentDuration }, (_, i) => i + 1).map((day) => {
    const kmPerDay = _.maxBy(
      inclusiveKm?.filter((km) => km.fromDay <= day),
      'fromDay',
    )?.kmInclusivePerDay;

    return kmPerDay;
  });
  return _.sum(kmPerDays);
};
