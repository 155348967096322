import type { VehicleExtraOptionValueType } from './vehicle-extra-option-value-type.enum';
import type { VehicleExtra } from './vehicle-extra.entity';

export interface VehicleExtraOption {
  id: string;
  name: string;
  basePriceFactor: number | null;
  price: number | null;
  pricePerDay: {
    fromDay: number;
    pricePerDay: number;
  }[];
  pricingType: VehicleExtraOptionPricingType;
  vehicleExtra: VehicleExtra;
  value: string;
  valueType: VehicleExtraOptionValueType;
}

export enum VehicleExtraOptionPricingType {
  FIXED_PER_DAY = 'FIXED_PER_DAY',
  FIXED = 'FIXED',
}
