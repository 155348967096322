<template>
  <button
    class="flex gap-4 rounded-2xl border border-transparent bg-primary/10 p-4 text-left transition duration-300 hover:scale-[1.03] hover:border-primary hover:bg-white"
    :class="{ 'opacity-50': car.isBlocked }"
  >
    <div class="basis-[55%]">
      <p class="text-lg font-medium leading-tight">
        {{ car.vehicleType.make.name }} {{ car.vehicleType.model }}
      </p>
      <img :src="imageUrl" />
    </div>
    <div class="basis-[45%]">
      <p class="mb-3 mt-1 font-medium">
        {{ t('inclKm', { inclusiveKm }) }}
      </p>
      <p>{{ power }} {{ t('hp') }}</p>
      <p class="mb-3">{{ seats }}-{{ t('seater') }}</p>
      <p class="text-lg font-medium text-primary">
        <span v-currency="pricePerDay" /> / {{ t('day') }}
      </p>
      <p class="text-sm text-black/30"><span v-currency="car.totalPrice" /> {{ t('total') }}</p>
    </div>
  </button>
</template>

<script lang="ts" setup>
import type { Car } from '@/entities/cars/car.entity';
import { computed } from 'vue';
import { getInclusiveKm } from '@/utils/get-inclusive-km';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  car: Car;
  rentDuration: number;
}>();

const { t } = useI18n();

const imageUrl = computed(() => props.car.imageUrl ?? props.car.vehicleType.imageUrl);

const inclusiveKm = computed(() =>
  getInclusiveKm(props.car.vehicleType.inclusiveKm, props.rentDuration),
);

const power = computed(() => props.car.power ?? props.car.vehicleType.power);
const seats = computed(() => props.car.seats ?? props.car.vehicleType.seats);
const pricePerDay = computed(() => (props.car.totalPrice ?? 0) / props.rentDuration);
</script>

<i18n lang="json">
{
  "en": {
    "inclKm": "Incl. {inclusiveKm} km",
    "hp": "hp",
    "seater": "seater",
    "day": "day",
    "total": "total"
  },
  "de": {
    "inclKm": "Inkl. {inclusiveKm} km",
    "hp": "PS",
    "seater": "Sitzer",
    "day": "Tag",
    "total": "Gesamt"
  }
}
</i18n>
