<template>
  <div>
    <h2>{{ t('stationAffiliation') }}</h2>

    <div v-if="carStations.length" class="mb-4 mt-4 border border-primary pb-1 pl-4 pr-4 pt-1">
      <div
        v-for="(station, i) in carStations"
        :key="i"
        class="relative border-primary pb-3 pr-8 pt-3"
        :class="{
          'border-b': i < carStations.length - 1,
        }"
      >
        <div
          class="grid grid-cols-2 gap-4"
          :class="{
            'opacity-30': getCurrentStationOfCar(car).id !== station.station.id,
          }"
        >
          <LabeledText :label="t('station')">{{
            getLocalizedField(station.station.info)
          }}</LabeledText>
          <LabeledText :label="t('fromDate')">{{
            formatDateString(station.fromDate, getDateFormat(), '-')
          }}</LabeledText>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import LabeledText from '@/components/LabeledText.vue';
import { useI18n } from 'vue-i18n';
import { formatDateString } from '@/hooks/use-formated-date';
import type { Car, CarStations } from '@/entities/cars/car.entity';
import { getDateFormat } from '@/hooks/use-date-format';
import { computed } from 'vue';
import { DateTime } from 'luxon';
import { getCurrentStationOfCar } from '@/hooks/use-current-station-of-car';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

const props = defineProps<{
  car: Car;
  carStations: CarStations[];
}>();

const carStations = computed(() =>
  [...props.carStations].sort((a, b) => {
    if (!a.fromDate) return -1;
    if (!b.fromDate) return 1;
    return DateTime.fromISO(a.fromDate) > DateTime.fromISO(b.fromDate) ? 1 : -1;
  }),
);

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();
</script>

<i18n lang="json">
{
  "en": {
    "stationAffiliation": "Station affiliation",
    "fromDate": "From date"
  },
  "de": {
    "stationAffiliation": "Stationszugehörigkeit",
    "fromDate": "Ab Datum"
  }
}
</i18n>
