<template>
  <form @submit.prevent="onSubmit">
    <TransferBasicData :booking="booking" :transfer="transfer" />

    <Divider class="col-span-3" />

    <TransferAddresses
      :pickup-transfer-location="pickupTransferLocation"
      :dropoff-transfer-location="dropoffTransferLocation"
    />

    <Divider class="col-span-3" />

    <TransferTimes
      :transfer-type="transferType"
      :start-date="formValues.startDate"
      :end-date="formValues.endDate"
      :booking="booking"
    />

    <Divider class="col-span-3" />

    <VehicleAndTimes :booking="booking" />

    <Divider class="col-span-3" />

    <div class="flex items-center justify-end">
      <CVButton :is-loading="props.isSaving" type="submit">
        {{ t('save') }}
      </CVButton>
    </div>
  </form>
</template>

<script lang="ts" setup>
import VehicleAndTimes from '@/components/booking-forms/VehicleAndTimes.vue';
import Divider from '@/components/Divider.vue';
import type { BookingType } from '@/entities/bookings/booking-type.enum';
import type { Booking, BookingLocation } from '@/entities/bookings/booking.entity';
import type { UpdateInternalBookingDto } from '@/entities/bookings/internal-booking.entity';
import { locationToCoordinates } from '@/hooks/use-location-to-coordinates';
import { useI18n } from 'vue-i18n';
import { transferEditSchema } from '@/validation/transfer.schema';
import { useForm } from 'vee-validate';
import { reactive } from 'vue';
import TransferAddresses from './components/TransferAddresses.vue';
import TransferBasicData from './components/TransferBasicData.vue';
import TransferTimes from './components/TransferTimes.vue';

const props = defineProps<{
  transfer?: Booking;
  pickupTransferLocation?: BookingLocation | null;
  dropoffTransferLocation?: BookingLocation | null;
  booking: Booking;
  transferNew: boolean;
  transferType: BookingType;
  isSaving: boolean;
}>();
const emit = defineEmits<{
  (e: 'onSubmit', value: UpdateInternalBookingDto): void;
}>();

const { t } = useI18n();

const formValues: UpdateInternalBookingDto = reactive({
  ...props.transfer,
  pickupLocation: locationToCoordinates(props.transfer?.pickupLocation),
  dropoffLocation: locationToCoordinates(props.transfer?.dropoffLocation),
  userId: props.transfer?.customer?.id,
});

const { handleSubmit } = useForm({
  initialValues: formValues,
  validationSchema: transferEditSchema,
});

const onSubmit = handleSubmit((values) => {
  emit('onSubmit', {
    ...values,
  });
});
</script>
