<template>
  <div>
    <div class="flex justify-between">
      <h2>{{ t('stationAffiliation') }}</h2>
      <CVButton @click.prevent="push(getEmptyCarStation())">
        {{ t('addStation') }}
      </CVButton>
    </div>

    <div
      v-if="fields.length"
      class="mb-4 mt-4 border border-primary pb-1 pl-4 pr-4 pt-1"
      :class="{
        'border-error': error,
      }"
    >
      <div
        v-for="(station, i) in fields"
        :key="i"
        class="relative border-primary pb-3 pr-8 pt-3"
        :class="{
          'border-b': i < fields.length - 1,
        }"
      >
        <div class="grid grid-cols-2 gap-4">
          <SelectField
            :disabled="disabledFieldsNr > i"
            :name="`stations[${i}].id`"
            :label="t('station')"
            :options="omitPreviousStationFromOptions(i)"
            empty-value-to-null
          />
          <DatepickerField
            v-if="i > 0"
            :disabled="disabledFieldsNr > i"
            :name="`stations[${i}].fromDate`"
            :label="t('fromDate')"
          />
          <LabeledText v-else :label="t('fromDate')">-</LabeledText>
        </div>
        <button
          v-if="disabledFieldsNr <= i"
          class="absolute bottom-3 right-0 cursor-pointer p-2 text-lg leading-3 text-red-600"
          @click.prevent="removeField(i)"
        >
          ×
        </button>
      </div>
    </div>
    <div v-if="error">
      <p class="text-error">{{ error }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import DatepickerField from '@/components/DatepickerField.vue';
import LabeledText from '@/components/LabeledText.vue';
import SelectField from '@/components/SelectField.vue';
import type { Station } from '@/entities/station/station.entity';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';
import { useFieldArray } from 'vee-validate';
import { computed } from 'vue';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

const props = defineProps<{
  error?: string;
  stations: Station[];
  carNew?: boolean;
}>();

interface CarStationFormValues {
  id: string | null;
  fromDate: string | null;
}

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();

const { push, replace, fields } = useFieldArray<CarStationFormValues>('stations');

const removeField = (index: number) => {
  replace(fields.value.filter((_, i) => i !== index).map((field) => field.value));
  if (index === 0 && fields.value.length) {
    replace([
      ...fields.value.map((field, i) =>
        i > 0
          ? field.value
          : {
              ...field.value,
              fromDate: null,
            },
      ),
    ]);
  }
};

const disabledFieldsNr = computed(() => {
  if (props.carNew) {
    return 0;
  }
  return fields.value.filter(
    (field) => !field.value.fromDate || DateTime.fromISO(field.value.fromDate) < DateTime.now(),
  ).length;
});

const stationOptions = computed(() => {
  return [
    {
      label: t('selectStation'),
      value: null,
    },
    ...props.stations.map((station) => ({
      label: getLocalizedField(station.info),
      value: station.id,
    })),
  ];
});

const omitPreviousStationFromOptions = (index: number) => {
  return stationOptions.value.filter(
    (option) => fields.value[index - 1]?.value.id !== option.value || !option.value,
  );
};

const getEmptyCarStation = (): CarStationFormValues => {
  return {
    id: null,
    fromDate: getNextFromDate(),
  };
};

const getNextFromDate = () => {
  const lastStation = fields.value.length ? fields.value[fields.value.length - 1].value : null;
  if (!lastStation) {
    return null;
  }
  const nowFromDate = DateTime.now().plus({ days: 1 });
  const accFromDate = lastStation.fromDate
    ? DateTime.fromJSDate(new Date(lastStation.fromDate)).plus({
        days: 1,
      })
    : nowFromDate;
  return accFromDate < nowFromDate ? nowFromDate.toISODate() : accFromDate.toISODate();
};
</script>

<i18n lang="json">
{
  "en": {
    "stationAffiliation": "Station affiliation",
    "selectStation": "- Select station -",
    "addStation": "+ Add station",
    "fromDate": "From date"
  },
  "de": {
    "stationAffiliation": "Stationszugehörigkeit",
    "selectStation": "- Station auswählen -",
    "addStation": "+ Station hinzufügen",
    "fromDate": "Ab Datum"
  }
}
</i18n>
