<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center justify-start gap-4">
      <SearchCustomerInput @on-update-user="selectedCustomerForSwap = $event" />
      <CVButton
        variant="success"
        :disabled="!selectedCustomerForSwap"
        :is-loading="isSaving"
        @click.prevent="swapToSelectedCustomer"
        >{{ t('swap') }}</CVButton
      >
      <span>/</span>
      <CVButton variant="success" :is-loading="isSaving" @click.prevent="createNewCustomer">{{
        t('createNewCustomer')
      }}</CVButton>
    </div>
    <CVButton variant="warning" @click.prevent="$emit('hideSwapCustomer')">{{
      t('cancel')
    }}</CVButton>
  </div>

  <CreateCustomerModal
    v-model:show-modal="showCreateCustomerModal"
    :booking-id="bookingId"
    :schema="schema"
    :is-saving="isSaving"
    @new-customer-created="swapToNewCustomer"
    @on-finished="$emit('hideSwapCustomer')"
    @on-cancel="showCreateCustomerModal = false"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import CreateCustomerModal from './CreateCustomerModal.vue';
import SearchCustomerInput from './SearchCustomerInput.vue';
import type { User } from '@/entities/auth/user.entity';
import type { ObjectSchema } from 'yup';
import { useI18n } from 'vue-i18n';
import type { UpdateBookingDto } from '@/entities/bookings/booking.entity';
import { Alert } from '@/utils/alert';

const props = defineProps<{
  bookingId: string;
  schema: ObjectSchema<any>;
  isSaving: boolean;
}>();

const emit = defineEmits<{
  (e: 'hideSwapCustomer'): void;
  (
    e: 'updateBooking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished: () => void,
  ): void;
}>();

const { t } = useI18n();

const selectedCustomerForSwap = ref<User>();
const showCreateCustomerModal = ref(false);

const swapToSelectedCustomer = () => {
  if (!selectedCustomerForSwap.value) return;
  swapCustomer(selectedCustomerForSwap.value, () => emit('hideSwapCustomer'));
};

const createNewCustomer = () => {
  showCreateCustomerModal.value = true;
};

const swapToNewCustomer = async (newCustomer: User, onFinished: () => void) => {
  swapCustomer(newCustomer, onFinished);
};

const swapCustomer = async (customer: User, onFinished: () => void) => {
  const { isConfirmed } = await Alert.fire({
    text: t('swapCustomerText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('adjustDriversData'),
    denyButtonText: t('onlyChangeCustomer'),
  });

  if (isConfirmed) {
    emit(
      'updateBooking',
      {
        id: props.bookingId,
        booking: {
          customerId: customer.id,
          customerData: {
            firstName: customer.firstName,
            lastName: customer.lastName,
            phone: customer.phone ?? undefined,
            dateOfBirth: customer.dateOfBirth ?? undefined,
            city: customer.city ?? undefined,
            company: customer.company ?? undefined,
            country: customer.country ?? undefined,
            gender: customer.gender ?? undefined,
            preferredLocal: customer.preferredLocal ?? undefined,
            street: customer.street ?? undefined,
            zip: customer.zip ?? undefined,
          },
        },
      },
      onFinished,
    );
  } else {
    emit(
      'updateBooking',
      {
        id: props.bookingId,
        booking: {
          customerId: customer.id,
        },
      },
      onFinished,
    );
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "swap": "Swap",
    "createNewCustomer": "+ Create new customer",
    "swapCustomerText": "Do you want to adjust the driver's data of the booking to the selected customer?",
    "adjustDriversData": "Adjust driver's data",
    "onlyChangeCustomer": "Don't adjust driver's data"
  },
  "de": {
    "swap": "Austauschen",
    "createNewCustomer": "+ Neuen Kunden anlegen",
    "swapCustomerText": "Möchtest du die Fahrerdaten der Buchung auf den ausgewählten Kunden anpassen?",
    "adjustDriversData": "Fahrerdaten anpassen",
    "onlyChangeCustomer": "Fahrerdaten nicht anpassen"
  }
}
</i18n>
