<template>
  <div>
    <LabeledText :label="`${t('positions')}`" />

    <div class="mt-4 text-sm">
      <div class="mb-1 flex gap-2 border-b border-primary/40 pb-1">
        <div class="basis-[15%] font-medium">{{ t('amount') }}</div>
        <div class="basis-[45%] font-medium">{{ t('service') }}</div>
        <div class="basis-[10%] font-medium">{{ t('tax') }}</div>
        <div class="basis-[15%] font-medium">{{ t('unitPrice') }}</div>
        <div class="basis-[15%] text-right font-medium">
          {{ t('totalPrice') }}
        </div>
      </div>

      <div
        v-for="position in invoice.positions"
        :key="position.title"
        class="mb-1 flex gap-2 border-b border-primary/40 pb-1"
      >
        <div class="basis-[15%]">
          <div>
            {{ position.amount }}
            {{ position.unitType ? t(`invoicePositionUnitTypes.${position.unitType}`) : '' }}
          </div>
        </div>
        <div class="basis-[45%]">
          <div>
            {{ position.title }}
          </div>
        </div>
        <div class="basis-[10%]">{{ position.tax * 100 }}%</div>
        <div class="basis-[15%]">
          <p v-currency="position.unitPrice" />
        </div>
        <div class="basis-[15%] text-right">
          <p v-currency="position.amount * position.unitPrice"></p>
        </div>
      </div>

      <div class="mt-2 flex gap-2">
        <div class="basis-[83%] text-right font-medium">{{ t('totalNet') }}:</div>
        <div class="basis-[17%] text-right">
          <span v-currency="totalNetPrice" />
        </div>
      </div>
      <div class="mt-1 flex gap-2">
        <div class="basis-[83%] text-right font-medium">{{ t('tax') }}:</div>
        <div class="basis-[17%] text-right">
          <span v-currency="totalTax" />
        </div>
      </div>
      <div class="mt-2 flex gap-2 text-xl font-medium">
        <div class="basis-[83%] text-right">{{ t('total') }}:</div>
        <div class="basis-[17%] text-right">
          <span v-currency="invoice.totalPositionSum" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import LabeledText from '@/components/LabeledText.vue';
import type { Invoice } from '@/entities/invoices/invoice.entity';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const props = defineProps<{
  invoice: Invoice;
}>();

const { t } = useI18n();

const totalNetPrice = computed(() =>
  props.invoice.positions.reduce(
    (prev, cur) => prev + (cur.unitPrice * cur.amount) / (1 + cur.tax),
    0,
  ),
);
const totalTax = computed(() => props.invoice.totalPositionSum - totalNetPrice.value);
</script>

<i18n lang="json">
{
  "en": {
    "positions": "Positions",
    "amount": "Amount",
    "service": "Service",
    "tax": "Tax",
    "unitPrice": "Unit Price",
    "totalPrice": "Total Price",
    "totalNet": "Total (Net)",
    "total": "Total"
  },
  "de": {
    "positions": "Posten",
    "amount": "Anzahl",
    "service": "Leistung",
    "tax": "MwSt.",
    "unitPrice": "Preis/Einheit",
    "totalPrice": "Gesamtpreis",
    "totalNet": "Summe (Netto)",
    "total": "Gesamt"
  }
}
</i18n>
