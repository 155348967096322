<template>
  <SearchApiEntityItemInput
    :search-result-options="searchResultOptions"
    :is-fetching-results="isFetchingResults"
    :placeholder="searchInputPlaceholder"
    @fetch="fetchResults"
    @on-update-selected-item="updateSelectedItem"
  />
</template>

<script lang="ts" setup>
import SearchApiEntityItemInput from './SearchApiEntityItemInput.vue';
import { computed, ref } from 'vue';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { useI18n } from 'vue-i18n';
import { getDateFormat } from '@/hooks/use-date-format';
import { formatDateString } from '@/hooks/use-formated-date';
import type { Invoice } from '@/entities/invoices/invoice.entity';
import type { FindAllInvoicesQueryDto } from '@/entities/invoices/invoice.entity';
import { InvoiceType } from '@/entities/invoices/invoice-type.enum';
import { InvoiceStatus } from '@/entities/invoices/invoice-status.enum';
import { useInvoicesWithQuery } from '@/queries/use-invoices';

const emit = defineEmits<{
  (e: 'on-update-invoice', value: Invoice | undefined): void;
}>();

const { t } = useI18n();

const searchString = ref('');
const searchInputPlaceholder = computed(() => t('searchForInvoice'));

const query = computed<FindAllInvoicesQueryDto>(() => ({
  filter: [
    {
      types: [
        InvoiceType.BOOKING,
        InvoiceType.DAMAGE,
        InvoiceType.ADMINISTRATIVE_OFFENCE,
        InvoiceType.OTHER,
        InvoiceType.GENERIC,
      ],
      status: [InvoiceStatus.OPEN, InvoiceStatus.PAID, InvoiceStatus.SENT],
      query: searchString.value,
    },
  ],
  limit: 20,
}));

const { data: searchResultsData, isFetching: isFetchingResults } = useInvoicesWithQuery(query);

const fetchResults = (search: string) => {
  searchString.value = search;
};

const searchResults = useFlattenPaginatedData(searchResultsData);

const searchResultOptions = computed(() => {
  return searchResults.value.map((invoice) => {
    const content = [
      `${t('invoiceNumber')} ${invoice.completeInvoiceNumber}`,
      invoice.title,
      invoice.receiverData.firstName + ' ' + invoice.receiverData.lastName,
      `${t('date')}: ${formatDateString(invoice.invoiceDate, getDateFormat())}`,
    ];
    return {
      item: invoice,
      content,
    };
  });
});

const updateSelectedItem = (item: any) => {
  emit('on-update-invoice', item as Invoice);
};
</script>

<i18n lang="json">
{
  "en": {
    "invoiceNumber": "INr.",
    "date": "Date",
    "searchForInvoice": "Search for Invoice"
  },
  "de": {
    "invoiceNumber": "RNr.",
    "date": "Datum",
    "searchForInvoice": "Rechnung suchen"
  }
}
</i18n>
