<template>
  <form class="space-y-4" @submit="onSubmit">
    <div class="flex justify-between">
      <h2>{{ t('basicInfo') }}</h2>
      <DropdownDotted :buttons="dottedMenuItems" />
    </div>
    <div class="grid grid-cols-3 gap-4">
      <div class="space-y-2">
        <LabeledText :label="t('vehicleType')">
          {{ props.initialCar.vehicleType.make.name }}
          {{ props.initialCar.vehicleType.model }}
        </LabeledText>

        <LabeledText :label="t('licencePlate')">
          {{ props.initialCar.licencePlate }}
        </LabeledText>

        <LabeledText :label="t('fin')">
          {{ props.initialCar.fin }}
        </LabeledText>
      </div>

      <div class="col-span-2">
        <img v-if="imageUrl" :src="imageUrl" :width="400" :height="200" />
        <div
          v-else
          class="h-50 flex w-full items-center justify-center border border-primary/40 text-primary"
        >
          {{ t('noImage') }}
        </div>
      </div>
    </div>

    <Divider />

    <div class="flex items-center justify-between">
      <h2>{{ t('carStatus') }}</h2>

      <CVButton @click.prevent="gotoEditCarStatus">
        {{ t('editCarStatusAndDamages') }}
      </CVButton>
    </div>

    <div class="grid grid-cols-3 gap-4">
      <SelectField name="tyres" :label="t('tyres')" :options="tyresOptions" />

      <LabeledText :label="t('status')">{{ t(`carStatus.${initialCar?.status}`) }}</LabeledText>

      <LabeledText :label="t('lastTuvDate')">{{ formattedLastTuvDate }}</LabeledText>

      <InspectionDatesList :inspections="initialCar.inspections" class="col-span-2" />

      <div
        v-if="formHasChanged(initialValues, formValues) || isSaving"
        class="flex w-full justify-end"
      >
        <CVButton :is-loading="isSaving" type="submit" :disabled="isSaving">
          {{ t('save') }}
        </CVButton>
      </div>
    </div>

    <Divider />

    <h2>{{ t('availability') }}</h2>

    <div class="grid grid-cols-3 gap-4">
      <LabeledText :label="t('availableFrom')">{{ formattedAvailableFrom }}</LabeledText>

      <LabeledText :label="t('availableUntil')">{{ formattedAvailableUntil }}</LabeledText>
    </div>

    <Divider />

    <ImmutableCarStations :car-stations="initialCar.stations" :car="initialCar" />

    <Divider />

    <h2>{{ t('specificCarDetails') }}</h2>

    <div class="grid grid-cols-3 gap-4">
      <LabeledText :label="t('color')">{{ t(`carColors.${initialCar.color}`) }}</LabeledText>

      <LabeledText :label="t('firstRegistration')">{{ formattedFirstRegistration }}</LabeledText>
    </div>

    <Divider />

    <h2>{{ t('technicalData') }}</h2>

    <div class="grid grid-cols-3 gap-4">
      <LabeledText :label="t('fuelType')">{{
        t(`fuelTypes.${initialCar.fuelType ?? initialCar.vehicleType.fuelType}`)
      }}</LabeledText>

      <LabeledText :label="t('transmission')">{{
        t(`transmissionTypes.${initialCar.transmission ?? initialCar.vehicleType.transmission}`)
      }}</LabeledText>

      <LabeledText :label="t('drive')">{{
        t(`driveTypes.${initialCar.drive ?? initialCar.vehicleType.drive}`)
      }}</LabeledText>

      <LabeledText
        v-if="(initialCar.fuelType ?? initialCar.vehicleType.fuelType) !== FuelType.ELECTRIC"
        :label="t('tankVolume')"
        >{{
          initialCar.tankVolumeInLiter ?? initialCar.vehicleType.tankVolumeInLiter
        }}
        l</LabeledText
      >

      <LabeledText
        v-if="(initialCar.fuelType ?? initialCar.vehicleType.fuelType) === FuelType.ELECTRIC"
        :label="t('batteryCapacity')"
        >{{
          initialCar.batteryCapacityInKwh ?? initialCar.vehicleType.batteryCapacityInKwh
        }}
        kWh</LabeledText
      >

      <LabeledText
        v-if="(initialCar.fuelType ?? initialCar.vehicleType.fuelType) === FuelType.ELECTRIC"
        :label="t('range')"
        >{{ initialCar.rangeInKm }} km</LabeledText
      >

      <LabeledText :label="t('seats')">{{
        initialCar.seats ?? initialCar.vehicleType.seats
      }}</LabeledText>

      <LabeledText :label="t('power')"
        >{{ initialCar.power ?? initialCar.vehicleType.power }} {{ t('hp') }}</LabeledText
      >

      <LabeledText :label="t('accelaration')"
        >{{ initialCar.accelaration ?? initialCar.vehicleType.accelaration }} s</LabeledText
      >
    </div>

    <Divider />

    <h2>{{ t('features') }}</h2>

    <div class="grid grid-cols-3 gap-4">
      <LabeledText :label="t('gpsNavigation')">
        {{ initialCar.hasNavigation ? t('yes') : t('no') }}
      </LabeledText>

      <LabeledText :label="t('distanceControl')">
        {{ initialCar.hasDistanceControl ? t('yes') : t('no') }}
      </LabeledText>

      <LabeledText :label="t('rearViewCamera')">
        {{ initialCar.hasRearViewCamera ? t('yes') : t('no') }}
      </LabeledText>
    </div>

    <Divider />

    <h2>{{ t('documents') }}</h2>

    <CarDocuments
      :car="initialCar"
      :document-type-filter="[CarDocumentType.CAR_IMAGE, CarDocumentType.REGISTRATION_CERTIFICATE]"
      immutable
    />
  </form>
</template>

<script lang="ts" setup>
import Divider from '@/components/Divider.vue';
import SelectField from '@/components/SelectField.vue';
import type { Car, UpdateCarDto } from '@/entities/cars/car.entity';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import CarDocuments from '@/views/vehicle-pool/edit-car/components/CarDocuments.vue';
import { formHasChanged } from '@/hooks/use-form-has-changed';
import { cloneDeep } from 'lodash';
import ImmutableCarStations from './ImmutableCarStations.vue';
import { CarTyres } from '@/entities/cars/car-tyres.enum';
import LabeledText from '@/components/LabeledText.vue';
import { useFormatedDateString } from '@/hooks/use-formated-date';
import { getDateFormat, getDateTimeFormat } from '@/hooks/use-date-format';
import InspectionDatesList from '@/views/vehicle-pool/edit-car/components/InspectionDatesList.vue';
import { FuelType } from '@/entities/cars/fuel-type.enum';
import { CarDocumentType } from '@/entities/cars/car-document-type.enum';
import * as yup from 'yup';
import { useAuthStore } from '@/stores/auth.store';
import { UserRole } from '@/entities/auth/user-role.enum';
import DropdownDotted from '@/components/DropdownDotted.vue';

const props = defineProps<{
  initialCar: Car;
  isSaving: boolean;
}>();
const emit = defineEmits<{
  (e: 'onSubmit', value: UpdateCarDto): void;
}>();

const { t } = useI18n();
const { user } = useAuthStore();

const formattedLastTuvDate = useFormatedDateString(
  computed(() => props.initialCar?.lastTuvDate),
  getDateTimeFormat(),
  '-',
);

const formattedAvailableFrom = useFormatedDateString(
  computed(() => props.initialCar?.availableFrom),
  getDateFormat(),
  '-',
);

const formattedAvailableUntil = useFormatedDateString(
  computed(() => props.initialCar?.availableUntil),
  getDateFormat(),
  '-',
);

const formattedFirstRegistration = useFormatedDateString(
  computed(() => props.initialCar?.firstRegistration),
  getDateFormat(),
  '-',
);

const tyresOptions = computed(() => {
  return Object.values(CarTyres).map((tyres) => ({
    label: t(`carTyres.${tyres}`),
    value: tyres,
  }));
});

const initialValues = ref<UpdateCarDto>({
  tyres: props.initialCar.tyres,
});

const imageUrl = computed(() => props.initialCar.imageUrl || props.initialCar.vehicleType.imageUrl);

const gotoEditCarStatus = () => {
  router.push({
    name: 'editCarStatus',
    params: { id: props.initialCar?.id },
  });
};

const { handleSubmit, values: formValues } = useForm({
  initialValues: initialValues.value,
  validationSchema: yup.object({
    tyres: yup.string().oneOf(Object.values(CarTyres)).required(),
  }),
});

const onSubmit = handleSubmit((values) => {
  emit('onSubmit', values);
  initialValues.value = cloneDeep(values);
});

usePreventLeavingUnsavedForm(() => formHasChanged(initialValues.value, formValues));

const dottedMenuItems = computed(() => [
  {
    title: t('addMaintenanceBlock'),
    onClick: () =>
      router.push({
        name: 'maintenanceEdit',
        params: { carId: props.initialCar.id, blockId: 'new' },
      }),
  },
  {
    title: t('addPartnerRent'),
    onClick: () =>
      router.push({
        name: 'partnerRentEdit',
        params: { carId: props.initialCar.id, bookingId: 'new' },
      }),
  },
  ...(user?.role === UserRole.ADMIN
    ? [
        {
          title: t('editCar'),
          onClick: () =>
            router.push({
              name: 'editCar',
              params: { id: props.initialCar.id },
            }),
        },
      ]
    : []),
]);
</script>

<i18n lang="json">
{
  "en": {
    "basicInfo": "Basic Info",
    "vehicleType": "Vehicle Type",
    "fin": "FIN",
    "changeImage": "Change Image",
    "removeImage": "× Remove Image",
    "status": "Status",
    "carStatus": "Car Status",
    "tyres": "Tyres",
    "lastTuvDate": "Last TÜV Date",
    "editCarStatusAndDamages": "View/Edit Car Status & Damages",
    "availability": "Availability",
    "specificCarDetails": "Specific Car Details",
    "color": "Color",
    "acquisitionType": "Acquisition Type",
    "purchasePrice": "Purchase Price",
    "leasingRate": "Leasing Rate",
    "listPrice": "List Price",
    "insurance": "Insurance",
    "availableFrom": "Available from",
    "availableUntil": "Available until",
    "technicalData": "Technical Data",
    "fuelType": "Fuel Type",
    "transmission": "Transmission",
    "seats": "Seats",
    "power": "Power",
    "accelaration": "Acceleration (0 - 100)",
    "drive": "Drive",
    "tankVolume": "Tank Volume",
    "batteryCapacity": "Battery Capacity",
    "range": "Range",
    "features": "Features",
    "gpsNavigation": "GPS/Navigation",
    "distanceControl": "Distance Control ...",
    "rearViewCamera": "... incl. Rear View Camera",
    "fromVehicleType": "From Vehicle Type",
    "documents": "Documents",
    "noImage": "No Image",
    "fuelLevel": "Fuel Level",
    "milage": "Milage",
    "licencePlatePlaceholder": "i.e. M-CV 1234",
    "purchasePriceTooltip": "The actual gross list price of the car minus discount (incl. transfer costs)",
    "availableFromTooltip": "Car is bookable on the website from this date",
    "firstRegistration": "First Registration",
    "hiddenFromPublic": "Hide from public",
    "externalLocation": "External location",
    "resetImage": "Reset image",
    "hp": "hp",
    "editCar": "Edit Car",
    "addMaintenanceBlock": "+ Add Maintenance Block",
    "addPartnerRent": "+ Add Partner Rent"
  },
  "de": {
    "basicInfo": "Grundlegende Daten",
    "vehicleType": "Vehicle Type",
    "fin": "FIN",
    "changeImage": "Bild ändern",
    "removeImage": "× Bild löschen",
    "status": "Status",
    "carStatus": "Fahrzeugstatus",
    "tyres": "Bereifung",
    "lastTuvDate": "Letzter TÜV Termin",
    "editCarStatusAndDamages": "Fahrzeugstatus & Schäden ansehen/bearbeiten",
    "availability": "Verfügbarkeit",
    "specificCarDetails": "Spezifische Fahrzeugdaten",
    "color": "Farbe",
    "acquisitionType": "Beschaffungsart",
    "purchasePrice": "Kaufpreis",
    "leasingRate": "Leasingrate",
    "listPrice": "Listenpreis",
    "insurance": "Versicherung",
    "availableFrom": "Verfügbar ab",
    "availableUntil": "Verfügbar bis",
    "technicalData": "Technische Daten",
    "fuelType": "Treibstofftyp",
    "transmission": "Getriebe",
    "seats": "Sitze",
    "power": "Leistung",
    "accelaration": "Beschleunigung (0 - 100)",
    "drive": "Antrieb",
    "tankVolume": "Tankgröße",
    "batteryCapacity": "Batteriekapazität",
    "range": "Reichweite",
    "features": "Ausstattung",
    "gpsNavigation": "GPS/Navigation",
    "distanceControl": "Parkabstandswarner ...",
    "rearViewCamera": "... inkl. Rückfahrkamera",
    "fromVehicleType": "Von Vehicle Type",
    "documents": "Dokumente",
    "noImage": "Kein Bild",
    "fuelLevel": "Tankfüllung",
    "milage": "Kilometerstand",
    "licencePlatePlaceholder": "zB.: M-CV 1234",
    "purchasePriceTooltip": "Der tatsächliche Brutto-Listenpreis des Autos abzüglich Rabatt (inkl. Überführungskosten)",
    "availableFromTooltip": "Auto ist ab diesem Datum auf der Webseite buchbar",
    "firstRegistration": "Erstzulassung",
    "hiddenFromPublic": "Auf Webseite ausblenden",
    "externalLocation": "Auswärtiger Standort",
    "resetImage": "Bild zurücksetzen",
    "hp": "PS",
    "editCar": "Fahrzeug bearbeiten",
    "addMaintenanceBlock": "+ Wartungsblock hinzufügen",
    "addPartnerRent": "+ Partnermiete hinzufügen"
  }
}
</i18n>
