<template>
  <PaginatedList :load="load" :query-key="['audit-log', props.entityType, props.entityId]">
    <template #entry="data">
      <div class="mb-2 flex flex-row items-start p-2">
        <div class="mr-4 w-10 rounded-lg p-2" :class="colors[data.entry.operation]">
          <component :is="icons[data.entry.operation]" />
        </div>
        <div class="flex-1 py-1">
          <span class="font-bold"> {{ formatIsoDateString(data.entry.createdAt) }}</span
          >&nbsp;{{ t('by') }}
          <span v-if="data.entry.user"
            >{{ data.entry.user.firstName }}
            {{ data.entry.user.lastName }}
          </span>
          <span v-else class="text-gray-500">
            {{ t('system') }}
          </span>
          <ChangedFieldsList :changed-fields="data.entry.changedFields" />
        </div>
      </div>
    </template>
  </PaginatedList>
</template>
<script setup lang="ts">
import PaginatedList from '@/components/PaginatedList.vue';
import { auditLogService } from '@/api/audit-log.service';
import { useI18n } from 'vue-i18n';
import { PencilIcon, PlusCircleIcon, TrashIcon } from '@heroicons/vue/24/outline';
import ChangedFieldsList from '@/components/audit-log/ChangedFieldsList.vue';
import { formatIsoDateString } from '@/hooks/use-formated-date';
import type { AuditLogEntityType } from '@/entities/audit-log/audit-log.entity';

const { t } = useI18n();

const icons = {
  create: PlusCircleIcon,
  update: PencilIcon,
  delete: TrashIcon,
};

const colors = {
  create: 'text-green-500 bg-green-100',
  update: 'text-blue-500 bg-blue-100',
  delete: 'text-red-500 bg-red-100',
};

const props = defineProps<{
  entityType: AuditLogEntityType;
  entityId: string;
}>();

const load = (pageNumber: number) =>
  auditLogService.getForEntity({
    entityType: props.entityType,
    entityId: props.entityId,
    page: pageNumber,
  });
</script>

<i18n lang="json">
{
  "en": {
    "by": "by",
    "system": "🤖 system"
  },
  "de": {
    "by": "von",
    "system": "🤖 System"
  }
}
</i18n>
