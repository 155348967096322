<template>
  <div class="space-y-4">
    <h2>{{ t('rosSettings') }}</h2>
    <form class="space-y-4" @submit.prevent="onSubmit">
      <SelectField
        name="showHandoverSurvey"
        :label="t('showHandoverSurvey')"
        :options="surveyOptions"
        empty-value-to-null
      />

      <div class="flex justify-end">
        <CVButton :is-loading="isUpdating" type="submit">{{ t('save') }}</CVButton>
      </div>
    </form>

    <Divider />

    <h2>{{ t('more') }}</h2>
    <div class="grid grid-cols-3 items-end gap-4">
      <Select
        v-model="surveyToDownloadId"
        class="col-span-2"
        :label="t('downloadSurveyResultsCsv')"
        :options="surveyOptions"
        empty-value-to-null
      />
      <CVButton
        :is-loading="isDownloadingCsv"
        :disabled="!surveyToDownloadId"
        @click.prevent="downloadCsv"
        >{{ t('downloadCsv') }}</CVButton
      >
    </div>
    <div v-if="$can('reload', 'FeatureFlags')">
      <p class="text-lg font-medium">{{ t('reloadFeatureFlags') }}</p>
      <CVButton
        class="w-full"
        :is-loading="reloadingFeatureFlags"
        @click.prevent="reloadFeatureFlags"
        >{{ t('reloadFeatureFlags') }}</CVButton
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import Divider from '@/components/Divider.vue';
import Select from '@/components/Select.vue';
import SelectField from '@/components/SelectField.vue';
import type { GlobalSettings, UpdateGlobalSettingsDto } from '@/entities/global-settings.entity';
import type { Survey } from '@/entities/surveys/survey.entity';
import { useI18n } from 'vue-i18n';
import { useExportSurveyCsv } from '@/queries/use-surveys';
import fileDownload from 'js-file-download';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import CVButton from '@/components/buttons/CVButton.vue';
import { useReloadFeatureFlags } from '@/queries/use-root';

const props = defineProps<{
  globalSettings: GlobalSettings;
  surveys: Survey[];
  isUpdating: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSubmit', value: UpdateGlobalSettingsDto): void;
}>();

const { t, locale } = useI18n();

const surveyToDownloadId = ref<string | null>(null);

const { handleSubmit } = useForm<UpdateGlobalSettingsDto>({
  initialValues: props.globalSettings,
});

const { mutateAsync: exportCsv, isPending: isDownloadingCsv } = useExportSurveyCsv();

const onSubmit = handleSubmit((values) => {
  emit('onSubmit', values);
});

const surveyOptions = computed(() => {
  return [
    {
      label: t('noSurvey'),
      value: null,
    },
    ...props.surveys.map((survey) => ({
      label: locale.value === 'de' ? survey.titleDe : survey.titleEn,
      value: survey.id,
    })),
  ];
});

const downloadCsv = async () => {
  if (!surveyToDownloadId.value) return;
  const csv = await exportCsv({ surveyId: surveyToDownloadId.value });
  const survey = props.surveys.find((survey) => survey.id === surveyToDownloadId.value);
  const dateString = new Date().toISOString().split('T')[0];
  const fileName =
    (survey ? (locale.value === 'de' ? survey.titleDe : survey.titleEn) : 'Survey') +
    ' ' +
    dateString +
    '.csv';
  fileDownload(csv, fileName);
  surveyToDownloadId.value = null;
};

const { mutateAsync: reloadFeatureFlagsAsync, isPending: reloadingFeatureFlags } =
  useReloadFeatureFlags();

const reloadFeatureFlags = () => {
  reloadFeatureFlagsAsync();
};
</script>

<i18n lang="json">
{
  "en": {
    "rosSettings": "ROS Settings",
    "showHandoverSurvey": "Show handover survey",
    "noSurvey": "- No survey -",
    "downloadSurveyResultsCsv": "Donwload survey results as CSV",
    "downloadCsv": "Download CSV",
    "more": "More",
    "reloadFeatureFlags": "Reload feature flags in API"
  },
  "de": {
    "rosSettings": "ROS Einstellungen",
    "showHandoverSurvey": "Zeige Umfrage bei Übergabe an",
    "noSurvey": "- Keine Umfrage -",
    "downloadSurveyResultsCsv": "Umfragenergebnisse als CSV herunterladen",
    "downloadCsv": "CSV herunterladen",
    "more": "Weiteres",
    "reloadFeatureFlags": "Featureflags in API aktualisieren"
  }
}
</i18n>
