<template>
  <HeaderBar />
  <section class="carvia-container relative">
    <div class="mb-4 grid grid-cols-3 items-center gap-4">
      <Input v-model="searchQuery" :placeholder="t('search')" class="flex-1" />
      <Input
        v-model="licencePlateFinSearchQuery"
        :placeholder="t('searchForLicencePlateFin')"
        class="flex-1"
      />
      <CVButton
        class="flex-1"
        @click="
          $router.push({
            name: 'editVehicleType',
            params: { id: 'new' },
          })
        "
        >{{ t('addVehicleType') }}</CVButton
      >
    </div>

    <div class="mb-4 grid grid-cols-3 gap-4">
      <div class="col-span-2 flex items-center justify-between gap-4">
        <div class="flex gap-4">
          <CVButton
            no-hover
            :outline="activeTabGroup === TabGroup.FILTER"
            @click.prevent="activeTabGroup = TabGroup.FILTER"
            >{{ t('showFilters') }}</CVButton
          >
          <CVButton
            no-hover
            :outline="activeTabGroup === TabGroup.TODO"
            @click.prevent="activeTabGroup = TabGroup.TODO"
            >{{ t('showTodos') }}</CVButton
          >
        </div>
        <Select
          v-model="stationId"
          :options="stationOptions"
          :placeholder="t('station')"
          empty-value-to-null
        />
      </div>
      <div>
        <CVButton
          class="w-full"
          @click="
            $router.push({
              name: 'addNewCar',
            })
          "
          >{{ t('addCar') }}</CVButton
        >
      </div>
    </div>

    <div class="mb-6">
      <Tabs v-if="activeTabGroup === TabGroup.FILTER" v-model="activeTab" :tabs="tabsFilter" />
      <Tabs v-if="activeTabGroup === TabGroup.TODO" v-model="activeTab" :tabs="tabsTodo" />
    </div>

    <div
      v-if="activeTab in infoBoxTexts"
      class="mb-4 flex items-center gap-3 border border-primary/50 p-2 pb-1 pt-1.5"
    >
      <div
        class="flex h-8 w-8 flex-none items-center justify-center rounded-full border border-primary text-2xl text-primary"
      >
        i
      </div>
      <p class="text-sm">{{ infoBoxTexts[activeTab] }}</p>
    </div>

    <div v-if="activeTab === Tab.GHOST_CARS" class="mb-4 grid grid-cols-3 gap-4">
      <CVButton
        class="col-start-3"
        @click="
          router.push({
            name: 'addNewCar',
            query: { isGhostCar: 'true' },
          })
        "
        >{{ t('addGhostCar') }}</CVButton
      >
    </div>

    <div v-if="vehicleTypes && showVehicleTypes" class="flex flex-col space-y-2">
      <VehicleTypeCard
        v-for="vehicleType in vehicleTypes"
        :key="vehicleType.id"
        :vehicle-type="vehicleType"
      />
    </div>
    <div v-else>
      <div v-if="activeTab === Tab.ORDERED" class="space-y-2">
        <div v-for="(month, i) in carsPerMonth" :key="i" class="space-y-2">
          <div class="text-sm text-primary">
            <span class="font-medium">{{ formatDateString(month.month, 'LLLL') }}</span>
            {{ formatDateString(month.month, 'yyyy') }}
          </div>
          <div class="space-y-2">
            <CarCard
              v-for="car in month.cars"
              :key="car.id"
              :car="car"
              :vehicle-type="car.vehicleType"
              :drop-down-items="todoDropDownItems(car.id)"
              :on-click-function="getTodoFunction(car)"
            />
          </div>
        </div>
      </div>
      <div v-else class="space-y-2">
        <CarCard
          v-for="car in visibleCars"
          :key="car.id"
          :car="car"
          :vehicle-type="car.vehicleType"
          :drop-down-items="todoDropDownItems(car.id)"
          :on-click-function="getTodoFunction(car)"
        />
      </div>

      <div v-if="carsHasNextPage" class="flex justify-center pt-6">
        <CVButton outline :is-loading="carsIsFetchingNextPage" @click="() => carsFetchNextPage()">
          {{ t('loadMore') }}
        </CVButton>
      </div>
    </div>

    <EntityListExceptions
      v-if="showVehicleTypes"
      :status="vehicleTypesStatus"
      :error="vehicleTypesError"
      :items-count="vehicleTypes?.length ?? 0"
      :no-items-text="t('noVehicleTypeFound')"
    />

    <EntityListExceptions
      v-if="showCars"
      :status="carsStatus"
      :error="carsError"
      :items-count="cars.length"
      :no-items-text="t('noCarFound')"
    />

    <div v-if="isError">
      <p>{{ t('somethingWentWrong') }}</p>
    </div>

    <TodosModal
      v-model="showTodosModal"
      :car="currentTodoCar"
      :stations="stations"
      :active-tab="activeTab"
    />

    <div
      v-if="isUpdatingCar"
      class="absolute inset-0 z-10 flex items-center justify-center bg-black/20"
    >
      <Spinner />
    </div>
  </section>
</template>

<script lang="ts" setup>
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import Input from '@/components/Input.vue';
import type { FindAllVehicleTypesQuery } from '@/entities/vehicle-type.entity';
import { useI18n } from 'vue-i18n';
import { useVehicleTypesWithQuery } from '@/queries/use-vehicle-types';
import { useUiStore } from '@/stores/ui.store';
import { debounce } from 'lodash';
import { computed, reactive, ref, watch, watchEffect } from 'vue';
import VehicleTypeCard from '@/views/vehicle-types/vehicle-types-list/VehicleTypeCard.vue';
import Select from '@/components/Select.vue';
import { useStations } from '@/queries/use-stations';
import { useRoute, useRouter } from 'vue-router';
import CarCard from '@/views/vehicle-types/vehicle-types-list/CarCard.vue';
import { useCarsWithQuery } from '@/queries/use-cars';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import Tabs, { type TabItem } from '@/components/Tabs.vue';
import { VehiclePoolTab as Tab } from './vehicle-pool-tab.enum';
import { useCarsQuery } from './use-cars-query';
import TodosModal from './components/TodosModal.vue';
import { getCurrentInspectionCycleMileage } from '@/hooks/use-current-inspection-cycle-mileage';
import { DateTime } from 'luxon';
import { getTuvDueDate } from '@/hooks/use-tuv-due-date';
import { getCurrentCarTyresSeason } from '@/utils/get-current-car-tyres-season';
import EntityListExceptions from '@/components/EntityListExceptions.vue';
import { CarStatus } from '@/entities/cars/car-status.enum';
import { useTodoFunctions } from './use-todo-functions';
import { useRouteQueryStore } from '@/stores/route-query.store';
import type { Car } from '@/entities/cars/car.entity';
import { formatDateString } from '@/hooks/use-formated-date';
import { useLocalizedFields } from '@/hooks/use-localized-fields';

enum TabGroup {
  FILTER = 'FILTER',
  TODO = 'TODO',
}

const { t } = useI18n();
const { getLocalizedField } = useLocalizedFields();
const searchQuery = ref<string>('');
const licencePlateFinSearchQuery = ref('');
const licencePlateFinSearchQueryDebounced = ref<string | undefined>(undefined);
const route = useRoute();
const preSelectedStationId = route.query.stationId as string | undefined;
const stationId = ref<string | null>(preSelectedStationId ?? null);
const router = useRouter();
const routeQueryStore = useRouteQueryStore();

const activeTab = ref<Tab>(Tab.OVERVIEW);
const tabsFilter = computed<TabItem[]>(() => [
  { title: t('overview'), id: Tab.OVERVIEW },
  { title: t('ordered'), id: Tab.ORDERED },
  { title: t('floatIn'), id: Tab.FLOAT_IN },
  { title: t('floatOut'), id: Tab.FLOAT_OUT },
  { title: t('ghostCars'), id: Tab.GHOST_CARS },
]);
const tabsTodo = computed<TabItem[]>(() => [
  { title: t('merchantConsultation'), id: Tab.MERCHANT_CONSULTATION },
  { title: t('carIncoming'), id: Tab.CAR_INCOMING },
  { title: t('inspectionDate'), id: Tab.INSPECTION },
  { title: t('tuvDate'), id: Tab.TUV },
  { title: t('tyres'), id: Tab.TYRES },
  { title: t('sell'), id: Tab.SELL },
]);

const showVehicleTypes = computed(
  () => !!((activeTab.value === Tab.OVERVIEW && !licencePlateFinSearchQuery.value) || query.query),
);
const showCars = computed(() => !showVehicleTypes.value);

const preSelectedTabGroup = Object.values(TabGroup).find(
  (tabGroup) => tabGroup === (route.query['tab-group'] as string),
);
const activeTabGroup = ref<TabGroup>(preSelectedTabGroup ?? TabGroup.FILTER);

watch(activeTabGroup, () => {
  activeTab.value =
    activeTabGroup.value === TabGroup.FILTER ? Tab.OVERVIEW : Tab.MERCHANT_CONSULTATION;
  routeQueryStore.push({ 'tab-group': activeTabGroup.value });
});

watch(activeTab, () => {
  if (activeTab.value !== Tab.OVERVIEW) {
    searchQuery.value = '';
    query.query = undefined;
    licencePlateFinSearchQuery.value = '';
    licencePlateFinSearchQueryDebounced.value = undefined;
  }
});

const query = reactive<FindAllVehicleTypesQuery>({
  query: undefined,
  stationIds: preSelectedStationId ? [preSelectedStationId] : undefined,
});
const {
  data: vehicleTypes,
  isError: isVehicleTypesError,
  status: vehicleTypesStatus,
  error: vehicleTypesError,
} = useVehicleTypesWithQuery(query, showVehicleTypes);

const carsQuery = useCarsQuery(activeTab, licencePlateFinSearchQueryDebounced, stationId);
const {
  data: carsData,
  isError: isCarsError,
  fetchNextPage: carsFetchNextPage,
  hasNextPage: carsHasNextPage,
  isFetchingNextPage: carsIsFetchingNextPage,
  status: carsStatus,
  error: carsError,
} = useCarsWithQuery(carsQuery, showCars);
const cars = useFlattenPaginatedData(carsData);

const visibleCars = computed(() =>
  cars.value.filter((car) => {
    if (
      (activeTab.value !== Tab.MERCHANT_CONSULTATION &&
        activeTab.value !== Tab.CAR_INCOMING &&
        activeTab.value !== Tab.INSPECTION &&
        activeTab.value !== Tab.TUV &&
        activeTab.value !== Tab.TYRES &&
        activeTab.value !== Tab.SELL) ||
      (activeTab.value === Tab.MERCHANT_CONSULTATION && !car.merchantConsultedDate) ||
      (activeTab.value === Tab.CAR_INCOMING && !car.registrationAndOrganizationDoneDate) ||
      (activeTab.value === Tab.INSPECTION &&
        getCurrentInspectionCycleMileage(car) >
          (car.inspections.length ? car.inspections[car.inspections.length - 1].milage : 0)) ||
      (activeTab.value === Tab.TUV &&
        (car.lastTuvDate === null ||
          DateTime.fromISO(car.lastTuvDate) < DateTime.fromJSDate(getTuvDueDate()))) ||
      (activeTab.value === Tab.TYRES && car.tyres !== getCurrentCarTyresSeason()) ||
      (activeTab.value === Tab.SELL && car.status === CarStatus.FLOAT_IN)
    ) {
      return true;
    }
    return false;
  }),
);

const carsPerMonth = computed(() => {
  return visibleCars.value
    .reduce(
      (months, car) => {
        const monthDate = DateTime.fromISO(car.availableFrom).startOf('month').toISO()!;
        const month = months.find((month) => month.month === monthDate);
        if (!month) {
          months.push({
            month: monthDate,
            cars: [car],
          });
        } else {
          month.cars.push(car);
        }
        return months;
      },
      [] as { month: string; cars: Car[] }[],
    )
    .sort((a, b) => (DateTime.fromISO(a.month) > DateTime.fromISO(b.month) ? 1 : -1));
});

const isError = computed(
  () =>
    (!showVehicleTypes.value && isVehicleTypesError.value) || (showCars.value && isCarsError.value),
);

const { data: stationsData } = useStations(500);
const stations = useFlattenPaginatedData(stationsData);

const stationOptions = computed(() => {
  if (!stations.value) return [];
  return [
    {
      label: t('allStations'),
      value: null,
    },
    ...stations.value.map((station) => {
      return {
        label: getLocalizedField(station.info),
        value: station.id,
      };
    }),
  ];
});
watch(stationId, (value) => {
  query.stationIds = !value ? undefined : [value];
  routeQueryStore.push({
    stationId: !value ? undefined : value,
  });
});

const refreshSearchQuery = debounce(() => {
  query.query = searchQuery.value || undefined;
  if (!searchQuery.value) return;
  activeTab.value = Tab.OVERVIEW;
  activeTabGroup.value = TabGroup.FILTER;
}, 500);
watch(searchQuery, () => {
  refreshSearchQuery();
});

const refreshLicencePlateFinSearchQuery = debounce(() => {
  licencePlateFinSearchQueryDebounced.value = licencePlateFinSearchQuery.value || undefined;
  if (!licencePlateFinSearchQuery.value) return;
  activeTab.value = Tab.OVERVIEW;
  activeTabGroup.value = TabGroup.FILTER;
}, 500);
watch(licencePlateFinSearchQuery, () => {
  refreshLicencePlateFinSearchQuery();
});

const { getTodoFunction, showTodosModal, currentTodoCar, isUpdatingCar } = useTodoFunctions(
  activeTab,
  t,
);

const todoDropDownItems = (carId: string) => [
  {
    title: t('editCar'),
    onClick: () =>
      router.push({
        name: 'editCar',
        params: { id: carId },
      }),
  },
];

const uiStore = useUiStore();
watchEffect(() => {
  uiStore.setHeaderTitle(t('vehiclePoolManagement'));
});

const infoBoxTexts = computed<Record<string, string>>(() => ({
  [Tab.MERCHANT_CONSULTATION]: t('infoBoxTexts.merchantConsultation'),
  [Tab.CAR_INCOMING]: t('infoBoxTexts.carIncoming'),
  [Tab.INSPECTION]: t('infoBoxTexts.inspection'),
  [Tab.TUV]: t('infoBoxTexts.tuv'),
  [Tab.TYRES]: t('infoBoxTexts.tyres'),
  [Tab.SELL]: t('infoBoxTexts.sell'),
}));
</script>

<i18n lang="json">
{
  "en": {
    "vehiclePoolManagement": "Vehicle Pool Management",
    "addVehicleType": "+ Add Vehicle Type",
    "addCar": "+ Add Car",
    "addGhostCar": "+ Add Ghost Car",
    "search": "Search vehicle type",
    "station": "Station",
    "allStations": "All Stations",
    "somethingWentWrong": "Something went wrong",
    "searchForLicencePlateFin": "Search car (licence plate/FIN)",
    "showFilters": "Show Filters",
    "showTodos": "Show Todos",
    "overview": "Overview",
    "ordered": "Ordered",
    "floatIn": "Float In",
    "floatOut": "Float Out",
    "ghostCars": "Ghost Cars",
    "merchantConsultation": "Merchant Consultation",
    "carIncoming": "Car Incoming",
    "inspectionDate": "Inspection Date",
    "tuvDate": "TÜV Date",
    "tyres": "Tyres",
    "sell": "Sell",
    "noVehicleTypeFound": "No vehicle type found",
    "noCarFound": "No car found",
    "changedToSummerTyres": "Confirm changed to summer tyres",
    "changedToWinterTyres": "Confirm changed to winter tyres",
    "confirmMerchantConsultation": "Confirm merchant consultation",
    "confirmRegistration": "Confirm registration",
    "enterInspectionAppointmentDate": "Enter inspection appointment date",
    "enterTuvAppointmentDate": "Enter TÜV appointment date",
    "fleetOutCar": "Fleet out car",
    "reallyChangedTyresToSummer": "Have you really changed to summer tyres?",
    "reallyChangedTyresToWinter": "Have you really changed to winter tyres?",
    "yesSummerTyresAreApplied": "Yes, summer tyres are applied",
    "yesWinterTyresAreApplied": "Yes, winter tyres are applied",
    "reallyFleetOutCar": "Do you really want to fleet out this car? This means, that the car is no longer available for bookings.",
    "yesFleetOutCar": "Yes, fleet out car",
    "editCar": "Edit Car",
    "infoBoxTexts": {
      "merchantConsultation": "Here are all ordered cars that will be delivered within the next 6 weeks according to the purchase contract. So check if something has changed.",
      "carIncoming": "Here are all ordered cars that will be delivered within the next 10 days according to the purchase contract. So organize the registration quickly, consult with accounting whether the car will be paid for/financed and organize the delivery to the right rental hub.",
      "inspection": "Here are all cars that need to be inspected within the next 5,000km at the latest",
      "tuv": "Here are all cars for which the TÜV is now due!",
      "tyres": "Here are all cars for which the tyres/wheels have to be changed. From October to the end of March these are all cars that are still on summer tyres and from April to the end of September all cars that are still equipped with winter tyres. (Some cars have special regulations and stay on winter tyres all year round. These are therefore not displayed here. The special regulation can be set in the Vehicle Type)",
      "sell": "Here are all cars that have reached 60,000 km or are 2 years old. This does not mean that the cars have to be sold immediately, but is intended as a hint to decide now whether the vehicle should be sold or not."
    }
  },
  "de": {
    "vehiclePoolManagement": "Fuhrparkmanagement",
    "addVehicleType": "+ Vehicle Type hinzufügen",
    "addCar": "+ Auto hinzufügen",
    "addGhostCar": "+ Ghostcar hinzufügen",
    "search": "Vehicle Type suchen",
    "station": "Station",
    "allStations": "Alle Stationen",
    "somethingWentWrong": "Etwas ist schief gelaufen",
    "searchForLicencePlateFin": "Auto suchen (Kennzeichen/FIN)",
    "showFilters": "Filter anzeigen",
    "showTodos": "Todos anzeigen",
    "overview": "Übersicht",
    "ordered": "Bestellt",
    "floatIn": "Eingeflottet",
    "floatOut": "Ausgeflottet",
    "ghostCars": "Ghostcars",
    "merchantConsultation": "Händler Rücksprache",
    "carIncoming": "Im Anflug",
    "inspectionDate": "Inspektionstermin",
    "tuvDate": "TÜV Termin",
    "tyres": "Reifen",
    "sell": "Verkaufen",
    "noVehicleTypeFound": "Keine Vehicle Types gefunden",
    "noCarFound": "Keine Autos gefunden",
    "changedToSummerTyres": "Auf Sommerreifen gewechselt bestätigen",
    "changedToWinterTyres": "Auf Winterreifen gewechselt bestätigen",
    "confirmMerchantConsultation": "Händler Rücksprache bestätigen",
    "confirmRegistration": "Anmeldung bestätigen",
    "enterInspectionAppointmentDate": "Inspektionstermin eintragen",
    "enterTuvAppointmentDate": "TÜV Termin eintragen",
    "fleetOutCar": "Auto ausflotten",
    "reallyChangedTyresToSummer": "Hast du wirklich auf Sommerreifen gewechselt?",
    "reallyChangedTyresToWinter": "Hast du wirklich auf Winterreifen gewechselt?",
    "yesSummerTyresAreApplied": "Ja, Sommerreifen wurden angebracht",
    "yesWinterTyresAreApplied": "Ja, Winterreifen wurden angebracht",
    "reallyFleetOutCar": "Möchtest du dieses Auto wirklich ausflotten? Das bedeutet, dass das Auto nicht mehr für Buchungen zur Verfügung steht.",
    "yesFleetOutCar": "Ja, Auto ausflotten",
    "editCar": "Auto bearbeiten",
    "infoBoxTexts": {
      "merchantConsultation": "Hier befinden sich alle bestellten Autos die laut Kaufvertrag innerhalb der nächsten 6 Wochen geliefert werden. Also prüfe nach ob sich da nicht was geändert hat.",
      "carIncoming": "Hier befinden sich alle bestellten Autos die laut Kaufvertrag innerhalb der nächsten 10 Tage geliefert werden. Also organisiere jetzt schnell die Zulassung, halte Rücksprache mit Accounting ob das Auto auch bezahlt/finanziert wird und organisiere die Anlieferung an den richtigen Rental Hub.",
      "inspection": "Hier befinden sich alle Autos die in spätestens 5.000km zur Inspektion müssen",
      "tuv": "Hier befinden sich alle Autos bei denen der TÜV ab jetzt fällig ist!",
      "tyres": "Hier befinden sich alle Autos bei denen die Reifen/Räder gewechselt werden müssen. Von Oktober bis Ende März sind das alle Autos die noch auf Sommerreifen stehen und von April bis Ende September alle die noch mit Winterreifen ausgestattet sind. (Manche Autos haben spezielle Regelungen und bleiben das ganze Jahr auf Winterreifen. Diese werden dementsprechend hier nicht angezeigt. Die Sonderregelung kann man im Vehicle Type einstellen)",
      "sell": "Hier befinden sich alle Autos die entweder 60.000 km erreicht haben oder 2 Jahre alt sind. Das heißt nicht, dass die Autos sofort verkauft werden müssen, sondern ist als Hinweis gedacht, damit man jetzt darüber entscheiden kann, ob das Fahrzeug nun verkauft werden soll oder nicht."
    }
  }
}
</i18n>
