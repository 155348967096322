<template>
  <div class="relative">
    <h2>{{ t('depositCreditcard') }}</h2>

    <div class="mt-4 grid w-4/5 grid-cols-2 gap-x-6 lg:w-2/3">
      <LabeledText :label="t('openRentalFee')"
        ><span v-currency="openRentalFee"></span
      ></LabeledText>
      <LabeledText :label="t('deposit')"><span v-currency="booking.deposit"></span></LabeledText>
    </div>

    <Divider class="col-span-3" />

    <PaymentOptions
      v-if="mode === Mode.BLOCK"
      :booking="booking"
      :amount="totalAmount"
      :is-saving="isSavingBooking"
      mode="block"
      @charge-credit-card-via-terminal="blockCreditCardViaTerminal"
      @charge-from-saved-credit-card="blockOnSavedCreditCard"
      @send-payment-link="sendBlockingPaymentLink"
      @continue-without-transaction="continueWithoutTransaction"
    />

    <div v-if="mode === Mode.CHARGE">
      <p class="mb-4">{{ t('depositBlockedButOpenFee') }}</p>

      <PaymentOptions
        :booking="booking"
        :amount="openRentalFee"
        :is-saving="isSavingBooking"
        mode="charge"
        @charge-credit-card-via-terminal="chargeCreditCardViaTerminal"
        @charge-from-saved-credit-card="chargeFromSavedCreditCard"
        @send-payment-link="sendPaymentLink"
        @continue-without-transaction="continueWithoutTransaction"
      />
    </div>

    <div v-if="mode === Mode.CHARGED">
      <p class="mb-4">
        {{ t('depositIsAlreadyBlocked') }}
      </p>

      <div class="flex justify-end">
        <p class="col-start-3 mr-2 grow-0 text-right">
          <span class="text-lg font-medium"> {{ t('reservedAmount') }}: </span>
          <span v-currency="booking.blockedCreditCardAmount" class="block text-2xl font-medium" />
        </p>
      </div>

      <div class="mt-5 flex justify-end">
        <ButtonNext @click.prevent="goToNextPage">
          {{ t('next') }}
        </ButtonNext>
      </div>
    </div>

    <div
      v-if="
        isInitiatingTerminal ||
        isWaitingForTransaction ||
        isChargingRemainingBookingamount ||
        isChargingRemainingBookingamountFromCreditCard ||
        isBlockingAmount
      "
      class="absolute -inset-8 flex items-center justify-center bg-black/20"
    >
      <div class="flex items-center gap-2 rounded-full bg-accent px-3 py-2">
        <p class="text-xl">{{ t(waitingForTransactionMessage) }}</p>
        <Spinner class="h-7 w-7" />
      </div>
      <CVButton
        v-if="isWaitingForPaymentLink"
        variant="error"
        class="text-link absolute right-8 top-8 text-red-600"
        @click.prevent="cancelWaitForPaymentLinkCompletion"
      >
        {{ t('cancelPaymentLink') }}<XMarkIcon class="h-4 w-4" />
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import LabeledText from '@/components/LabeledText.vue';
import Divider from '@/components/Divider.vue';
import type { Booking } from '@/entities/bookings/booking.entity';
import { useI18n } from 'vue-i18n';
import { computed, onUnmounted, ref } from 'vue';
import ButtonNext from '@/components/buttons/ButtonNext.vue';
import { useRoute, useRouter } from 'vue-router';
import PaymentOptions from './PaymentOptions.vue';
import type { ChargeCreditCardNotificationEvent } from '@/entities/payment-terminals/charge-credit-card-notification-event';
import {
  useChargeCreditCard,
  useChargeRemainingBookingAmountFromCreditCard,
} from '@/queries/use-payment-terminals';
import { TerminalTransactionType } from '@/entities/payment-terminals/terminal-transaction-type.enum';
import { paymentTerminalEventsService } from '@/api/payment-terminal-events.service';
import { ChargeCreditCardStatus } from '@/entities/payment-terminals/charge-credit-card-status.enum';
import { createToast } from 'mosha-vue-toastify';
import { useBlockAmount, useChargeRemainingBookingAmount } from '@/queries/use-payment-api';
import { AutoChargeType } from '@/entities/payment-terminals/auto-charge-type.enum';
import { useUpdateBooking } from '@/queries/use-bookings';
import { DateTime } from 'luxon';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { CreditCard } from '@/entities/payment-terminals/credit-card.enum';

enum Mode {
  BLOCK = 'BLOCK',
  CHARGE = 'CHARGE',
  CHARGED = 'CHARGED',
}

const props = defineProps<{
  booking: Booking;
  isSaving?: boolean;
}>();

const { t } = useI18n();

const { mutateAsync: updateBooking, isPending: isSavingBooking } = useUpdateBooking();

const waitingForTransactionMessage = ref('');
const isWaitingForTransaction = ref(false);
const isWaitingForPaymentLink = ref(false);

const openRentalFee = computed(() =>
  props.booking.agent ? 0 : props.booking.currentBalance ? -props.booking.currentBalance : 0,
);
const totalAmount = computed(
  () => (props.booking.deposit ?? 0) + Math.max(openRentalFee.value ?? 0, 0),
);

const mode = computed(() => {
  if (!props.booking.blockedCreditCardDate) {
    return Mode.BLOCK;
  } else if (!props.booking.blockedCreditCardIncludesOpenFee && openRentalFee.value > 0) {
    return Mode.CHARGE;
  } else {
    return Mode.CHARGED;
  }
});

const { mutateAsync: chargeCreditCard, isPending: isInitiatingTerminal } = useChargeCreditCard();

const {
  mutateAsync: chargeRemainingBookingAmountFromCreditCard,
  isPending: isChargingRemainingBookingamountFromCreditCard,
} = useChargeRemainingBookingAmountFromCreditCard();

const { mutateAsync: chargeRemainingBookingAmount, isPending: isChargingRemainingBookingamount } =
  useChargeRemainingBookingAmount();

const { mutateAsync: blockAmount, isPending: isBlockingAmount } = useBlockAmount();

const eventsServiceMessages: ChargeCreditCardNotificationEvent[] = [];

let transactionRefNr = '';
const blockCreditCardViaTerminal = async (paymentTerminalId: string, amount: number) => {
  chargeCreditCardViaTerminal(paymentTerminalId, amount, TerminalTransactionType.BLOCK);
};

const chargeCreditCardViaTerminal = async (
  paymentTerminalId: string,
  amount: number,
  type = TerminalTransactionType.CHARGE,
) => {
  if (!paymentTerminalId) return;
  waitingForTransactionMessage.value = 'connecting';
  const res =
    type === TerminalTransactionType.CHARGE
      ? await chargeRemainingBookingAmountFromCreditCard({
          paymentTerminalId: paymentTerminalId,
          bookingId: props.booking.id,
        })
      : await chargeCreditCard({
          amount: amount,
          paymentTerminalId: paymentTerminalId,
          bookingId: props.booking.id,
          type,
        });
  transactionRefNr = res.refNr;
  isWaitingForTransaction.value = true;
  eventsServiceMessages.forEach((event: ChargeCreditCardNotificationEvent) =>
    reactToEventsServiceMessages(event),
  );
};

paymentTerminalEventsService.open().subscribe({
  next(event) {
    eventsServiceMessages.push(event);
    isWaitingForTransaction.value && reactToEventsServiceMessages(event);
  },
  error(err) {
    console.error('something wrong occurred: ' + err);
  },
});

const reactToEventsServiceMessages = async (event: ChargeCreditCardNotificationEvent) => {
  // if payment terminal is not the one we are waiting for, ignore
  // Use transactionId in the future
  if (event.refNr === transactionRefNr) {
    switch (event.status) {
      case ChargeCreditCardStatus.REQUESTING:
        waitingForTransactionMessage.value = 'followInstructionsOnTerminal';
        break;
      case ChargeCreditCardStatus.REQUESTED:
        waitingForTransactionMessage.value = 'transactionIsCompleted';
        break;
      case ChargeCreditCardStatus.SUCCESS:
        isWaitingForTransaction.value = false;
        await updateBooking({
          id: props.booking.id,
          booking: { blockedCreditCardIncludesOpenFee: true },
        });
        goToNextPage();
        break;
      case ChargeCreditCardStatus.FAILED:
        isWaitingForTransaction.value = false;
        createToast(t('transactionCanceled'), {
          position: 'bottom-right',
          type: 'danger',
        });
    }
  }
};

onUnmounted(() => paymentTerminalEventsService.close());

const blockOnSavedCreditCard = async (amount: number, type = AutoChargeType.PSEUDO_NUMBER) => {
  waitingForTransactionMessage.value =
    type === AutoChargeType.PSEUDO_NUMBER
      ? 'blockingAmountOnSavedCreditCard'
      : 'sendingPaymentLink';
  const res = await blockAmount({
    amount,
    bookingId: props.booking.id,
    types: [type],
  });
  if (res.paymentLink) {
    transactionRefNr = res.paymentLink.refNr;
    isWaitingForTransaction.value = true;
    waitingForTransactionMessage.value = 'waitingForPaymentLinkCompletion';
  }
  if (res.dueAmount === 0) {
    await updateBooking({
      id: props.booking.id,
      booking: { blockedCreditCardIncludesOpenFee: true },
    });
    goToNextPage();
  }
};

const chargeFromSavedCreditCard = async (amount: number, type = AutoChargeType.PSEUDO_NUMBER) => {
  waitingForTransactionMessage.value =
    type === AutoChargeType.PSEUDO_NUMBER
      ? 'chargingAmountOnSavedCreditCard'
      : 'sendingPaymentLink';
  const res = await chargeRemainingBookingAmount({
    bookingId: props.booking.id,
    types: [type],
    creditCardToUse: CreditCard.ONLINE_PAYMENT,
  });
  if (res.paymentLink) {
    transactionRefNr = res.paymentLink.refNr;
    isWaitingForTransaction.value = true;
    waitingForTransactionMessage.value = 'waitingForPaymentLinkCompletion';
  }
  if (res.dueAmount === 0) {
    goToNextPage();
  }
};

const cancelWaitForPaymentLinkCompletion = () => {
  isWaitingForTransaction.value = false;
  isWaitingForPaymentLink.value = false;
  transactionRefNr = '';
};

const sendBlockingPaymentLink = (amount: number) => {
  blockOnSavedCreditCard(amount, AutoChargeType.PAYMENT_LINK);
  isWaitingForPaymentLink.value = true;
};

const sendPaymentLink = (amount: number) => {
  chargeFromSavedCreditCard(amount, AutoChargeType.PAYMENT_LINK);
  isWaitingForPaymentLink.value = true;
};

const continueWithoutTransaction = async (amount: number) => {
  if (mode.value === Mode.BLOCK) {
    await updateBooking({
      id: props.booking.id,
      booking: {
        blockedCreditCardAmount: amount,
        blockedCreditCardDate: DateTime.now().toISODate(),
        blockedCreditCardIncludesOpenFee: true,
      },
    });
  } else if (mode.value === Mode.CHARGE) {
    await updateBooking({
      id: props.booking.id,
      booking: {
        blockedCreditCardIncludesOpenFee: true,
      },
    });
  }
  goToNextPage();
};

const route = useRoute();
const router = useRouter();

const goToNextPage = () => {
  router.push({
    name: 'handoverCarStatus',
    params: { id: route.params.id },
  });
};
</script>

<i18n lang="json">
{
  "en": {
    "depositCreditcard": "Deposit Credit Card",
    "openRentalFee": "Open Rental Fee",
    "deposit": "Deposit",
    "connecting": "Connecting",
    "followInstructionsOnTerminal": "Please follow instructions on terminal",
    "transactionIsCompleted": "Completing transaction",
    "transactionCanceled": "The transaction was canceled. Please try again, if this was not intended.",
    "depositIsAlreadyBlocked": "The total amount of deposit and open rental price has already been blocked on the credit card.",
    "depositBlockedButOpenFee": "The deposit has been blocked on the credit card, but a (partial) rent fee is still open.",
    "reservedAmount": "Reserved Amount",
    "blockingAmountOnSavedCreditCard": "Blocking amount on saved credit card",
    "sendingPaymentLink": "Sending payment link",
    "waitingForPaymentLinkCompletion": "Waiting for payment link completion",
    "chargingAmountOnSavedCreditCard": "Charging amount on saved credit card",
    "cancelPaymentLink": "Cancel payment link"
  },
  "de": {
    "depositCreditcard": "Kreditkarte hinterlegen",
    "openRentalFee": "Noch offener Mietbetrag",
    "deposit": "Kaution",
    "connecting": "Verbindung wird aufgebaut",
    "followInstructionsOnTerminal": "Bitte folge den Anweisungen auf dem Terminal",
    "transactionIsCompleted": "Transaktion wird abgeschlossen",
    "transactionCanceled": "Die Transaktion wurde abgebrochen. Bitte versuche es nochmal, falls das nicht beabsichtigt war.",
    "depositIsAlreadyBlocked": "Der Gesamtbetrag aus Kaution und offenem Mietpreis wurde bereits auf der Kreditkarte blockiert.",
    "depositBlockedButOpenFee": "Die Kaution wurde auf der Kreditkarte blockiert, aber ein (Teil-)Mietbetrag ist noch offen.",
    "reservedAmount": "Reservierter Betrag",
    "blockingAmountOnSavedCreditCard": "Betrag auf hinterlegter Kreditkarte blockieren",
    "sendingPaymentLink": "Zahlungslink wird gesendet",
    "waitingForPaymentLinkCompletion": "Warte auf Zahlungslink Abschluss",
    "chargingAmountOnSavedCreditCard": "Betrag wird auf hinterlegter Kreditkarte eingezogen",
    "cancelPaymentLink": "Zahlungslink abbrechen"
  }
}
</i18n>
