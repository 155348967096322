<template>
  <div class="flex flex-row items-center justify-between">
    <h2 :class="{ 'mb-4': addAgent || booking.agent }">
      {{ t('chooseAgent') }}
    </h2>
    <div v-if="!booking.agent" class="flex flex-col space-y-2">
      <CVButton v-if="!addAgent" @click.prevent="addAgent = true">{{ t('addAgent') }}</CVButton>
    </div>
  </div>
  <div v-if="addAgent" class="relative flex flex-col space-y-2">
    <p class="mb-2">{{ t('addAgentWarning', { days: daysBeforeRentalAgencyInvoiceGetsSent }) }}</p>
    <p class="mb-4 font-medium">{{ t('addAgentWarning2') }}</p>
    <Select v-model="chosenId" :options="options" placeholder="Agent" class="mb-4 max-w-fit" />
    <div class="flex space-x-2">
      <CVButton
        variant="success"
        :disabled="!chosenId"
        :is-loading="isSaving"
        @click.prevent="saveAgent"
      >
        {{ t('save') }}
      </CVButton>
      <CVButton
        variant="warning"
        :is-loading="isSaving"
        @click.prevent="
          chosenId = '';
          addAgent = false;
        "
      >
        {{ t('cancel') }}
      </CVButton>
    </div>
    <div
      v-if="isLoadingAgents"
      class="absolute -inset-2 flex items-center justify-center bg-black/20"
    >
      <Spinner />
    </div>
  </div>
  <div v-else-if="booking.agent" class="flex flex-col space-y-2">
    <p>{{ t('agentLinked') }}</p>
    <p v-if="!agentInvoiceExists">{{ t('invoiceCreatedTime') }} {{ invoiceCreationTime }}</p>
    <p v-else class="text-success">{{ t('agentInvoiceExists') }}</p>
    <div class="flex items-end justify-between">
      <LabeledText :label="t('linkedAgent')">{{
        `${booking.agent.firstName} ${booking.agent.lastName}${
          booking.agent.company ? ', ' + booking.agent.company : ''
        }, ${t(`agentTypes.${booking.agent.agentType}`)}`
      }}</LabeledText>
      <div v-if="!isLoadingInvoices">
        <CVButton
          v-if="!agentInvoiceExists"
          variant="error"
          outline
          :is-loading="isSaving"
          @click.prevent="removeAgent"
          >{{ t('removeAgentFromBooking') }}</CVButton
        >
        <p v-else class="italic text-black/20">
          {{ t('agentCannotBeRemoved') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import CVButton from '@/components/buttons/CVButton.vue';
import { useUsersWithQuery } from '@/queries/use-users';
import { UserRole } from '@/entities/auth/user-role.enum';
import { computed, type ComputedRef, ref } from 'vue';
import { flattenPaginatedData, useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import type { User } from '@/entities/auth/user.entity';
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import LabeledText from '@/components/LabeledText.vue';
import { Alert } from '@/utils/alert';
import { DateTime } from 'luxon';
import { useFormatedDateString } from '@/hooks/use-formated-date';
import { getDateFormat } from '@/hooks/use-date-format';
import { useInvoicesWithQuery } from '@/queries/use-invoices';
import Select from '@/components/Select.vue';

const { t } = useI18n();
const props = defineProps<{
  booking: Booking;
  isSaving: boolean;
}>();

const emit = defineEmits<{
  (
    e: 'update-booking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished: () => void,
  ): void;
}>();

const daysBeforeRentalAgencyInvoiceGetsSent = 7;

const { data: agentsData, isLoading: isLoadingAgents } = useUsersWithQuery({
  role: [UserRole.AGENT],
  limit: 500,
});
const agents: ComputedRef<User[]> = useFlattenPaginatedData(agentsData);

const { data: invoicesData, isLoading: isLoadingInvoices } = useInvoicesWithQuery({
  filter: [{ bookingIds: [props.booking.id] }],
});

const agentInvoiceExists = computed(() =>
  flattenPaginatedData(invoicesData).some((invoice) => invoice.customer?.role === UserRole.AGENT),
);

const options = computed(() =>
  agents.value.map((user) => {
    return {
      label: `${user.firstName} ${user.lastName}${user.company ? ', ' + user.company : ''}`,
      value: user.id,
    };
  }),
);

const addAgent = ref<boolean>(false);
const chosenId = ref('');
const invoiceCreationTime = useFormatedDateString(
  DateTime.fromISO(props.booking.startDate)
    .minus({
      days: daysBeforeRentalAgencyInvoiceGetsSent,
    })
    .toISO(),
  getDateFormat(),
);

const saveAgent = async () => {
  const { isConfirmed } = await Alert.fire({
    text: t('finalAddAgentWarning'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('link'),
    cancelButtonText: t('cancel'),
  });
  if (isConfirmed) {
    const updateBookingValues: UpdateBookingDto = {
      agentId: chosenId.value,
    };
    emit(
      'update-booking',
      {
        id: props.booking.id,
        booking: updateBookingValues,
      },
      () => {
        addAgent.value = false;
      },
    );
  }
};

const removeAgent = async () => {
  const { isConfirmed } = await Alert.fire({
    text: t('removeAgentWarning'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('removeAgent'),
    cancelButtonText: t('cancel'),
  });
  if (isConfirmed) {
    const updateBookingValues: UpdateBookingDto = {
      agentId: null,
    };
    emit(
      'update-booking',
      {
        id: props.booking.id,
        booking: updateBookingValues,
      },
      () => {
        addAgent.value = false;
      },
    );
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "chooseAgent": "Partner/Agent",
    "addAgentWarning": "Attention: If a partner/agent is linked, the rental invoice is addressed to this agent. The invoice will be automatically generated and sent {days} days before the start of the rental. The link can be undone only until this happens.",
    "addAgentWarning2": "Attention: Please always add the extras cross border and additional driver for 0 € beforehand, as this is always included with the partners/agents. Reduce the rental price accordingly so the total price fits!",
    "addAgent": "+ Add Partner/Agent",
    "linkedAgent": "Linked Partner/Agent",
    "link": "link",
    "agentLinked": "This booking is linked to an partner/agent. The invoice with the rental amount and extras will be sent to this partner/agent. Only additional costs will be charged to the tenant.",
    "invoiceCreatedTime": "The invoice will be generated automatically on:",
    "agentInvoiceExists": "The partner/agent invoice has already been created and sent.",
    "finalAddAgentWarning": "Really link booking to partner/agent?",
    "removeAgentFromBooking": "Remove partner/agent from booking",
    "removeAgentWarning": "Do you really want to remove the partner/agent from the booking? The rental invoice will be addressed and sent to the customer instead of the partner/agent at the end of the rent.",
    "removeAgent": "Remove partner/agent",
    "agentCannotBeRemoved": "Partner/Agent cannot be removed"
  },
  "de": {
    "chooseAgent": "Partner/Vermittler",
    "addAgentWarning": "Achtung: Wenn ein Partner/Vermittler verknüpft ist, wird die Mietrechnung an diesen adressiert. Die Rechnung wird {days} Tage vor Mietbeginn automatisch generiert und verschickt. Die Verknüpfung kann nur bis zu diesem Zeitpunkt aufgehoben werden.",
    "addAgentWarning2": "Achtung: Bitte immer die Extras Auslandsfahrt und Zusatzfahrer für 0 € vorher hinzufügen, da dies bei den Partnern/Vermittlern immer enthalten ist. Den Mietpreis entsprechend reduzieren, damit der Gesamtpreis passt!",
    "addAgent": "+ Partner/Vermittler hinzufügen",
    "linkedAgent": "Verknüpfter Partner/Vermittler",
    "link": "verknüpfen",
    "agentLinked": "Diese Buchung ist mit einem Partner/Vermittler verknüpft. Die Rechnung mit dem Mietbetrag und den Extras wird an diesen verschickt. Lediglich zusätzlich anfallende Kosten werden dem Mieter in Rechnung gestellt.",
    "invoiceCreatedTime": "Die Rechnung wird voraussichtlich automatisch generiert am:",
    "agentInvoiceExists": "Die Partner/Vermittlerrechnung wurde bereits erstellt und verschickt.",
    "finalAddAgentWarning": "Buchung wirklich mit Partner/Vermittler verknüpfen?",
    "removeAgentFromBooking": "Partner/Vermittler von Buchung entfernen",
    "removeAgentWarning": "Möchtest du die Verlinkung mit dem Partner/Vermittler wirklich auflösen? Die Mietrechnung wird dann nicht mehr an den Partner/Vermittler, sondern am Ende der Miete an den Kunden adressiert und verschickt werden.",
    "removeAgent": "Partner/Vermittler entfernen",
    "agentCannotBeRemoved": "Partner/Vermittler kann nicht mehr entfernt werden"
  }
}
</i18n>
