<template>
  <h2>{{ t('otherConditions') }}</h2>
  <div class="mt-4 grid grid-cols-3 gap-4">
    <InputField
      name="deposit"
      type="number"
      :label="t('deposit')"
      :max-fraction-digits="2"
      :min="0"
      adornment="€"
      :disabled="!$can('update', subject('Booking', booking), 'deposit')"
    />
    <InputField
      name="pricePerExtraKm"
      type="number"
      :label="t('pricePerExtraKm')"
      :max-fraction-digits="2"
      :min="0"
      adornment="€"
      :disabled="!$can('update', subject('Booking', booking), 'pricePerExtraKm')"
    />
  </div>
  <div v-if="hasChanged" class="mt-4 flex justify-end gap-4">
    <CVButton variant="success" :is-loading="isSaving" @click.prevent="onSubmit">{{
      t('save')
    }}</CVButton>
    <CVButton variant="warning" :disabled="isSaving" @click.prevent="onCancel">{{
      t('cancel')
    }}</CVButton>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import InputField from '@/components/InputField.vue';
import type { Booking, UpdateBookingDto } from '@/entities/bookings/booking.entity';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import { ref, watch } from 'vue';
import { cloneDeep } from 'lodash';
import { useFormHasChanged } from '@/hooks/use-form-has-changed';
import { subject } from '@casl/ability';

const { t } = useI18n();

const props = defineProps<{
  booking: Booking;
  isSaving: boolean;
}>();

const emit = defineEmits<{
  (
    e: 'updateBooking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished?: () => void,
  ): void;
  (e: 'hasChanged', value: boolean): void;
}>();

const onCancel = () => {
  setValues({
    deposit: props.booking.deposit ?? undefined,
    pricePerExtraKm: props.booking.pricePerExtraKm,
  });
};

const {
  handleSubmit,
  values: formValues,
  setValues,
} = useForm({
  initialValues: {
    deposit: props.booking.deposit ?? undefined,
    pricePerExtraKm: props.booking.pricePerExtraKm,
  },
  validationSchema: yup.object({
    deposit: yup.number().required(),
    pricePerExtraKm: yup.number().required(),
  }),
});

const unchangedValues = ref(cloneDeep(formValues));

const onSubmit = handleSubmit((values) => {
  emit(
    'updateBooking',
    {
      id: props.booking.id,
      booking: values,
    },
    () => (unchangedValues.value = cloneDeep(formValues)),
  );
});

const hasChanged = useFormHasChanged(unchangedValues, formValues);
watch(hasChanged, () => emit('hasChanged', hasChanged.value));
</script>

<i18n lang="json">
{
  "en": {
    "otherConditions": "Other Conditions",
    "deposit": "Deposit",
    "pricePerExtraKm": "Price per extra km"
  },
  "de": {
    "otherConditions": "Sonstige Konditionen",
    "deposit": "Kaution",
    "pricePerExtraKm": "Preis pro Extra Km"
  }
}
</i18n>
