<template>
  <Modal v-model="showModal" :close-on-click-outside="false">
    <div class="mb-4 flex justify-end">
      <a
        href="#"
        class="text-red-600 underline hover:no-underline"
        @click.prevent="$emit('update:modelValue', false)"
        >{{ t('close') }} [x]</a
      >
    </div>
    <div v-if="showModal && car">
      <OverviewAndExtras
        v-if="activePage === Page.OVERVIEW_AND_EXTRAS"
        :car="car"
        :pickup-location="pickupLocation"
        :dropoff-location="dropoffLocation"
        :start-date="startDate"
        :end-date="endDate"
        :rent-duration="rentDuration"
        :transfer-delivery-price="transferDeliveryPrice"
        :transfer-return-price="transferReturnPrice"
        :total-price="totalPrice"
        :booked-extras-map="bookedExtrasMap"
        :is-agency-booking="isAgencyBooking"
        :pricing="pricing"
        @update-pricing="pricing = $event"
        @update-booked-extras-map="bookedExtrasMap = $event"
        @update-is-agency-booking="isAgencyBooking = $event"
        @continue="continueFromOverviewAndExtras"
      />
      <AddAgency
        v-if="activePage === Page.ADD_AGENCY"
        v-model:agency="agency"
        :total-price="totalPrice"
        @continue="activePage = Page.ADD_CUSTOMER"
        @back="activePage = Page.OVERVIEW_AND_EXTRAS"
      />
      <AddCustomer
        v-if="activePage === Page.ADD_CUSTOMER"
        v-model:customer="customer"
        v-model:customer-id="customerId"
        v-model:customer-email="customerEmail"
        :total-price="totalPrice"
        @continue="activePage = Page.CUSTOMER_DATA"
        @back="backFromAddCustomer"
      />
      <CustomerData
        v-if="activePage === Page.CUSTOMER_DATA"
        v-model:customer-data="customerData"
        :customer-email="customerEmail"
        :agency="agency"
        :total-price="totalPrice"
        :start-date="startDate"
        :end-date="endDate"
        :rent-duration="rentDuration"
        :pickup-location="pickupLocation"
        :dropoff-location="dropoffLocation"
        :total-base-price="totalBasePrice"
        :transfer-delivery-price="transferDeliveryPrice"
        :transfer-return-price="transferReturnPrice"
        :car="car"
        :booked-extras-map="bookedExtrasMap"
        :is-loading="isLoading"
        @back="activePage = Page.ADD_CUSTOMER"
        @continue="createCustomerAndBooking"
      />
    </div>
    <div v-else class="flex justify-center py-12">
      <Spinner />
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from '@/components/Modal.vue';
import { computed, ref, toRef, watch, watchEffect } from 'vue';
import OverviewAndExtras from './modal-forms/overview-and-extras/OverviewAndExtras.vue';
import { useCar } from '@/queries/use-cars';
import {
  AdditionalDriver,
  type BookingLocation,
  type CreateBookingDto,
} from '@/entities/bookings/booking.entity';
import type { Pricing } from '@/entities/pricing.entity';
import AddAgency from './modal-forms/add-agency/AddAgency.vue';
import AddCustomer from './modal-forms/add-customer/AddCustomer.vue';
import type { CreateCustomerUserDto, User } from '@/entities/auth/user.entity';
import type { CustomerDataDto } from '@/entities/bookings/customer-data.dto';
import CustomerData from './modal-forms/customer-data/CustomerData.vue';
import { Gender } from '@/entities/auth/gender.enum';
import { useCreateCustomerUser } from '@/queries/use-users';
import { useCreateBooking, useCreateBookingAsAgent } from '@/queries/use-bookings';
import { BookingType } from '@/entities/bookings/booking-type.enum';
import { VehicleExtraType } from '@/entities/vehicle-extra-type.enum';
import { Alert } from '@/utils/alert';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import { Local } from '@/entities/auth/local.enum';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import { useAuthStore } from '@/stores/auth.store';
import { UserRole } from '@/entities/auth/user-role.enum';

enum Page {
  OVERVIEW_AND_EXTRAS = 'OVERVIEW_AND_EXTRAS',
  ADD_AGENCY = 'ADD_AGENCY',
  ADD_CUSTOMER = 'ADD_CUSTOMER',
  CUSTOMER_DATA = 'CUSTOMER_DATA',
}

const props = defineProps<{
  modelValue: boolean;
  carId: string;
  pickupLocation: BookingLocation;
  dropoffLocation: BookingLocation;
  startDate: Date;
  endDate: Date;
  rentDuration: number;
  transferDeliveryPrice?: number | null;
  transferReturnPrice?: number | null;
  totalBasePrice: number;
  totalPrice: number;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const { t } = useI18n();
const authStore = useAuthStore();

const activePage = ref(Page.OVERVIEW_AND_EXTRAS);
const bookedExtrasMap = ref<Record<string, string>>({});
const isAgencyBooking = ref(false);
const pricing = ref<Pricing | null>(null);
const agency = ref<User | null>(null);
const customer = ref<User | null>(null);
const customerId = ref<string | null>(null);
const customerEmail = ref<string | null>(null);
const customerData = ref<CustomerDataDto>({ preferredLocal: Local.EN_US });

const totalPrice = computed(() => {
  return pricing.value?.totalPrice ?? props.totalPrice ?? 0;
});

const { data: car } = useCar(toRef(props, 'carId'));

const { mutateAsync: createUser, isPending: isCreatingUser } = useCreateCustomerUser();
const { mutateAsync: createBooking, isPending: isCreatingBooking } = useCreateBooking();
const { mutateAsync: createBookingAsAgent, isPending: isCreatingBookingAsAgent } =
  useCreateBookingAsAgent();

const isLoading = computed(
  () => isCreatingUser.value || isCreatingBooking.value || isCreatingBookingAsAgent.value,
);

const showModal = ref(props.modelValue);
watchEffect(() => (showModal.value = props.modelValue));

watch(showModal, () => {
  emit('update:modelValue', showModal.value);
  if (showModal.value) {
    activePage.value = Page.OVERVIEW_AND_EXTRAS;
    bookedExtrasMap.value = {};
    isAgencyBooking.value = false;
    pricing.value = null;
    agency.value = null;
    customer.value = null;
    customerId.value = null;
    customerEmail.value = null;
    customerData.value = { preferredLocal: Local.EN_US };
  }
});

watch(customer, () => {
  customerData.value = customer.value
    ? {
        firstName: customer.value.firstName,
        lastName: customer.value.lastName,
        company: customer.value.company ?? undefined,
        gender: customer.value.gender,
        dateOfBirth: customer.value.dateOfBirth ?? undefined,
        phone: customer.value.phone ?? undefined,
        street: customer.value.street ?? undefined,
        city: customer.value.city ?? undefined,
        zip: customer.value.zip ?? undefined,
        country: customer.value.country ?? undefined,
        preferredLocal: customer.value.preferredLocal,
      }
    : { preferredLocal: Local.EN_US };
});

const continueFromOverviewAndExtras = () => {
  if (isAgencyBooking.value) {
    activePage.value = Page.ADD_AGENCY;
  } else {
    activePage.value = Page.ADD_CUSTOMER;
    agency.value = null;
  }
};

const backFromAddCustomer = () => {
  if (isAgencyBooking.value) {
    activePage.value = Page.ADD_AGENCY;
  } else {
    activePage.value = Page.OVERVIEW_AND_EXTRAS;
  }
};

const createCustomerAndBooking = async () => {
  if (!pricing.value) return;

  const isAgency = authStore.user?.role === UserRole.AGENT;

  let newCustomer: User | null = null;
  if (customerEmail.value && !customerId.value) {
    const createUserDto: CreateCustomerUserDto = {
      ...customerData.value,
      email: customerEmail.value,
      firstName: customerData.value.firstName ?? '',
      lastName: customerData.value.lastName ?? '',
      gender: customerData.value.gender ?? Gender.MALE,
      preferredLocal: customerData.value.preferredLocal ?? Local.EN_US,
    };
    newCustomer = await createUser(createUserDto);
    customerId.value = newCustomer.id;
  }

  const additionalDriversExtra = car.value?.vehicleType.availableExtras.find(
    (extra) => extra.type === VehicleExtraType.ADDITIONAL_DRIVER,
  );
  const optionId = additionalDriversExtra ? bookedExtrasMap.value[additionalDriversExtra.id] : null;
  const additionalDriversCount = Number(
    additionalDriversExtra?.options.find((option) => option.id === optionId)?.value ?? 0,
  );
  const additionalDrivers = Array.from({ length: additionalDriversCount }, (_, i) => i + 1).map(
    () => new AdditionalDriver(),
  );

  const createBookingDto: CreateBookingDto = {
    type: BookingType.CUSTOMER_BOOKING,
    startDate: props.startDate,
    endDate: props.endDate,
    carId: props.carId,
    pickupLocationType: props.pickupLocation.locationType,
    pickupStationId: props.pickupLocation.station?.id,
    pickupLocation: props.pickupLocation.location?.coordinates,
    pickupLocationGeocodedAddress: props.pickupLocation.location?.address,
    dropoffLocationType: props.dropoffLocation.locationType,
    dropoffStationId: props.dropoffLocation.station?.id,
    dropoffLocation: props.dropoffLocation.location?.coordinates,
    dropoffLocationGeocodedAddress: props.dropoffLocation.location?.address,
    bookedExtras: convertToBookedExtras(bookedExtrasMap.value),
    priceCalculationId: pricing.value?.id,
    agentId: agency.value?.id,
    customerId: customerId.value,
    customerData: customerData.value,
    additionalDrivers: additionalDrivers,
    status: BookingStatus.OPEN,
  };

  const { id, bookingNumber } = await (!isAgency
    ? createBooking(createBookingDto)
    : createBookingAsAgent(createBookingDto));

  showModal.value = false;

  const { isConfirmed } = await Alert.fire({
    titleText: t('bookingCreated'),
    text: t('bookingCreatedText', { bookingNumber }),
    icon: 'success',
    showCloseButton: true,
    confirmButtonText: !isAgency ? t('viewBooking') : t('close'),
  });

  if (isConfirmed && !isAgency) {
    router.push({ name: 'bookingEdit', params: { id } });
  }
};

const convertToBookedExtras = (bookedExtrasMap: Record<string, string>) => {
  return Object.entries(bookedExtrasMap).map(([vehicleExtraId, vehicleExtraOptionId]) => ({
    vehicleExtraId,
    vehicleExtraOptionId,
  }));
};
</script>

<i18n lang="json">
{
  "en": {
    "bookingCreated": "Booking created",
    "bookingCreatedText": "The booking number is {bookingNumber}.",
    "viewBooking": "View Booking",
    "close": "Close"
  },
  "de": {
    "bookingCreated": "Buchung erstellt",
    "bookingCreatedText": "Die Buchungsnummer lautet {bookingNumber}.",
    "viewBooking": "Buchung ansehen",
    "close": "Schließen"
  }
}
</i18n>
