<template>
  <div class="flex flex-col">
    <LabeledText :label="t('rentalDuration')"
      ><p class="py-1">{{ totalRentalDays }} {{ t('days') }}</p></LabeledText
    >
    <div v-if="showOverwriteInclusiveKm && !immutable" class="mb-2 mt-2">
      <div class="flex items-center">
        <div class="w-32">
          <InputField
            name="customInclusiveKm"
            type="number"
            :min="0"
            adornment="km"
            empty-value-to-null
            @input="hasChanged = true"
          />
        </div>
        <div class="ml-2">+ {{ currentInclusiveAndExtraKm.extraKm }} {{ t('extraKm') }} =</div>
      </div>
    </div>
    <Information
      ><template #text>{{ currentInclusiveAndExtraKm.sum + ' ' + t('inclusiveKm') }}</template
      ><template #modal>{{ t('information') }}</template></Information
    >
    <p v-if="!immutable && !hasChanged">
      <a class="text-link" href="#" @click.prevent="toggleOverwriteInclusiveKm">{{
        showOverwriteInclusiveKm ? t('useInclusiveKmOfCar') : t('overwriteInclusiveKm')
      }}</a>
    </p>
    <div v-if="hasChanged" class="mt-2 flex justify-end gap-2">
      <CVButton variant="success" :is-loading="isSaving" @click.prevent="onSubmit">
        {{ t('save') }}
      </CVButton>
      <CVButton variant="warning" :disabled="isSaving" @click.prevent="onCancel">
        {{ t('cancel') }}
      </CVButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { InclusiveKmPerDay } from '@/entities/inclusive-km-per-day.entity';
import { VehicleExtraType } from '@/entities/vehicle-extra-type.enum';
import type { VehicleExtra } from '@/entities/vehicle-extra.entity';
import { getInclusiveKm } from '@/utils/get-inclusive-km';
import { useI18n } from 'vue-i18n';
import { useField, useForm } from 'vee-validate';
import { computed, ref, watch } from 'vue';
import Information from '../Information.vue';
import InputField from '../InputField.vue';
import LabeledText from '../LabeledText.vue';
import type { Pricing } from '@/entities/pricing.entity';
import * as yup from 'yup';
import type { UpdateBookingDto } from '@/entities/bookings/booking.entity';

const props = defineProps<{
  totalRentalDays: number;
  priceCalculation: Pricing;
  inclusiveKm: InclusiveKmPerDay[];
  calculatedInclusiveKm: number | null;
  availableExtras: VehicleExtra[];
  immutable?: boolean;
  bookingId?: string;
  customInclusiveKm?: number | null;
  isSaving?: boolean;
}>();

const emit = defineEmits<{
  (
    e: 'updateBooking',
    query: { id: string; booking: UpdateBookingDto },
    onFinished?: () => void,
  ): void;
  (e: 'hasChanged', value: boolean): void;
}>();

const { t } = useI18n();

const hasChanged = ref(false);

const toggleOverwriteInclusiveKm = () => {
  hasChanged.value = true;
  showOverwriteInclusiveKm.value = props.customInclusiveKm === null;
  if (showOverwriteInclusiveKm.value) {
    customInclusiveKmValue.value = getInclusiveKm(props.inclusiveKm, props.totalRentalDays);
  } else {
    customInclusiveKmValue.value = null;
  }
};

const currentInclusiveKm = computed(
  () => customInclusiveKmValue.value ?? props.calculatedInclusiveKm, // getInclusiveKm(props.inclusiveKm, props.totalRentalDays)
);
const currentInclusiveAndExtraKm = computed(() => {
  let extraKm =
    props.priceCalculation.customBookedExtras?.find(
      (customBookedExtra) => customBookedExtra.customVehicleExtraType === VehicleExtraType.EXTRA_KM,
    )?.value ?? null;

  if (extraKm === null) {
    const extra = props.availableExtras.find((extra) => extra.type === VehicleExtraType.EXTRA_KM);
    let option = props.priceCalculation.bookedExtras.find(
      (bookedExtra) => bookedExtra.vehicleExtra.type === extra?.type,
    )?.vehicleExtraOption;
    extraKm = Number(option?.value ?? 0);
  }
  return {
    extraKm,
    sum: extraKm + (currentInclusiveKm.value ?? 0),
  };
});

const onCancel = () => {
  if (props.customInclusiveKm === undefined) return;
  customInclusiveKmValue.value = props.customInclusiveKm;
  showOverwriteInclusiveKm.value = !!props.customInclusiveKm;
  hasChanged.value = false;
};

const { handleSubmit } = useForm({
  initialValues: {
    customInclusiveKm: props.customInclusiveKm,
  },
  validationSchema: yup.object({
    customInclusiveKm: yup.number().nullable().optional(),
  }),
});

const { value: customInclusiveKmValue } = useField<number | null>('customInclusiveKm');
const showOverwriteInclusiveKm = ref(!!customInclusiveKmValue.value);

const unchangedValue = ref(customInclusiveKmValue.value);

const onSubmit = handleSubmit((values) => {
  if (!props.bookingId) return;
  emit(
    'updateBooking',
    {
      id: props.bookingId,
      booking: values,
    },
    () => {
      unchangedValue.value = customInclusiveKmValue.value;
      hasChanged.value = false;
    },
  );
});

watch(hasChanged, () => emit('hasChanged', hasChanged.value));
</script>

<i18n lang="json">
{
  "en": {
    "rentalDuration": "Rental Duration",
    "days": "Days",
    "information": "Extra kilometers already included!",
    "overwriteInclusiveKm": "Overwrite inclusive KM",
    "useInclusiveKmOfCar": "Use inclusive KM of Car",
    "extraKm": "Extra KM"
  },
  "de": {
    "rentalDuration": "Mietdauer",
    "days": "Tage",
    "information": "Extra Kilometer mit inbegriffen!",
    "overwriteInclusiveKm": " Inklusiv KM überschreiben",
    "useInclusiveKmOfCar": "Inklusiv KM des Autos nutzen",
    "extraKm": "Extra KM"
  }
}
</i18n>
