import type { LocationPoint } from '@/entities/bookings/booking.entity';
import { type MaybeRefOrGetter, toValue } from '@vueuse/core';
import { computed } from 'vue';

export const useLocationToCoordinates = (
  location: MaybeRefOrGetter<LocationPoint | null | undefined>,
) => {
  return computed(() => locationToCoordinates(toValue(location)));
};

export const locationToCoordinates = (location?: LocationPoint | null) => {
  return location
    ? {
        latitude: location.coordinates[1],
        longitude: location.coordinates[0],
      }
    : null;
};
