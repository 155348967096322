import { pricingService } from '@/api/pricing.service';
import type {
  CreateAdditionalCostsDto,
  CreatePricingDto,
  CreatePricingWithExtendedTimePeriodDto,
} from '@/entities/pricing.entity';

import { useMutation, useQuery } from '@tanstack/vue-query';
import { type MaybeRefOrGetter, toRef } from '@vueuse/core';
import type { MutationDefaultsContext } from './query-client';

const QUERY_KEY = 'pricing';

export const useRequestPricing = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (params: CreatePricingDto) => pricingService.requestPricing(params),
    onMutate: (): MutationDefaultsContext => ({ showSuccessToast: false }),
  });
};

export const useExtendTimePeriodOfPricing = () => {
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (params: CreatePricingWithExtendedTimePeriodDto) =>
      pricingService.extendTimePeriodOfPricing(params),
    onMutate: (): MutationDefaultsContext => ({ showSuccessToast: false }),
  });
};

export const useRequestAdditionalCosts = (
  params: CreateAdditionalCostsDto,
  enabled: MaybeRefOrGetter<boolean> = true,
) => {
  return useQuery({
    queryKey: [QUERY_KEY, params],
    queryFn: () => pricingService.requestAdditionalCosts(params),
    enabled: toRef(enabled),
  });
};
