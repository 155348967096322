<template>
  <div class="grid grid-cols-2 gap-x-4 gap-y-2">
    <LabeledText
      class="cursor-pointer"
      :label="startDateTitle"
      @click="showPicker = !disabled ? !showPicker : showPicker"
    >
      <span
        class="block h-8 w-full border border-primary/40 p-1 pl-1 transition duration-300 hover:border-primary"
        >{{ formattedStartDate }}</span
      >
    </LabeledText>

    <LabeledText
      class="cursor-pointer"
      :label="endDateTitle"
      @click="showPicker = !disabled ? !showPicker : showPicker"
    >
      <span
        class="block h-8 w-full border border-primary/40 p-1 pl-1 transition duration-300 hover:border-primary"
        >{{ formattedEndDate }}</span
      ></LabeledText
    >

    <div v-if="showPicker" ref="pickerContainer" class="absolute z-10 mt-[63px]">
      <Datepicker
        v-model="dates"
        range
        inline
        week-numbers
        multi-calendars
        :close-on-auto-apply="false"
        :partial-range="false"
        :disabled-dates="disabledDates"
        :month-change-on-scroll="false"
        :clearable="false"
        enable-time-picker
        :flow="['calendar', 'time']"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import LabeledText from '@/components/LabeledText.vue';
import { BookingStatus } from '@/entities/bookings/booking-status.enum';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { formatIsoDateString } from '@/hooks/use-formated-date';
import { useBookingsWithQuery } from '@/queries/use-bookings';
import { daysFromInterval } from '@/utils/utils';
import { onClickOutside } from '@vueuse/core';
import { flatMap } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { computed, ref, watchEffect } from 'vue';
import Datepicker from '@/components/Datepicker.vue';

const props = defineProps<{
  carId: string;
  startDate: string;
  endDate: string;
  bookingId?: string;
  disabled?: boolean;
  startDateTitle: string;
  endDateTitle: string;
}>();
const emit = defineEmits<{
  (e: 'update:startDate', value: string): void;
  (e: 'update:endDate', value: string): void;
  (e: 'change'): void;
}>();

const dates = ref([new Date(props.startDate), new Date(props.endDate)]);
watchEffect(() => (dates.value = [new Date(props.startDate), new Date(props.endDate)]));

const { data: bookingsData } = useBookingsWithQuery(
  computed(() => ({
    filter: [
      {
        carIds: [props.carId],
        status: [
          BookingStatus.OPEN,
          BookingStatus.CONFIRMED,
          BookingStatus.HANDED_OVER,
          BookingStatus.CAR_RETURNED,
        ],
      },
    ],
  })),
  !!props.carId,
);
const showPicker = ref(false);
const pickerContainer = ref(null);
onClickOutside(pickerContainer, () => {
  if (!showPicker.value) return;
  showPicker.value = false;

  if (
    props.startDate === dates.value[0].toISOString() &&
    props.endDate === dates.value[1].toISOString()
  ) {
    return;
  }

  emit('update:startDate', dates.value[0].toISOString());
  emit('update:endDate', dates.value[1].toISOString());
  emit('change');
});

const formattedStartDate = computed(() => formatIsoDateString(props.startDate));
const formattedEndDate = computed(() => formatIsoDateString(props.endDate));

const bookings = useFlattenPaginatedData(bookingsData);

const disabledDates = computed(() => {
  return flatMap(
    bookings.value
      ?.filter((booking) => booking.id !== props.bookingId)
      .map((booking) => {
        return Interval.fromDateTimes(
          DateTime.fromISO(booking.startDate),
          DateTime.fromISO(booking.endDate),
        );
      }),
    (interval) => {
      const days = Array.from(daysFromInterval(interval)).map((day) => day.toJSDate());
      days.pop();
      days.shift();
      return days;
    },
  );
});
</script>
