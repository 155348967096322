import type {
  Car,
  CreateCarDto,
  FindAllCarsQueryDto,
  UpdateCarDto,
} from '@/entities/cars/car.entity';
import type { PaginatedResponse } from '@/entities/pagination/paginated-response';
import { Service } from './http-service';

class CarsService extends Service<Car, CreateCarDto, UpdateCarDto> {
  constructor() {
    super('/cars');
  }

  async getAllWithQuery(params: FindAllCarsQueryDto) {
    const res = await this.httpClient.get<PaginatedResponse<Car>>(`${this.endpoint}`, {
      params,
    });
    return res.data;
  }
}

export const carsService = new CarsService();
