<template>
  <ul
    v-if="user?.role === UserRole.AGENT"
    class="menu-compact menu dropdown-content z-[50] mt-5 w-64 rounded-box bg-white p-2 shadow"
  >
    <li>
      <button class="btn btn-ghost btn-sm" @click="handleLogout">
        {{ t('logout') }}
      </button>
    </li>
  </ul>

  <ul
    v-else
    class="menu-compact menu dropdown-content z-[50] mt-5 w-64 rounded-box bg-white p-2 shadow"
  >
    <li v-if="$can('ros', 'Dashboard')">
      <router-link :to="{ name: 'home' }">{{ t('dashboard') }}</router-link>
    </li>

    <li v-if="$can('handle', 'Booking')">
      <router-link :to="{ name: 'upcomingHandovers' }">{{ t('handover') }}</router-link>
    </li>

    <li v-if="$can('ros', 'Bookings')">
      <router-link :to="{ name: 'bookingPlan' }">{{ t('bookingPlan') }}</router-link>
    </li>

    <li v-if="$can('ros', 'Bookings')">
      <router-link :to="{ name: 'bookingList' }">{{ t('bookingList') }}</router-link>
    </li>

    <li v-if="$can('ros', 'CreateBooking')">
      <router-link :to="{ name: 'bookingCreate' }">{{ t('createBooking') }}</router-link>
    </li>

    <li v-if="$can('ros', 'VehiclePool')">
      <router-link :to="{ name: 'vehiclePool' }">{{ t('vehiclePoolManagement') }}</router-link>
    </li>

    <li v-if="$can('read', 'VehicleType')">
      <router-link :to="{ name: 'vehicleTypes' }">{{ t('vehicleTypes') }}</router-link>
    </li>

    <li v-if="$can('update', 'Station')">
      <router-link :to="{ name: 'stationList' }">{{ t('stations') }}</router-link>
    </li>

    <li v-if="$can('manage', 'Invoice')">
      <router-link :to="{ name: 'invoiceList' }">{{ t('invoices') }}</router-link>
    </li>

    <li v-if="$can('manage', 'PaymentTerminal')">
      <router-link :to="{ name: 'paymentTerminalList' }">{{ t('paymentTerminals') }}</router-link>
    </li>

    <li v-if="user?.role === UserRole.ADMIN && $can('manage', 'User')">
      <router-link :to="{ name: 'userList' }">{{ t('users') }}</router-link>
    </li>

    <li>
      <router-link :to="{ name: 'settings' }">{{ t('settings') }}</router-link>
    </li>

    <li>
      <button class="btn btn-ghost btn-sm" @click="handleLogout">
        {{ t('logout') }}
      </button>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { UserRole } from '@/entities/auth/user-role.enum';
import { useI18n } from 'vue-i18n';
import { useLogout } from '@/queries/auth/use-logout';
import { useAuthStore } from '@/stores/auth.store';
import { RouterLink } from 'vue-router';

const { t } = useI18n();
const { user } = useAuthStore();

const { mutate: logoutUser } = useLogout();

const handleLogout = () => {
  logoutUser();
};
</script>
<i18n lang="json">
{
  "en": {
    "login": "Login",
    "logout": "Logout",
    "settings": "Settings",
    "vehicleTypes": "Vehicles",
    "stations": "Stations",
    "handover": "Handovers",
    "bookingList": "Booking list",
    "bookingPlan": "Booking plan",
    "createBooking": "Create Booking",
    "dashboard": "Dashboard",
    "invoices": "Invoices",
    "paymentTerminals": "Payment Terminals",
    "users": "Users",
    "vehiclePoolManagement": "Vehicle Pool Management"
  },
  "de": {
    "login": "Login",
    "logout": "Logout",
    "settings": "Einstellungen",
    "vehicleTypes": "Fahrzeuge",
    "stations": "Stationen",
    "handover": "Übergaben & Rückgaben",
    "bookingList": "Buchungsliste",
    "bookingPlan": "Belegungsplan",
    "createBooking": "Buchung erstellen",
    "dashboard": "Dashboard",
    "invoices": "Rechnungen",
    "paymentTerminals": "Zahlungsterminale",
    "users": "Benutzer",
    "vehiclePoolManagement": "Fuhrparkmanagement"
  }
}
</i18n>
