<template>
  <div class="flex items-center justify-between">
    <h2>{{ t('invoice') }} {{ invoice.completeInvoiceNumber }}</h2>
    <Spinner v-if="isFetching" class="h-6 w-6" />
  </div>

  <div class="mt-8">
    <div class="flex items-start justify-between">
      <div>
        <p
          class="text-xl font-medium"
          :class="{
            'text-success': invoice.status === InvoiceStatus.PAID,
            'text-error': invoice.status !== InvoiceStatus.PAID,
          }"
        >
          {{ t('status') }}: {{ t(`invoiceStatus.${invoice.status}`) }}
        </p>

        <p class="mt-6">
          {{ invoice.receiverData.firstName }}
          {{ invoice.receiverData.lastName }}
          <br />
          {{ invoice.receiverData.street }}
          <br />
          {{ invoice.receiverData.zip }}
          {{ invoice.receiverData.city }}
        </p>

        <LabeledText :label="`${t('bookingNumber')}`" class="mt-4">{{
          invoice.booking ? invoice.booking.bookingNumber : 'n/a'
        }}</LabeledText>
      </div>

      <div class="grid grid-cols-1 gap-2">
        <div>
          <CVButton outline class="w-full" @click="downloadInvoice(invoice)">{{
            t('downloadInvoice')
          }}</CVButton>
        </div>
        <div v-if="invoice.status !== InvoiceStatus.CANCELED">
          <CVButton
            outline
            class="w-full"
            :is-loading="isSending"
            @click="sendInvoiceToCustomerWithAlert(invoice.id)"
            >{{ t('sendToCustomer') }}</CVButton
          >
        </div>
      </div>
    </div>

    <div class="mt-4 flex justify-between">
      <LabeledText :label="`${t('description')}`">{{ invoice.title ?? '-' }}</LabeledText>

      <LabeledText :label="`${t('invoiceDate')}`">{{ formattedInvoiceDate }}</LabeledText>
    </div>

    <InvoicePositions :invoice="invoice" class="mb-6 mt-6" />

    <Divider />

    <Payments :booking="invoice.booking" :invoice-id="invoice.id" @on-changed="$emit('refetch')" />

    <Divider />

    <h2>{{ t('comment') }}</h2>

    <TextareaField name="comment" rows="4" />

    <div class="mt-4 flex justify-end">
      <CVButton :is-loading="isSaving" @click="onSubmit">{{ t('save') }}</CVButton>
    </div>

    <div v-if="invoice.type !== InvoiceType.REVERSAL && invoice.status !== InvoiceStatus.CANCELED">
      <Divider />

      <div class="mt-3">
        <CVButton outline variant="error" :disabled="isSaving" @click.prevent="cancelInvoice">
          {{ t('cancelInvoice') }}
        </CVButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Payments from '@/components/booking-forms/Payments.vue';
import Divider from '@/components/Divider.vue';
import LabeledText from '@/components/LabeledText.vue';
import TextareaField from '@/components/TextareaField.vue';
import { InvoiceStatus } from '@/entities/invoices/invoice-status.enum';
import type { Invoice, UpdateInvoiceDto } from '@/entities/invoices/invoice.entity';
import { useFormatedIsoDateString } from '@/hooks/use-formated-date';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';
import { useForm } from 'vee-validate';
import InvoicePositions from './InvoicePositions.vue';
import { invoiceUpdateSchema } from '@/validation/invoice.schema';
import { Alert } from '@/utils/alert';
import { useInvoicePdfs } from '@/hooks/use-invoice-pdf';
import { ref } from 'vue';
import { cloneDeep } from 'lodash';
import { usePreventLeavingUnsavedForm } from '@/hooks/use-prevent-leaving-unsaved-form';
import { InvoiceType } from '@/entities/invoices/invoice-type.enum';
import { formHasChanged } from '@/hooks/use-form-has-changed';

const props = defineProps<{
  invoice: Invoice;
  isSaving: boolean;
  isFetching: boolean;
}>();

const emit = defineEmits<{
  (e: 'onSubmit', value: UpdateInvoiceDto): void;
  (e: 'onCancel'): void;
  (e: 'refetch'): void;
}>();

const { t } = useI18n();

const formattedInvoiceDate = useFormatedIsoDateString(props.invoice.invoiceDate, DateTime.DATE_MED);

const initialValues = {
  comment: props.invoice.comment ?? '',
};

const { handleSubmit, values: formValues } = useForm({
  initialValues,
  validationSchema: invoiceUpdateSchema,
});

const unchangedValues = ref(cloneDeep(formValues));

const onSubmit = handleSubmit((values) => {
  unchangedValues.value = cloneDeep(formValues);
  emit('onSubmit', values);
});

usePreventLeavingUnsavedForm(() => formHasChanged(unchangedValues.value, formValues));

const { downloadInvoice, sendInvoiceToCustomerWithAlert, isSending } = useInvoicePdfs();

const cancelInvoice = async () => {
  const alertResult = await Alert.fire({
    titleText: t('reallyCancelInvoiceTitle'),
    text: t('reallyCancelInvoiceText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('cancelInvoice'),
    denyButtonText: t('dontCancel'),
  });
  if (alertResult.isConfirmed) {
    emit('onCancel');
  }
};
</script>

<i18n lang="json">
{
  "en": {
    "invoice": "Invoice",
    "status": "Status",
    "bookingNumber": "Booking Number",
    "description": "Description",
    "invoiceDate": "Invoice Date",
    "downloadInvoice": "Download Invoice (PDF)",
    "sendToCustomer": "Send to Customer",
    "comment": "Comment",
    "cancelInvoice": "Cancel Invoice",
    "reallyCancelInvoiceTitle": "Really cancel Invoice?",
    "reallyCancelInvoiceText": "The Invoice will be permanently canceled.",
    "dontCancel": "Don't cancel"
  },
  "de": {
    "invoice": "Rechnung",
    "status": "Status",
    "bookingNumber": "Buchungsnummer",
    "description": "Beschreibung",
    "invoiceDate": "Rechnungsdatum",
    "downloadInvoice": "Rechnung runterladen (PDF)",
    "sendToCustomer": "An Kunde senden",
    "comment": "Kommentar",
    "cancelInvoice": "Rechnung stornieren",
    "reallyCancelInvoiceTitle": "Rechnung wirklich stornieren?",
    "reallyCancelInvoiceText": "Die Rechnung wird unwiderruflich storniert.",
    "dontCancel": "Nicht stornieren"
  }
}
</i18n>
