<template>
  <div class="grid grid-cols-2 items-start gap-4 p-6">
    <div class="grid gap-4">
      <LabeledText :label="t('created')">{{ createdDate }}</LabeledText>
      <div v-if="!editDescription" class="flex items-start justify-between gap-2">
        <LabeledText :label="t('description')">{{ damage.description }}</LabeledText>
        <button class="text-2xl text-primary" @click.prevent="editDescription = true">
          &#9998;
        </button>
      </div>
      <div v-else class="space-y-4">
        <Input v-model="description" :label="t('description')" />
        <div class="flex gap-2">
          <CVButton variant="success" :is-loading="updatingDamage" @click="saveDescription">{{
            t('save')
          }}</CVButton>
          <CVButton variant="warning" :disabled="updatingDamage" @click="cancelDescription">{{
            t('cancel')
          }}</CVButton>
        </div>
      </div>
      <LabeledText :label="t('repair')">
        <CVButton
          v-if="damage.status === DamageStatus.OPEN"
          class="justify-self-start"
          :is-loading="updatingDamage"
          @click.prevent="setDamageStatusToRepaired"
          >{{ t('confirmRepair') }}</CVButton
        >
        <span v-else>{{ t('repairedAt') }} {{ repairedDate }}</span>
      </LabeledText>
    </div>
    <MarkerContainer :damage="damage" hide-position-numbers></MarkerContainer>
    <div class="col-span-2 w-full">
      <div v-if="damage?.videoFileUrl" class="mb-2 flex gap-2">
        <a
          href="#"
          :class="{ 'text-link': showVideo, 'text-gray-500': !showVideo }"
          @click.prevent="showVideo = false"
          >{{ t('picture') }}</a
        >
        <div class="text-primary">|</div>
        <a
          href="#"
          :class="{ 'text-link': !showVideo, 'text-gray-500': showVideo }"
          @click.prevent="showVideo = true"
          >{{ t('video') }}</a
        >
      </div>
      <img v-show="!showVideo" :src="damage.imageFileUrl" class="w-full" />
      <video v-if="damage?.videoFileUrl" v-show="showVideo" class="w-full" controls>
        <source :src="damage?.videoFileUrl" />
      </video>
    </div>
  </div>
</template>

<script lang="ts" setup>
import LabeledText from '@/components/LabeledText.vue';
import type { Damage } from '@/entities/damage.entity';
import { useFormatedIsoDateString } from '@/hooks/use-formated-date';
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import MarkerContainer from './components/MarkerContainer.vue';
import { DamageStatus } from '@/entities/damage-status.enum';
import { useUpdateDamage } from '@/queries/use-damages';
import { Alert } from '@/utils/alert';
import Input from '@/components/Input.vue';
import CVButton from '@/components/buttons/CVButton.vue';

const props = defineProps<{
  damage: Damage;
}>();

const { t } = useI18n();

const description = ref(props.damage.description);
const editDescription = ref(false);

const { mutateAsync: updateDamage, isPending: updatingDamage } = useUpdateDamage();
const showVideo = ref(false);

watch(
  () => props.damage,
  () => {
    showVideo.value = false;
  },
);

const createdDate = useFormatedIsoDateString(computed(() => props.damage.createdDate));

const repairedDate = useFormatedIsoDateString(computed(() => props.damage.repairedDate));

const setDamageStatusToRepaired = async () => {
  const result = await Alert.fire({
    title: t('areYouSure'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: t('yes'),
    cancelButtonText: t('no'),
  });

  if (!result.isConfirmed) return;

  await updateDamage({
    id: props.damage?.id,
    damage: {
      status: DamageStatus.REPAIRED,
    },
  });
};

const saveDescription = async () => {
  await updateDamage({
    id: props.damage?.id,
    damage: {
      description: description.value,
    },
  });
  editDescription.value = false;
};

const cancelDescription = async () => {
  editDescription.value = false;
  description.value = props.damage.description;
};
</script>

<i18n lang="json">
{
  "en": {
    "created": "Created",
    "description": "Description",
    "repair": "Repair",
    "confirmRepair": "Confirm repair",
    "repairedAt": "Repaired at",
    "areYouSure": "Are you sure?",
    "picture": "Picture",
    "video": "Video"
  },
  "de": {
    "created": "Erstellt",
    "description": "Beschreibung",
    "repair": "Reparatur",
    "confirmRepair": "Reparatur bestätigen",
    "repairedAt": "Repariert am",
    "areYouSure": "Bist du sicher?",
    "picture": "Foto",
    "video": "Video"
  }
}
</i18n>
